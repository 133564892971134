import gql from 'graphql-tag';
import {
    ApplicationType,
    UpdateApplicationInput,
    Applicant,
    ApplicationDecision,
    ActivityLogInput,
} from 'proxyaddress-common/types';
import wrapMutation from './wrapMutation';
import { ApplicantType, Application } from './applicant';

export interface UpdateApplicationParams {
    application: UpdateApplicationInput;
    activityLogEntry?: ActivityLogInput;
}

export interface UpdateApplicationData {
    application: ApplicationType;
}

export const applicationMutation = gql`
    mutation editApplication($application: UpdateApplicationInput!, $activityLogEntry: ActivityLogInput) {
        editApplication(application: $application, activityLogEntry: $activityLogEntry) ${Application}
    }
`;

export const updateApplication = wrapMutation<UpdateApplicationParams, UpdateApplicationData>(applicationMutation);

export interface SubmitApplicationDecisionParams {
    closingDecision: ApplicationDecision;
    activityLogEntry: ActivityLogInput;
}

interface SubmitApplicationDecisionData {
    applicant: Applicant;
}

export const submitApplicationDecisionMutation = gql`
    mutation submitApplicationDecision($closingDecision: ApplicationDecision!, $activityLogEntry: ActivityLogInput!) {
        submitApplicationDecision(closingDecision: $closingDecision, activityLogEntry: $activityLogEntry) ${ApplicantType}
    }    
`;

export const submitApplicationDecision = wrapMutation<SubmitApplicationDecisionParams, SubmitApplicationDecisionData>(
    submitApplicationDecisionMutation,
);
