import { ACTIVE, INACTIVE } from 'proxyaddress-common/constants';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { shade700 } from '../../theme/colours';
import { getDisplayAddress } from '../../utils/addresses';
import {
    formatProxyAddressSource,
    getProxyAddressBuildingStatus,
    getProxyAddressDetailsTitle,
} from '../../utils/proxyAddress';
import { formatDetailsDate } from '../../utils/staffDashboard';
import Button from '../bits/Buttons/Button';
import IconButton from '../bits/Buttons/IconButton';
import Caption from '../bits/Caption/Caption';
import Detail from '../bits/Details/Detail';
import StatusBadge from '../bits/Grid/StatusBadge';
import { IconOptions } from '../bits/Icons';
import { Title2 } from '../bits/Titles/titles';
import { AdminStateContext } from '../WithAdminState/adminState';
import EditProxyAddressDetails from './EditProxyAddressDetails';

const DetailsWrapper = styled.div`
    display: block;
    width: 50%;
    margin: auto;
    > div {
        button {
            margin-right: 0;
        }
        text-align: center;
    }
    form {
        width: min-content;
        margin: auto;
    }
`;

const AddressTitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    h2 {
        display: inline-block;
        overflow-wrap: break-word;
    }
`;

const EditButtonWrapper = styled.div`
    display: block;
    margin: 1rem auto;
`;

const ProxyAddressDetails = (): ReactElement => {
    const history = useHistory();
    const { proxyAddressUuid }: { proxyAddressUuid: string } = useParams();
    const { currentProxyAddress, councilAreas, proxyAddresses, setCurrentProxyAddress } = useContext(AdminStateContext);
    const [editProxyAddressDetails, setEditProxyAddressDetails] = useState(false);

    useEffect(() => {
        // sets proxy address on refresh
        if (!currentProxyAddress) setCurrentProxyAddress(proxyAddresses[proxyAddressUuid]);

        return () => {
            setCurrentProxyAddress(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToProxyAddressAdmin = () => {
        setCurrentProxyAddress(undefined);
        history.push('/admin/addresses');
    };

    return (
        <>
            {currentProxyAddress && (
                <>
                    <AddressTitleWrapper>
                        <IconButton icon="backArrow" fill={shade700} onClick={redirectToProxyAddressAdmin} />
                        <Title2>{getProxyAddressDetailsTitle(currentProxyAddress)}</Title2>
                    </AddressTitleWrapper>
                    <DetailsWrapper>
                        {!editProxyAddressDetails && (
                            <div>
                                <EditButtonWrapper>
                                    <Button
                                        buttonStyle="primary"
                                        text="Edit ProxyAddress"
                                        onClick={() => setEditProxyAddressDetails(true)}
                                    />
                                </EditButtonWrapper>
                                <Caption>Status</Caption>
                                <StatusBadge text={currentProxyAddress?.isActive ? ACTIVE : INACTIVE} />
                                <Detail caption="Address" detail={getDisplayAddress(currentProxyAddress)} />
                                <Detail
                                    caption="Council area"
                                    detail={councilAreas[currentProxyAddress?.councilAreaUuid].name}
                                />
                                <Detail
                                    caption="Source"
                                    detail={formatProxyAddressSource(currentProxyAddress?.source)}
                                    iconBelow={{
                                        fill: shade700,
                                        icon: currentProxyAddress?.source.toLowerCase() as IconOptions,
                                    }}
                                />
                                {currentProxyAddress?.activationDate && (
                                    <Detail
                                        caption="Activated"
                                        detail={formatDetailsDate(currentProxyAddress.activationDate)}
                                    />
                                )}
                                <Detail caption="Expires" detail={formatDetailsDate(currentProxyAddress?.expiryDate)} />
                                <Detail
                                    caption="Building status"
                                    detail={getProxyAddressBuildingStatus(currentProxyAddress?.blpuStateCode)}
                                />
                            </div>
                        )}

                        {editProxyAddressDetails && (
                            <EditProxyAddressDetails
                                proxyAddress={currentProxyAddress}
                                setEditing={setEditProxyAddressDetails}
                            />
                        )}

                        {/* TODO add number of people assigned to address and score once PXA-79 is done */}
                    </DetailsWrapper>
                </>
            )}
        </>
    );
};

export default ProxyAddressDetails;
