import React, { ReactElement } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminNavigation from '../patterns/AdminNavigation';
import CreateOrganisation from '../patterns/CreateOrganisation';
import CreateProxyAddress from '../patterns/CreateProxyAddress';
import CreateStaffUser from '../patterns/CreateStaffUser';
import OrganisationAdmin from '../patterns/OrganisationAdmin';
import OrganisationDetails from '../patterns/OrganisationDetails';
import StaffAdmin from '../patterns/StaffAdmin';
import CouncilAreas from '../patterns/CouncilAreas';
import UploadCouncilAreas from '../patterns/UploadCouncilAreas';
import ProxyAddressAdmin from '../patterns/ProxyAddressAdmin';
import ProxyAddressDetails from '../patterns/ProxyAddressDetails';

const Admin = (): ReactElement => {
    return (
        <div>
            <AdminNavigation>
                <Switch>
                    <Route exact path="/admin">
                        <Redirect to="/admin/organisations" />
                    </Route>

                    <Route exact path="/admin/organisations" component={OrganisationAdmin} />

                    <Route path="/admin/organisations/new" component={CreateOrganisation} />

                    <Route path="/admin/organisations/:orgUuid" component={OrganisationDetails} />

                    <Route path="/admin/staffUser/new/:orgUuid" component={CreateStaffUser} />

                    <Route path="/admin/staff" component={StaffAdmin} />

                    <Route exact path="/admin/councilAreas" component={CouncilAreas} />

                    <Route path="/admin/councilAreas/update" component={UploadCouncilAreas} />

                    <Route exact path="/admin/addresses" component={ProxyAddressAdmin} />

                    <Route path="/admin/addresses/new" component={CreateProxyAddress} />

                    <Route path="/admin/addresses/:proxyAddressUuid" component={ProxyAddressDetails} />
                </Switch>
            </AdminNavigation>
        </div>
    );
};

export default Admin;
