import { map } from 'ramda';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { echo } from '../../../theme/sizing';
import BodyCopy from '../BodyCopy/BodyCopy';
import Button from '../Buttons/Button';
import { Title2 } from '../Titles/titles';

interface IntroProps {
    body?: ReactNode;
    text: string[];
    title: string;
    cancelTitle?: string;
    submitTitle?: string;
    onCancel?: () => void;
    onSubmit?: () => void;
}

const StyledIntro = styled.div`
    text-align: centre;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: ${echo};
`;

const StyledIntroBody = styled.form`
    text-align: left;
    > * + * {
        margin-top: ${echo};
    }
    width: 50%;
    margin: auto;
    @media (max-width: 650px) {
        width: 100%;
        margin-left: 5px;
    }
`;

const Intro = ({ body, text, title, onCancel, onSubmit, cancelTitle, submitTitle }: IntroProps): ReactElement => (
    <StyledIntro>
        <StyledIntroBody>
            <Title2>{title}</Title2>
            {onCancel && <Button buttonStyle="link" text={cancelTitle} onClick={onCancel} leftIcon="backArrow" />}
            {map(
                (line) => (
                    <BodyCopy key={line} textAlign="left">
                        {line}
                    </BodyCopy>
                ),
                text,
            )}
            {body}
            {onSubmit && <Button buttonStyle="primary" text={submitTitle} onClick={onSubmit} rightIcon="rightArrow" />}
        </StyledIntroBody>
    </StyledIntro>
);

export default Intro;
