import React, { ReactElement } from 'react';
import SignIn from './SignIn';
import Register from './Register';

interface AuthLandingProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange: (authState: string, data?: any) => void;
    isApplicant: boolean;
}

const AuthLanding = ({ isApplicant, ...props }: AuthLandingProps): ReactElement => {
    return (
        <>
            {isApplicant ? (
                <SignIn isApplicant={isApplicant} {...props} />
            ) : (
                <>
                    <Register />
                    <SignIn isApplicant={isApplicant} {...props} />
                </>
            )}
        </>
    );
};

export default AuthLanding;
