import { ApplicantTab } from '../types';

export const NOTE = 'NOTE';
export const EMAIL = 'EMAIL';
export const REMINDER = 'REMINDER';
export const APPLICATION_CREATED = 'APPLICATION_CREATED';
export const APPLICATION_STARTED = 'APPLICATION_STARTED';
export const APPLICATION_DECISION = 'APPLICATION_DECISION';
export const APPLICANT_DETAILS_UPDATED = 'APPLICANT_DETAILS_UPDATED';
export const PROXY_ADDRESS_ASSIGNED = 'PROXY_ADDRESS_ASSIGNED';
export const STAFF_ASSIGNED = 'STAFF_ASSIGNED';
export const CHECK_STARTED = 'CHECK_STARTED';
export const CHECK_RETURNED = 'CHECK_RETURNED';
export const ALL_CHECKS_RETURNED = 'ALL_CHECKS_RETURNED';
export const UPLOAD = 'UPLOAD';

export const PENDING = 'PENDING';
export const STARTED = 'STARTED';
export const ACTION_REQUIRED = 'ACTION_REQUIRED';
export const APPROVED = 'APPROVED';
export const REJECTED = 'REJECTED';
export const CLOSED_WITHOUT_DECISION = 'CLOSED_WITHOUT_DECISION';

export const COUNCIL = 'COUNCIL';
export const CHARITY = 'CHARITY';
export const OTHER = 'OTHER';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

export const INTRO = 'intro';
export const DETAILS = 'details';
export const ID_VERIFICATION = 'idVerification';
export const SURVEY = 'survey';
export const SERVICE_CHOICE = 'serviceChoice';
export const COMPLETE = 'complete';

export const applicationStages = [INTRO, DETAILS, ID_VERIFICATION, SURVEY, SERVICE_CHOICE, COMPLETE];

export const applicationStageDetails = [
    { stage: INTRO, title: 'Start' },
    { stage: DETAILS, title: 'Details and identity report' },
    { stage: ID_VERIFICATION, title: 'Photo ID verification' },
    { stage: SURVEY, title: 'Participant survey' },
    { stage: SERVICE_CHOICE, title: 'Service choice' },
    { stage: COMPLETE, title: 'Complete' },
];

export const PERSONAL_DETAILS = 'Details';
export const CHECKS = 'Checks';
export const ACTIVITY = 'Activity';
export const applicantTabs: ApplicantTab[] = [PERSONAL_DETAILS, CHECKS, ACTIVITY];

//Onfido Results
export const CLEAR = 'clear';
export const CONSIDER = 'consider';
export const AWAITING_RESULT = 'awaitingResult';

export const SCOTLAND = 'SCOTLAND';
export const NORTHERN_IRELAND = 'NORTHERN_IRELAND';
export const ENGLAND_AND_WALES = 'ENGLAND_AND_WALES';
export const SCOTLAND_OUTPUT = 'Scotland';
export const ENGLAND_AND_WALES_OUTPUT = 'England and Wales';
export const NORTHERN_IRELAND_OUTPUT = 'Northern Ireland';

export const INHABITED = 'INHABITED';
export const UNINHABITED = 'UNINHABITED';
export const DONATED = 'DONATED';
export const PARTNER = 'PARTNER';

export const ID_CHECK = 'idCheck';
export const WATCHLIST = 'watchlistCheck';
