import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { shade200, shade600, blue600 } from '../../theme/colours';
import { bravo, charlie } from '../../theme/sizing';
import { label1 } from '../../theme/cssSnippets';

interface InfoBoxProps {
    body: ReactNode;
}

const InfoBoxStyled = styled.div`
    height: 50px;
    border: ${charlie} solid ${shade200};
    width: fit-content;
    block-size: fit-content;
    padding: ${charlie};
    h2 {
        ${label1};
    }
    p {
        padding-top: ${bravo};
    }
    a,
    a:visited {
        color: ${shade600};
    }
    a:hover {
        color: ${blue600};
    }
`;

function InfoBox({ body }: InfoBoxProps): ReactElement {
    return <InfoBoxStyled>{body}</InfoBoxStyled>;
}

export default InfoBox;
