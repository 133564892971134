import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Caption from '../Caption/Caption';
import { foxtrot } from '../../../theme/sizing';
import { shade600, shade700 } from '../../../theme/colours';
import { Icon, IconOptions } from '../Icons';

interface DetailWrapperProps {
    detail: string | undefined;
    caption: string;
    iconBelow?: {
        icon: IconOptions;
        size?: string;
        fill?: string;
    };
}

export const StyledDetailWrapper = styled.div`
    margin: ${foxtrot} auto;
    p {
        font-size: 0.8rem;
        color: ${shade700};
    }
`;

const Detail = ({ detail, caption, iconBelow }: DetailWrapperProps): ReactElement => {
    return (
        <StyledDetailWrapper>
            <Caption color={shade600}>{caption}</Caption>
            <p>{detail ? detail : '-'}</p>
            {iconBelow && <Icon icon={iconBelow.icon} size={iconBelow.size} fill={iconBelow.fill} />}
        </StyledDetailWrapper>
    );
};

export default Detail;
