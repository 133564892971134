import { ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import { isNil } from 'ramda';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { updateApplicant } from '../../../graphql/applicant';
import { red400 } from '../../../theme/colours';
import { echo } from '../../../theme/sizing';
import {
    checkEditApplicantValidationResult,
    EditApplicantDetailsForm,
    EditDetailsErrors,
    editDetailsInitialErrors,
    formatApplicantDetailsToUpdate,
    getApplicantDetailsToUpdateFields,
    getEditApplicantDetails,
    validateEditApplicantDetailsInput,
} from '../../../utils/applicant';
import { formatDetailsDate, formatOrgApplicant } from '../../../utils/staffDashboard';
import Loading from '../../bits/Loading';
import Button from '../../bits/Buttons/Button';
import Caption, { StyledCaption } from '../../bits/Caption/Caption';
import AddressDetail from '../../bits/Details/AddressDetail';
import Detail from '../../bits/Details/Detail';
import StatusBadge from '../../bits/Grid/StatusBadge';
import { Title2 } from '../../bits/Titles/titles';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import EditApplicantPersonalDetails from './EditApplicantPersonalDetails';

interface PersonalDetailsTabProps {
    applicant: ApplicantWithStaffDetails;
}

const EditDisabledMessage = styled(StyledCaption)`
    margin-bottom: ${echo};
`;

const TabPageHeading = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
        display: inline-flex;
    }
    h2 {
        display: inline-block;
        align-self: start;
    }
    button {
        align-self: start;
    }
    p {
        display: block;
    }
`;

const PersonalDetailsTab = ({ applicant }: PersonalDetailsTabProps): ReactElement => {
    const { setCurrentApplicant, staffUsers } = useContext(StaffUserContext);
    const [updateApplicantMutation, { loading, error: updateError }] = updateApplicant.hook();
    const [isCheckActive, setIsCheckActive] = useState(false);
    const [disabledMessage, setDisabledMessage] = useState('');
    const [editDetails, setEditDetails] = useState(false);
    const [details, setDetails] = useState<EditApplicantDetailsForm>(getEditApplicantDetails(applicant));
    const [errors, setErrors] = useState<EditDetailsErrors>(editDetailsInitialErrors);

    useEffect(() => {
        if (applicant?.application) {
            /* TODO add checks to make sure are no open checks for watchlist and identity report */
            const { idCheck } = applicant?.application;

            if (!isNil(idCheck?.checkId)) {
                setIsCheckActive(true);
                setDisabledMessage('Unable to edit applicant details while a check is ongoing.');
            }
        }
    }, [applicant]);

    const submitDetails = async () => {
        const validationResult = validateEditApplicantDetailsInput(details);
        const isEditApplicantInputValid = checkEditApplicantValidationResult(validationResult);

        if (isEditApplicantInputValid) {
            const applicantWithNewInfo = formatApplicantDetailsToUpdate(applicant, details);
            const variables = getApplicantDetailsToUpdateFields(applicantWithNewInfo);
            const updatedApplicant = await updateApplicantMutation({ variables });
            if (updatedApplicant.data) {
                const formattedEditedApplicant = formatOrgApplicant({
                    applicant: updatedApplicant.data.editApplicant,
                    staffUsers,
                });
                setCurrentApplicant(formattedEditedApplicant);
                setEditDetails(false);
            }
        } else {
            setErrors(validationResult);
        }
    };

    return (
        <>
            <TabPageHeading>
                <Title2>{editDetails ? 'Edit personal details' : 'Personal Details'}</Title2>

                {!editDetails && (
                    <Button
                        buttonStyle="secondary"
                        text="Edit personal details"
                        disabled={isCheckActive || !applicant?.isActive || loading}
                        onClick={() => setEditDetails(true)}
                    />
                )}
                {editDetails && (
                    <div>
                        <Button
                            buttonStyle="secondary"
                            text="Cancel"
                            onClick={() => {
                                setEditDetails(false);
                                setDetails(getEditApplicantDetails(applicant));
                            }}
                        />
                        <Button buttonStyle="primary" text="Save details" onClick={submitDetails} />
                    </div>
                )}
            </TabPageHeading>
            {loading && <Loading />}
            {!loading && (
                <>
                    {!editDetails && (
                        <>
                            {disabledMessage && (
                                <EditDisabledMessage>
                                    <Caption color={red400}>
                                        <p>{disabledMessage}</p>
                                    </Caption>
                                </EditDisabledMessage>
                            )}
                            <Caption>Status</Caption>
                            <StatusBadge text={applicant?.isActive ? 'ACTIVE' : 'INACTIVE'} />
                            <Detail detail={applicant?.title} caption="Title" />
                            <Detail detail={applicant?.firstName} caption="First name" />
                            <Detail detail={applicant?.middleNames} caption="Middle names" />
                            <Detail detail={applicant?.lastName} caption="Last name" />
                            <Detail detail={formatDetailsDate(applicant?.dateOfBirth)} caption="Date of birth" />
                            <Detail detail={applicant?.email} caption="Email" />
                            <Detail detail={applicant?.correspondenceEmail} caption="Correspondence email" />
                            <Detail detail={applicant?.phoneNumber} caption="Phone number" />
                            <AddressDetail address={applicant?.postalAddress} caption="Postal address" />
                            <AddressDetail address={applicant?.currentLocation} caption="Current location" />
                            <AddressDetail address={applicant?.addressHistory} caption="Previous address(es)" />

                            <Title2>ProxyAddress Details</Title2>
                            <AddressDetail address={applicant?.proxyAddress} caption="Assigned ProxyAddress" />
                            <Detail detail={applicant?.pin} caption="ProxyAddress Identification Number (PIN)" />
                        </>
                    )}
                    {editDetails && (
                        <EditApplicantPersonalDetails
                            details={details}
                            setDetails={setDetails}
                            updateError={!!updateError}
                            errors={errors}
                            submit={submitDetails}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default PersonalDetailsTab;
