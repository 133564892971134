import { ADMIN_USER_GROUP, APPLICANT_USER_GROUP, STAFF_USER_GROUP } from 'proxyaddress-common/constants/users';
import {
    ApplicationType,
    StaffUser,
    Applicant,
    ApplicantAddress,
    UpdateApplicantAddressArgs,
    UpdateApplicationInput,
} from 'proxyaddress-common/types';
import { always, cond, equals, isEmpty, map, omit, pathOr, startsWith, T, values } from 'ramda';
import { AppUser } from '../components/WithAuth/AuthContext';
import { CreateApplicantParams } from '../graphql/applicant';
import { CreateStaffUserParams } from '../graphql/staffUser';
import { ERROR_NO_NAME } from './constants';
import { checkEmail, checkValidEmail } from './forgotPassword';

export const getLoggedInUsersGroups = (user: AppUser): Array<string> => {
    return pathOr([], ['signInUserSession', 'accessToken', 'payload', 'cognito:groups'], user);
};

export const isUserInGroup = (user: AppUser, group: string): boolean => {
    const groups: Array<string> = getLoggedInUsersGroups(user);
    return groups?.includes(group);
};

export const getCurrentUserType = cond<AppUser, string>([
    [(user) => isUserInGroup(user, APPLICANT_USER_GROUP), always(APPLICANT_USER_GROUP)],
    [(user) => isUserInGroup(user, STAFF_USER_GROUP), always(STAFF_USER_GROUP)],
    [(user) => isUserInGroup(user, ADMIN_USER_GROUP), always(ADMIN_USER_GROUP)],
    [T, always('')],
]);

export const getCurrentUserOrganisation = (user: AppUser): string => {
    return pathOr('', ['attributes', 'custom:organisationId'], user);
};

export const getRedirectPath = cond<string, string>([
    [equals(STAFF_USER_GROUP), always('/dashboard')],
    [equals(ADMIN_USER_GROUP), always('/admin')],
    [equals(APPLICANT_USER_GROUP), always('/application')],
    [T, always('/signIn')],
]);

export const getCurrentUserId = (user?: AppUser): string => {
    return pathOr('', ['attributes', 'sub'], user);
};

export const getCurrentUserUuids = (user: AppUser): { orgUuid: string; userUuid: string } => {
    return {
        orgUuid: getCurrentUserOrganisation(user),
        userUuid: getCurrentUserId(user),
    };
};

export interface StaffUserErrors {
    nameError: string;
    emailError: string;
}

export const StaffUserInitialErrors = {
    nameError: '',
    emailError: '',
};

export const validateStaffUserInput = ({ staffUser }: CreateStaffUserParams): StaffUserErrors => {
    const { name, email } = staffUser;
    return {
        nameError: isEmpty(name) ? ERROR_NO_NAME : '',
        emailError: checkEmail(email),
    };
};

export interface ApplicantErrors {
    emailError: string;
}

export const ApplicantInitialErrors = {
    emailError: '',
};

export const validateApplicantInput = ({ applicantUser }: CreateApplicantParams): ApplicantErrors => {
    const { email } = applicantUser;

    return {
        emailError: checkEmail(email),
    };
};

export const staffUserDropdownValues = (staffUsers: Record<string, StaffUser>): { value: string; label: string }[] => {
    return map((staffUser) => ({ value: staffUser.staffUuid, label: staffUser.name }), values(staffUsers));
};

export const checkEmailFromUrl = (query: string): string | undefined => {
    const hasEmail = startsWith('?username=', query);
    if (hasEmail) {
        return decodeURIComponent(query.split('?username=')[1]);
    }
    return;
};

export const getApplicantUpdateFields = (applicant: Applicant) => {
    return {
        ...omit(
            [
                'email',
                'createdAt',
                'createdBy',
                'modifiedAt',
                'closedApplications',
                'activityLog',
                'pin',
                'proxyAddress',
                '__typename',
                'postalAddress',
                'currentLocation',
            ],
            applicant,
        ),
        postalAddress: applicant.postalAddress && omit(['__typename'], applicant.postalAddress),
        currentLocation: applicant.currentLocation && omit(['__typename'], applicant.currentLocation),
    };
};

export const getApplicationUpdateFields = (application: ApplicationType): UpdateApplicationInput => {
    return {
        ...omit(['createdAt', 'createdBy', 'modifiedAt', '__typename', 'idCheck', 'watchlistCheck'], application),
        progress: omit(['__typename'], application?.progress),
    };
};

export const getApplicantAddressUpdateFields = (applicantAddress: ApplicantAddress): UpdateApplicantAddressArgs => {
    return omit(['createdAt', 'modifiedAt', '__typename'], applicantAddress);
};

export const removeTypeFields = (applicant: Applicant): Applicant => {
    return {
        ...omit(['__typename'], applicant),
        ...(applicant.application
            ? {
                  application: {
                      ...omit(['__typename'], applicant.application),
                      ...(applicant.application.idCheck
                          ? { idCheck: omit(['__typename'], applicant.application.idCheck) }
                          : {}),
                  },
              }
            : {}),
        addressHistory: applicant.addressHistory
            ? applicant.addressHistory.map((address) => omit(['__typename'], address))
            : [],
    };
};

export const formatUsername = (email: string): string => {
    const removeWhitespace = email.trim();
    if (checkValidEmail(removeWhitespace)) return removeWhitespace;
    else return '';
};
