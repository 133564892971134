import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { title2 } from '../../theme/cssSnippets';

interface DashboardButtonSearchProps {
    body?: ReactElement;
    title?: string;
    navButton?: ReactElement;
}

const Wrapper = styled.div`
    margin-bottom: 1.4rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
        display: inline-flex;
    }
    button {
        line-height: normal;
        margin: 0;
    }
    button > svg {
        margin-top: -0.3rem;
    }
    h1 {
        display: inline-block;
        ${title2}
    }
`;
const DashboardButtonSearch = ({ body, navButton, title }: DashboardButtonSearchProps): ReactElement => {
    return (
        <Wrapper>
            <div>
                {navButton}
                <h1>{title ? title : 'Dashboard'}</h1>
            </div>
            <div>{body}</div>
        </Wrapper>
    );
};

export default DashboardButtonSearch;
