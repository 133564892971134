import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { getOnfidoSdkToken } from '../../../graphql/apis';
import { IdStage } from '../../../utils/application';
import Loading from '../../bits/Loading';
import Intro from '../../bits/Intro/Intro';
import { ApplicantContext } from '../../WithApplicant/applicantContext';
import { IdStagesProps } from '../ApplicationIdVerification';
import OnfidoView from './Onfido';

const IdPhoto = ({ setIdStage }: IdStagesProps): ReactElement => {
    const {
        applicant: { application, orgUuid, applicantUuid },
    } = useContext(ApplicantContext);
    const [token, setToken] = useState('');
    const [checkComplete, setCheckComplete] = useState(false);
    const {
        data: getOnfidoTokenQuery,
        loading: tokenLoading,
        error: tokenError,
    } = getOnfidoSdkToken.hook({ applicationUuid: application?.applicationUuid || '', orgUuid, applicantUuid });

    useEffect(() => {
        if (tokenLoading || tokenError) {
            return;
        }
        // TODO remove log
        // Leaving in for dev
        console.log('ONFIDO TOKEN: ', { getOnfidoTokenQuery });
        if (getOnfidoTokenQuery?.getOnfidoSdkToken) {
            setToken(getOnfidoTokenQuery.getOnfidoSdkToken);
        } else {
            setToken('');
        }
    }, [getOnfidoTokenQuery, tokenLoading, tokenError]);

    const updateOnCompletedCheck = useCallback((completed: boolean) => {
        setCheckComplete(completed);
    }, []);

    useEffect(() => {
        if (checkComplete) {
            setIdStage(IdStage.ID_RESULT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkComplete]);

    return (
        <>
            {tokenError ? (
                <div>An error occurred please refresh the page...</div>
            ) : (
                <Intro
                    text={[]}
                    title="Photo ID verification"
                    onCancel={() => setIdStage(IdStage.ID_INTRO)}
                    cancelTitle="Back to details"
                    body={
                        !tokenLoading && token ? (
                            <OnfidoView token={token} setCheckComplete={updateOnCompletedCheck} />
                        ) : (
                            <Loading />
                        )
                    }
                />
            )}
        </>
    );
};

export default IdPhoto;
