import styled from 'styled-components';
import React, { ReactElement } from 'react';
import { overline } from '../../../theme/cssSnippets';
import * as c from 'proxyaddress-common/constants';
import { red400, shade600 } from '../../../theme/colours';
import { includes } from 'ramda';

interface StatusBadgeProps {
    text: string;
}

interface StyledStatusBadgeProps {
    text: string;
}

const colourPicker = (text: string) => {
    if (includes(text, [c.ACTIVE, c.PENDING, 'NOT STARTED', 'ACTION REQUIRED'])) {
        return red400;
    } else {
        return shade600;
    }
};

const StyledStatusBadge = styled.div<StyledStatusBadgeProps>`
    ${overline}
    font-weight: 600;
    color: ${(props) => colourPicker(props.text)};
    border: 0.08rem solid;
    display: inline-block;
    padding-left: 0.2rem;
    padding-right: 0.1rem;
    height: 1rem;
`;

const StatusBadge = ({ text }: StatusBadgeProps): ReactElement => {
    return <StyledStatusBadge text={text}>{text}</StyledStatusBadge>;
};

export default StatusBadge;
