import { GridApi } from '@ag-grid-community/core';
import { StaffUser } from 'proxyaddress-common/types/staffUser';
import { values } from 'ramda';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { listStaffUsersByOrg } from '../../graphql/staffUser';
import { contentSecondary } from '../../theme/colours';
import { foxtrot } from '../../theme/sizing';
import { getOrgDetailsRenderers, GridWrapper } from '../../utils/grid';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import Button from '../bits/Buttons/Button';
import IconButton from '../bits/Buttons/IconButton';
import Grid from '../bits/Grid/Grid';
import { viewOrgDetailsColumns } from '../bits/Grid/gridOptions';
import Loading from '../bits/Loading';
import { useDataFormatter } from '../hooks/hooks';
import { AdminStateContext } from '../WithAdminState/adminState';
import DashboardButtonSearch from './DashboardButtonSearch';

const OrganisationDetails = (): ReactElement => {
    const history = useHistory();
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const { organisations, currentOrganisation, setCurrentOrganisation } = useContext(AdminStateContext);
    const { orgUuid }: { orgUuid: string } = useParams();
    const { data: ListStaffUsersData, loading, error } = listStaffUsersByOrg.hook({ orgUuid });

    const staffUsers = useDataFormatter({
        data: ListStaffUsersData?.listStaffUsersByOrg,
        Type: StaffUser,
        targetUuid: 'staffUuid',
    }) as Record<string, StaffUser>;

    useEffect(() => {
        // sets org on refresh
        if (!currentOrganisation) setCurrentOrganisation(organisations[orgUuid]);

        return () => {
            setCurrentOrganisation(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const body = (
        <>
            <Button
                buttonStyle="primary"
                text="+ Add Staff"
                onClick={() => history.push(`/admin/staffUser/new/${orgUuid}`)}
            />
        </>
    );

    const redirectToOrgAdmin = () => {
        setCurrentOrganisation(undefined);
        history.push('/admin/organisations');
    };

    const navButton = (
        <IconButton icon="backArrow" size={foxtrot} fill={contentSecondary} onClick={redirectToOrgAdmin} />
    );

    return (
        <div>
            {staffUsers && (
                <GridWrapper>
                    <DashboardButtonSearch title={currentOrganisation?.name} body={body} navButton={navButton} />
                    <Grid
                        gridApi={gridApi}
                        setGridApi={setGridApi}
                        columns={viewOrgDetailsColumns}
                        rowData={values(staffUsers)}
                        frameworkComponent={getOrgDetailsRenderers()}
                    />
                </GridWrapper>
            )}
            {loading && <Loading />}
            {error && <BodyCopy>Something went wrong please try again or contact an admin for support</BodyCopy>}
        </div>
    );
};

export default OrganisationDetails;
