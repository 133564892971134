import { either, isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { failure } from 'io-ts/lib/PathReporter';

// Simplify logging from io-ts
export const decode = <I, A>(input: I, type: t.Decoder<I, A>): string | void => {
    const maybe = type.decode(input);
    if (isLeft(maybe)) {
        return `Validation failed for input:
      ${JSON.stringify(input, null, 2)}
      Error details:
      ${failure(maybe.left)
          .map((s) => '- ' + s.replace(/supplied to .*}\//, '\n - Supplied to: '))
          .join('\n\n')}
      `;
    }
};

// Converts date objects to string
type ISOStringFromDate = t.Type<string, Date, unknown>;

const ISOStringFromDate: ISOStringFromDate = new t.Type<string, Date, unknown>(
    'ISOStringFromDate',
    t.string.is,
    (u, c) =>
        either.chain(date.validate(u, c), (d: Date) => {
            const s = d.toISOString();
            return t.string.is(s) ? t.success(s) : t.failure(u, c);
        }),
    (a) => new Date(a),
);

export const StringFromDate = t.union([ISOStringFromDate, t.string]);

export const defaultActivityLogDetails = {
    applicationUuid: undefined,
    checkId: undefined,
    checkType: undefined,
    newStaffAssignedUuid: undefined,
    note: undefined,
};
