import React, { ReactElement, useContext } from 'react';
import Button from '../bits/Buttons/Button';
import { ApplicantContext } from '../WithApplicant/applicantContext';
import AuthContext from '../WithAuth/AuthContext';

const Applicant = (): ReactElement => {
    const { signOut } = useContext(AuthContext);
    const { applicant } = useContext(ApplicantContext);

    return (
        <div>
            <span>
                {applicant.firstName} {applicant.lastName}
            </span>
            <Button buttonStyle="primary" text="Sign Out" onClick={() => signOut()} />
            <p>Application</p>
        </div>
    );
};

export default Applicant;
