import { ApolloError } from '@apollo/client';
import { PERSONAL_DETAILS } from 'proxyaddress-common/constants';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { updateApplicant, UpdateApplicantParams } from '../../../graphql/applicant';
import { green400, red400, shade600 } from '../../../theme/colours';
import { charlie, echo, golf } from '../../../theme/sizing';
import { getApplicantDetailsToUpdateFields, isApplicationUndecided } from '../../../utils/applicant';
import { GridWrapper as Wrapper } from '../../../utils/grid';
import Button from '../../bits/Buttons/Button';
import DropdownButton from '../../bits/Buttons/DropdownButton';
import DropdownOptionButton from '../../bits/Buttons/DropdownOptionButton';
import Caption from '../../bits/Caption/Caption';
import SelectInput from '../../bits/FormFields/SelectInput';
import ApproveApplicationModal from '../../bits/Modal/ApproveApplicationModal';
import RejectApplicationModal from '../../bits/Modal/RejectApplicationModal';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import ApplicantDetailsTabPages from './ApplicantDetailsTabPages';
import ApplicantHeading from './ApplicantHeading';

const LayoutWrapper = styled.div`
    display: grid;
    grid-template-columns: 30% auto;
    grid-gap: ${charlie};
    margin-top: ${echo};
    height: 100%;
    h2 {
        margin-bottom: ${golf};
    }
`;

const StaffDetailsWrapper = styled.div`
    margin-top: ${echo};
    p {
        display: inline;
    }
    div {
        padding-left: 0;
    }
`;

const AssignStaffButtonsWrapper = styled.div`
    display: inline-block;
    button {
        margin-right: 0.3rem;
    }
`;

const CurrentStaffMemberWrapper = styled.div`
    display: flex;
    button {
        margin-left: ${echo};
        font-size: 0.7rem;
    }
`;

const ApplicantDetails = (): ReactElement => {
    const { currentApplicant, setCurrentApplicant, setCurrentApplicantTab, staffOptions } =
        useContext(StaffUserContext);
    const [updateApplicantMutation, { loading, error }] = updateApplicant.hook();
    const [newStaffAssignedUuid, setNewStaffAssignedUuid] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);

    useEffect(() => {
        return () => {
            setCurrentApplicant(undefined);
            setCurrentApplicantTab(PERSONAL_DETAILS);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reviewApplicationOptions = (
        <ul>
            <li>
                <DropdownOptionButton
                    icon="tick"
                    color={green400}
                    text="Approve application"
                    onClick={() => setApproveModalOpen(true)}
                />
            </li>
            <li>
                <DropdownOptionButton
                    icon="cross"
                    color={red400}
                    text="Reject application"
                    onClick={() => setRejectModalOpen(true)}
                />
            </li>
        </ul>
    );

    const changeAssignedStaffUser = async () => {
        if (currentApplicant && newStaffAssignedUuid) {
            const applicantWithStaffUserUpdates = getApplicantDetailsToUpdateFields(
                currentApplicant,
                newStaffAssignedUuid,
            );
            const variables: UpdateApplicantParams = applicantWithStaffUserUpdates;
            try {
                await updateApplicantMutation({ variables });
                setShowDropdown(false);
            } catch (updateError) {
                const err = updateError as ApolloError;
                setErrorMessage(err.message);
            }
        }
    };

    return (
        <Wrapper>
            {currentApplicant && (
                <>
                    {approveModalOpen && (
                        <ApproveApplicationModal
                            open={approveModalOpen}
                            setOpen={setApproveModalOpen}
                            applicant={currentApplicant}
                        />
                    )}
                    {rejectModalOpen && (
                        <RejectApplicationModal
                            open={rejectModalOpen}
                            setOpen={setRejectModalOpen}
                            applicant={currentApplicant}
                        />
                    )}
                    <ApplicantHeading name={currentApplicant.name} status={currentApplicant.applicationStatus} />
                    <LayoutWrapper>
                        <div>
                            {isApplicationUndecided(currentApplicant.applicationStatus) && (
                                <DropdownButton text="Review application" options={reviewApplicationOptions} />
                            )}
                            <StaffDetailsWrapper>
                                <Caption color={shade600}>Assigned staff</Caption>

                                {!showDropdown && (
                                    <CurrentStaffMemberWrapper>
                                        <p>{currentApplicant.staffMember.name}</p>
                                        <Button
                                            buttonStyle="link"
                                            text="Assign"
                                            onClick={() => setShowDropdown(true)}
                                        />
                                    </CurrentStaffMemberWrapper>
                                )}
                                {showDropdown && (
                                    <>
                                        <SelectInput
                                            id="assignStaff"
                                            label="Assign staff"
                                            hideLabel
                                            width={'80%'}
                                            options={staffOptions}
                                            defaultValue={currentApplicant.staffMember.staffUuid}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                setNewStaffAssignedUuid(e.target.value)
                                            }
                                            error={error && errorMessage}
                                        />
                                        <AssignStaffButtonsWrapper>
                                            <Button
                                                buttonStyle="primary"
                                                text="Assign"
                                                disabled={loading}
                                                onClick={changeAssignedStaffUser}
                                            />
                                            <Button
                                                buttonStyle="secondary"
                                                text="Cancel"
                                                onClick={() => setShowDropdown(false)}
                                            />
                                        </AssignStaffButtonsWrapper>
                                    </>
                                )}
                            </StaffDetailsWrapper>
                        </div>
                        <ApplicantDetailsTabPages />
                    </LayoutWrapper>
                </>
            )}
        </Wrapper>
    );
};

export default ApplicantDetails;
