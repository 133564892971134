import { detailsStage } from 'proxyaddress-common/types';
import { isEmpty, values } from 'ramda';
import React, { FormEvent, ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { updateApplicant, UpdateApplicantParams } from '../../graphql/applicant';
import { shade200 } from '../../theme/colours';
import {
    AddressInfo,
    addressInfoInitialState,
    addressInitialState,
    formatApplicantAddress,
} from '../../utils/addresses';
import {
    ApplicantAddressErrors,
    applicantAddressInitialErrors,
    addressTypes,
    validateApplicantAddressInput,
    validateMonthYear,
} from '../../utils/application';
import { ERROR_DATE } from '../../utils/constants';
import {
    getApplicantAddressUpdateFields,
    getApplicantUpdateFields,
    getApplicationUpdateFields,
} from '../../utils/users';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import Button from '../bits/Buttons/Button';
import Caption from '../bits/Caption/Caption';
import Form from '../bits/Form/Form';
import Label from '../bits/Form/Label';
import DateInputField, { DateInputsWrapper } from '../bits/FormFields/Dates';
import FormField from '../bits/FormFields/FormField';
import SelectInput from '../bits/FormFields/SelectInput';
import { ApplicantContext } from '../WithApplicant/applicantContext';
import AddressList from './AddressList';
import AddressForm, { AddressWithCountry } from '../bits/Form/AddressForm';

const StyledAddressForm = styled.div`
    border-bottom: 0.07rem solid ${shade200};
    border-top: 0.07rem solid ${shade200};
    padding: 1rem;
`;

const ApplicantAddressForm = (): ReactElement => {
    const { applicant } = useContext(ApplicantContext);
    const [errors, setErrors] = useState<ApplicantAddressErrors>(applicantAddressInitialErrors);
    const [address, setAddress] = useState<AddressWithCountry>({ ...addressInitialState, country: 'GBR' });
    const [addressInfo, setAddressInfo] = useState<AddressInfo>(addressInfoInitialState);
    const [movedDates, setMovedDates] = useState({
        movedInYear: '',
        movedInMonth: '',
        movedOutYear: '',
        movedOutMonth: '',
    });

    const [updateApplicantMutation, { loading, error }] = updateApplicant.hook();

    useEffect(() => {
        const { movedInYear, movedInMonth, movedOutYear, movedOutMonth } = movedDates;
        if (movedInMonth && movedInYear) {
            const validatedMonthYearIn = validateMonthYear(movedInMonth, movedInYear);
            if (validatedMonthYearIn) {
                setErrors({ ...errors, dateMovedInError: '' });
                setAddressInfo({ ...addressInfo, dateMovedIn: validatedMonthYearIn });
            } else {
                setErrors({ ...errors, dateMovedInError: ERROR_DATE });
                setAddressInfo({ ...addressInfo, dateMovedIn: '' });
            }
        }
        if (movedOutMonth && movedOutYear) {
            const validatedMonthYearOut = validateMonthYear(movedOutMonth, movedOutYear);
            if (validatedMonthYearOut) {
                setErrors({ ...errors, dateMovedOutError: '' });
                setAddressInfo({ ...addressInfo, dateMovedOut: validatedMonthYearOut });
            } else {
                setErrors({ ...errors, dateMovedOutError: ERROR_DATE });
                setAddressInfo({ ...addressInfo, dateMovedOut: '' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [movedDates]);

    const resetAddressForm = () => {
        setAddressInfo(addressInfoInitialState);
        setAddress(addressInitialState);
        setMovedDates({
            movedInYear: '',
            movedInMonth: '',
            movedOutYear: '',
            movedOutMonth: '',
        });
        setErrors(applicantAddressInitialErrors);
    };

    const addAddress = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!applicant.application) return;
        const addressToSave = formatApplicantAddress(addressInfo, address);
        const validationResult = validateApplicantAddressInput(addressToSave);
        const addressHistory = applicant.addressHistory
            ? applicant.addressHistory.map((a) => getApplicantAddressUpdateFields(a))
            : [];
        const variables: UpdateApplicantParams = {
            applicant: {
                ...getApplicantUpdateFields(applicant),
                application: {
                    ...getApplicationUpdateFields(applicant.application),
                    progress: detailsStage,
                },
                addressHistory: [...addressHistory, addressToSave],
            },
        };
        if (values(validationResult).every(isEmpty)) {
            await updateApplicantMutation({
                variables,
            });
            resetAddressForm();
        } else {
            setErrors(validationResult);
        }
    };

    const formBody = (
        <>
            <BodyCopy>Please provide your last 5 years of addresses</BodyCopy>
            <BodyCopy>
                Please enter as much as you can remember - previous addresses are not required however they can help us
                more accurately complete checks
            </BodyCopy>
            <AddressForm address={address} setAddress={setAddress} errors={errors} allowCountry={true} />
            <SelectInput
                label="Type of accommodation"
                id="addressType"
                options={addressTypes}
                placeholder="Please select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setAddressInfo({ ...addressInfo, addressType: e.target.value })
                }
                error={errors.addressTypeError}
            />
            <FormField error={errors.dateMovedOutError || errors.dateMovedInError}>
                <Label>Date moved in</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="movedInMonth"
                        type="text"
                        value={movedDates.movedInMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMovedDates({ ...movedDates, movedInMonth: e.target.value })
                        }
                        preText="Month"
                        size="small"
                    />
                    <DateInputField
                        id="movedInYear"
                        type="text"
                        value={movedDates.movedInYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMovedDates({ ...movedDates, movedInYear: e.target.value })
                        }
                        preText="Year"
                    />
                </DateInputsWrapper>
                <Caption>For example, 11 2020</Caption>
                <Label>Date moved out</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="movedOutMonth"
                        type="text"
                        value={movedDates.movedOutMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMovedDates({ ...movedDates, movedOutMonth: e.target.value })
                        }
                        preText="Month"
                        size="small"
                    />
                    <DateInputField
                        id="movedOutYear"
                        type="text"
                        value={movedDates.movedOutYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMovedDates({ ...movedDates, movedOutYear: e.target.value })
                        }
                        preText="Year"
                    />
                </DateInputsWrapper>
                <Caption>For example, 11 2020</Caption>
            </FormField>
            <Button buttonStyle="primary" disabled={loading} type="submit" text={loading ? 'Loading' : 'Add address'} />
        </>
    );

    return (
        <StyledAddressForm>
            <AddressList />
            <Form
                title="Previous address"
                body={formBody}
                onSubmit={(e) => addAddress(e)}
                error={!!error}
                loading={loading}
            />
        </StyledAddressForm>
    );
};

export default ApplicantAddressForm;
