import { includes } from 'ramda';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import NegativeHeader from '../patterns/NegativeHeader';
import AuthLanding from './AuthLanding';
import ForgotPassword from './ForgotPassword';
import Loading from '../bits/Loading';
import RequireNewPassword from './RequireNewPassword';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 4.5rem;
`;

const getCustomComponent = (authState: string) => {
    switch (authState) {
        case 'loading':
            return Loading;
        case 'signIn':
            return AuthLanding;
        case 'forgotPassword':
            return ForgotPassword;
        case 'requireNewPassword':
            return RequireNewPassword;
        default:
            throw new Error(`Unknown authState: ${authState}`);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const CustomAmplify = (props: any): ReactElement => {
    const { pathname } = useLocation();
    const { authState, children } = props;
    const [isApplicant, setIsApplicant] = useState(false);

    useEffect(() => {
        if (includes('application', pathname)) {
            setIsApplicant(true);
        } else {
            setIsApplicant(false);
        }
    }, [pathname]);

    if (authState === 'signedIn') {
        return children;
    } else {
        const CustomComponent = getCustomComponent(authState);
        return (
            <>
                <NegativeHeader />
                <Wrapper>
                    <CustomComponent isApplicant={isApplicant} {...props} />
                </Wrapper>
            </>
        );
    }
};

export default CustomAmplify;
