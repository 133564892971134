import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { hotel } from '../../../theme/sizing';
import { shade500 } from '../../../theme/colours';
import { InputWrapper } from './InputWrapper';

interface FormInputFieldProps {
    id: string;
    type?: string;
    preText?: string;
    button?: ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    placeholder?: string;
    value?: string;
    defaultValue?: string;
    disabled?: boolean;
    autocomplete?: string;
    autofocus?: boolean;
    multiple?: boolean;
    hasError?: boolean;
}

const Input = styled.input`
    align-self: flex-start;
    border: none;
    height: ${hotel};
    width: 20.5rem;
    padding-left: 0.2rem;
    transition: border-color 0.1s ease-in-out;
    &:disabled {
        border: 0.125rem solid ${shade500};
        color: ${shade500};
    }
`;

const FormInputField = ({
    id,
    type,
    preText,
    button,
    onChange,
    onBlur,
    placeholder,
    value,
    defaultValue,
    disabled,
    autocomplete,
    autofocus,
    multiple,
    hasError,
    ...props
}: FormInputFieldProps): ReactElement => {
    return (
        <InputWrapper type={type} {...props}>
            {preText && <p>{preText}</p>}
            <Input
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                autoComplete={autocomplete}
                autoFocus={autofocus}
                multiple={multiple}
                readOnly={!onChange}
            />
            {button}
        </InputWrapper>
    );
};

export default FormInputField;
