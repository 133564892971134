import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Tabs, { TabLink } from '../bits/Tabs/Tabs';
import PrimaryHeader from './PrimaryHeader';

interface AccountsNavigationProps {
    children: ReactNode;
}

const AdminBody = styled.div`
    height: 90vh;
    width: 100%;
    margin-top: 4%;
`;

const AdminNavigation = ({ children, ...props }: AccountsNavigationProps) => {
    const adminLinks: TabLink[] = [
        {
            link: '/admin/organisations',
            label: 'Organisations',
        },
        {
            link: '/admin/staff',
            label: 'Staff',
        },
        {
            link: '/admin/addresses',
            label: 'Addresses',
        },
        {
            link: '/admin/councilAreas',
            label: 'Council Areas',
        },
    ];

    const menu = <Tabs links={adminLinks} />;

    return (
        <div {...props}>
            <PrimaryHeader menu={menu} />
            <AdminBody>{children}</AdminBody>
        </div>
    );
};

export default AdminNavigation;
