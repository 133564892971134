import { FetchResult, MutationHookOptions, MutationOptions, MutationTuple, useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Client } from './client';

type WrappedMutationOptions<D, P> = Omit<MutationOptions<D, P>, 'mutation'>;

interface WrappedMutation<P, D> {
    promise: (client: Client, params: P) => Promise<FetchResult<D>>;
    hook: (options?: MutationHookOptions<D, P> | undefined) => MutationTuple<D, P>;
}

const wrapMutation = <P, D>(mutation: DocumentNode): WrappedMutation<P, D> => {
    return {
        promise: async (
            client: Client,
            params: P,
            options: WrappedMutationOptions<D, P> = {},
        ): Promise<FetchResult<D>> => {
            return client.mutate<D, P>({
                ...options,
                mutation: mutation,
                variables: params,
            });
        },
        hook: (options: MutationHookOptions<D, P> = {}): MutationTuple<D, P> => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useMutation(mutation, {
                ...options,
            });
        },
    };
};

export default wrapMutation;
