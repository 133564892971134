import gql from 'graphql-tag';
import wrapQuery from './wrapQuery';
import { Org } from 'proxyaddress-common/types/organisation';
import wrapMutation from './wrapMutation';

interface ListOrganisationData {
    listOrganisations: Org[];
}

export const OrganisationType = `{
    orgUuid
    name
    councilAreaUuids
    createdAt
    createdBy
    status
    orgType
    applicationCredits
    serviceCredits
    subscriptionEmail
    subscriptionExpiry
    modifiedAt
}`;

export const listOrganisationQuery = gql`
    query listOrganisations {
        listOrganisations ${OrganisationType}
    }
`;

export const listOrganisations = wrapQuery<void, ListOrganisationData>(listOrganisationQuery);

export interface CreateOrgParams {
    organisation: {
        name: string;
        councilAreaUuids: string[];
        createdBy: string;
        status: string;
        orgType: string;
        subscriptionEmail: string;
    };
}

interface CreateOrganisationData {
    createOrganisation: Org;
}

export const OrganisationInputType = `{
    name
    councilAreaUuids
    createdBy
    status
    orgType
    subscriptionEmail
}`;

const mutation = gql`
    mutation createOrganisation($organisation: NewOrganisationInput!) {
        createOrganisation(organisation: $organisation) ${OrganisationType}
    }
`;

export const createOrganisation = wrapMutation<CreateOrgParams, CreateOrganisationData>(mutation);

export interface GetOrganisationParams {
    orgUuid: string;
}

export interface GetOrganisationData {
    getOrganisation: Org;
}

export const getOrganisationQuery = gql`
    query getOrganisation($orgUuid: String!) {
        getOrganisation(orgUuid: $orgUuid) ${OrganisationType}
    }
`;

export const getOrganisation = wrapQuery<GetOrganisationParams, GetOrganisationData>(getOrganisationQuery);
