import * as t from 'io-ts';
import { omit, pick } from 'ramda';
import * as c from '../constants';
import optionalToUndefined from '../t/optionalToUndefined';
import { StaffUser } from '.';
import { Address } from './address';
import { ProxyAddress } from './proxyAddress';
import { OnfidoReport } from './onfido';

const ActivityLogEntryDetails = t.type({
    applicationUuid: optionalToUndefined(t.string),
    checkId: optionalToUndefined(t.string),
    checkType: optionalToUndefined(t.string),
    newStaffAssignedUuid: optionalToUndefined(t.string),
    note: optionalToUndefined(t.string),
});

export const LogType = t.union([
    t.literal(c.NOTE),
    t.literal(c.EMAIL),
    t.literal(c.REMINDER),
    t.literal(c.APPLICATION_CREATED),
    t.literal(c.APPLICATION_STARTED),
    t.literal(c.APPLICATION_DECISION),
    t.literal(c.APPLICANT_DETAILS_UPDATED),
    t.literal(c.PROXY_ADDRESS_ASSIGNED),
    t.literal(c.STAFF_ASSIGNED),
    t.literal(c.CHECK_STARTED),
    t.literal(c.CHECK_RETURNED),
    t.literal(c.ALL_CHECKS_RETURNED),
    t.literal(c.UPLOAD), // Upload not being used in this version, but leaving here because may be wanted for future versions
]);

export type LogType = t.TypeOf<typeof LogType>;

export const ActivityLogEntryType = t.type({
    applicantUuid: t.string,
    orgUuid: t.string,
    activityLogUuid: t.string,
    createdAt: t.string,
    createdBy: t.string,
    logType: LogType,
    details: ActivityLogEntryDetails,
});

export type ActivityLogEntryType = t.TypeOf<typeof ActivityLogEntryType>;

export const ActivityLogInput = t.type(omit(['activityLogUuid', 'createdAt'], ActivityLogEntryType.props));

export type ActivityLogInput = t.TypeOf<typeof ActivityLogInput>;

const ApplicationStages = t.union([
    t.literal(c.INTRO),
    t.literal(c.DETAILS),
    t.literal(c.ID_VERIFICATION),
    t.literal(c.SURVEY),
    t.literal(c.SERVICE_CHOICE),
    t.literal(c.COMPLETE),
]);

export type ApplicationStages = t.TypeOf<typeof ApplicationStages>;

export const PProgress = t.partial({
    __typename: t.literal('Progress'),
});

export const TProgress = t.type({
    [c.INTRO]: t.boolean,
    [c.DETAILS]: t.boolean,
    [c.ID_VERIFICATION]: t.boolean,
    [c.SURVEY]: t.boolean,
    [c.SERVICE_CHOICE]: t.boolean,
    [c.COMPLETE]: t.boolean,
});

export const Progress = t.intersection([TProgress, PProgress]);

export type Progress = t.TypeOf<typeof Progress>;

export const defaultProgress = {
    [c.INTRO]: false,
    [c.DETAILS]: false,
    [c.ID_VERIFICATION]: false,
    [c.SURVEY]: false,
    [c.SERVICE_CHOICE]: false,
    [c.COMPLETE]: false,
};

export const completedProgress = {
    [c.INTRO]: true,
    [c.DETAILS]: true,
    [c.ID_VERIFICATION]: true,
    [c.SURVEY]: true,
    [c.SERVICE_CHOICE]: true,
    [c.COMPLETE]: true,
};

export const detailsStage = {
    ...defaultProgress,
    [c.INTRO]: true,
};

export const idStage = {
    ...detailsStage,
    [c.DETAILS]: true,
};

export const surveyStage = {
    ...idStage,
    [c.ID_VERIFICATION]: true,
};

export const serviceStage = {
    ...surveyStage,
    [c.SURVEY]: true,
};

export const completeStage = {
    ...surveyStage,
    [c.SERVICE_CHOICE]: true,
};

export const ApplicationStatus = t.union([
    t.literal(c.PENDING),
    t.literal(c.STARTED),
    t.literal(c.ACTION_REQUIRED),
    t.literal(c.APPROVED),
    t.literal(c.REJECTED),
    t.literal(c.CLOSED_WITHOUT_DECISION),
]);

export type ApplicationStatus = t.TypeOf<typeof ApplicationStatus>;

export const CheckResult = t.union([t.literal(c.AWAITING_RESULT), t.literal(c.CLEAR), t.literal(c.CONSIDER)]);

export const FormattedReport = t.partial({
    ...omit(['properties', 'breakdown', 'documents'], OnfidoReport.props),
    properties: optionalToUndefined(t.string),
    breakdown: optionalToUndefined(t.string),
    documents: optionalToUndefined(t.array(t.string)),
});

export type FormattedReport = t.TypeOf<typeof FormattedReport>;

export const TCheck = t.type({
    checkStatus: t.string,
    applicantId: t.string,
    checkId: optionalToUndefined(t.string),
    reportIds: optionalToUndefined(t.array(t.string)),
    checkResult: optionalToUndefined(CheckResult),
    startedAt: optionalToUndefined(t.string),
    completedAt: optionalToUndefined(t.string),
    staffStartedByUuid: optionalToUndefined(t.string),
    reports: optionalToUndefined(t.array(FormattedReport)),
});

export const PCheck = t.partial({
    __typename: t.literal('Check'),
});

export const Check = t.intersection([TCheck, PCheck]);

export type Check = t.TypeOf<typeof Check>;

export const TApplicationType = t.type({
    orgUuid: t.string,
    applicantUuid: t.string,
    applicationUuid: t.string,
    applicationStatus: ApplicationStatus,
    progress: Progress,
    hasParticipantSurvey: t.boolean,
    createdAt: t.string,
    createdBy: t.string,
    modifiedAt: t.string,
    identityReport: optionalToUndefined(t.string),
    closingDecisionReason: optionalToUndefined(t.string),
    staffClosedByUuid: optionalToUndefined(t.string),
    applicationClosedDate: optionalToUndefined(t.string),
    watchlistCheck: optionalToUndefined(Check),
    idCheck: optionalToUndefined(Check),
});

export const PApplicationType = t.partial({
    __typename: t.literal('Application'),
});

export const UpdateApplicationInput = t.type(
    omit(['createdBy', 'createdAt', 'modifiedAt', 'idCheck', 'watchlistCheck'], TApplicationType.props),
);

export type UpdateApplicationInput = t.TypeOf<typeof UpdateApplicationInput>;

export const ApplicationType = t.intersection([TApplicationType, PApplicationType]);

export type ApplicationType = t.TypeOf<typeof ApplicationType>;

export const ApplicationsType = t.array(ApplicationType);

export type ApplicationsType = t.TypeOf<typeof ApplicationsType>;

export const TApplicantAddress = t.type({
    applicantAddressUuid: optionalToUndefined(t.string),
    orgUuid: optionalToUndefined(t.string),
    applicantUuid: optionalToUndefined(t.string),
    addressType: optionalToUndefined(t.string),
    dateMovedIn: optionalToUndefined(t.string),
    dateMovedOut: optionalToUndefined(t.string),
    modifiedAt: optionalToUndefined(t.string),
    createdAt: optionalToUndefined(t.string),
    country: optionalToUndefined(t.string),
});

export const PApplicantAddress = t.partial({ __typename: t.literal('ApplicantAddress') });

export const ApplicantAddress = t.intersection([TApplicantAddress, PApplicantAddress, Address]);

export type ApplicantAddress = t.TypeOf<typeof ApplicantAddress>;

export const UpdateApplicantAddressArgs = t.type({
    ...omit(['modifiedAt', 'createdAt', '__typename'], TApplicantAddress.props),
    ...Address.props,
});

export type UpdateApplicantAddressArgs = t.TypeOf<typeof UpdateApplicantAddressArgs>;

export const PApplicant = t.partial({
    __typename: t.literal('ApplicantUser'),
});

export const TApplicant = t.type({
    applicantUuid: t.string,
    orgUuid: t.string,
    email: t.string,
    createdAt: t.string,
    createdBy: t.string,
    staffMember: t.string,
    activityLog: t.array(ActivityLogEntryType),
    closedApplications: t.array(ApplicationType),
    firstName: optionalToUndefined(t.string),
    lastName: optionalToUndefined(t.string),
    middleNames: optionalToUndefined(t.string),
    modifiedAt: optionalToUndefined(t.string),
    applicationStatus: optionalToUndefined(ApplicationStatus),
    application: optionalToUndefined(ApplicationType),
    isActive: optionalToUndefined(t.boolean),
    dateOfBirth: optionalToUndefined(t.string),
    addressHistory: optionalToUndefined(t.array(ApplicantAddress)),
    pin: optionalToUndefined(t.string),
    title: optionalToUndefined(t.string),
    phoneNumber: optionalToUndefined(t.string),
    serviceChoices: optionalToUndefined(t.array(t.string)),
    currentLocation: optionalToUndefined(Address),
    postalAddress: optionalToUndefined(Address),
    correspondenceEmail: optionalToUndefined(t.string),
    proxyAddress: optionalToUndefined(ProxyAddress),
});

export const ApplicantWithStaffDetails = t.type({
    ...omit(['staffMember'], TApplicant.props),
    staffMember: StaffUser,
    name: t.string,
});
export type ApplicantWithStaffDetails = t.TypeOf<typeof ApplicantWithStaffDetails>;

export const Applicant = t.intersection([PApplicant, TApplicant]);
export type Applicant = t.TypeOf<typeof Applicant>;

export const CreateApplicantArgs = t.type(
    pick(['orgUuid', 'createdBy', 'email', 'staffMember', 'firstName', 'lastName'], TApplicant.props),
);

export type CreateApplicantArgs = t.TypeOf<typeof CreateApplicantArgs>;

export const UpdateAddressInput = t.type({
    ...omit(['__typename'], Address.props),
});

export type UpdateAddressInput = t.TypeOf<typeof UpdateAddressInput>;

export const UpdateApplicantInput = t.type({
    ...omit(
        [
            'email',
            'createdAt',
            'createdBy',
            'modifiedAt',
            'closedApplications',
            'activityLog',
            'staffMember',
            'pin',
            'proxyAddress',
            'addressHistory',
            'postalAddress',
            'currentLocation',
        ],
        TApplicant.props,
    ),

    staffMember: optionalToUndefined(t.string),
    application: optionalToUndefined(UpdateApplicationInput),
    addressHistory: optionalToUndefined(t.array(UpdateApplicantAddressArgs)),
    postalAddress: optionalToUndefined(UpdateAddressInput),
    currentLocation: optionalToUndefined(UpdateAddressInput),
});

export type UpdateApplicantInput = t.TypeOf<typeof UpdateApplicantInput>;

export const EditApplicantArgs = t.type({
    applicant: UpdateApplicantInput,
    activityLog: optionalToUndefined(ActivityLogInput),
});

export type EditApplicantArgs = t.TypeOf<typeof EditApplicantArgs>;

export const ApplicationDecision = t.union([
    t.literal(c.APPROVED),
    t.literal(c.REJECTED),
    t.literal(c.CLOSED_WITHOUT_DECISION),
]);

export type ApplicationDecision = t.TypeOf<typeof ApplicationDecision>;

export const SubmitApplicationDecisionArgs = t.type({
    closingDecision: ApplicationDecision,
    activityLogEntry: ActivityLogInput,
});

export type SubmitApplicationDecisionArgs = t.TypeOf<typeof SubmitApplicationDecisionArgs>;

export const EditApplicationArgs = t.type({
    application: UpdateApplicationInput,
    activityLogEntry: optionalToUndefined(ActivityLogInput),
});

export type EditApplicationArgs = t.TypeOf<typeof EditApplicationArgs>;
