import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Caption from '../Caption/Caption';
import Label from '../Form/Label';
import FormField from './FormField';
import { InputWrapper } from './InputWrapper';

interface TextAreaProps {
    id: string;
    label?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    width?: string;
    height?: string;
    helperText?: string;
    error?: string;
    value: string;
}

interface StyledTextAreaProps {
    height?: string;
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
    height: ${(props) => (props.height ? props.height : 'auto')};
`;

const TextAreaInput = ({
    id,
    label,
    onChange,
    width,
    height,
    helperText,
    error,
    value,
    ...props
}: TextAreaProps): ReactElement => (
    <FormField error={error} {...props}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <InputWrapper width={width}>
            <StyledTextArea id={id} onChange={onChange} height={height} value={value} />
        </InputWrapper>
        {helperText && <Caption>{helperText}</Caption>}
    </FormField>
);

export default TextAreaInput;
