import { GridApi } from '@ag-grid-community/core';
import { values } from 'ramda';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { getProxyAddressesCellRenderers, GridWrapper } from '../../utils/grid';
import Button from '../bits/Buttons/Button';
import Grid from '../bits/Grid/Grid';
import { proxyAddressesColumns } from '../bits/Grid/gridOptions';
import { AdminStateContext } from '../WithAdminState/adminState';
import DashboardButtonSearch from './DashboardButtonSearch';

const ProxyAddressAdmin = () => {
    const history = useHistory();
    const { proxyAddresses, councilAreas, redirectToProxyAddress } = useContext(AdminStateContext);

    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    const body = (
        <Button
            buttonStyle="primary"
            text="+ New ProxyAddress"
            onClick={() => {
                history.push('/admin/addresses/new');
            }}
        />
    );

    return (
        <GridWrapper>
            <DashboardButtonSearch title="Addresses" body={body} />
            <Grid
                gridApi={gridApi}
                rowData={values(proxyAddresses)}
                setGridApi={setGridApi}
                columns={proxyAddressesColumns}
                frameworkComponent={{ ...getProxyAddressesCellRenderers({ councilAreas, redirectToProxyAddress }) }}
            />
        </GridWrapper>
    );
};

export default ProxyAddressAdmin;
