// Errors
export const ERROR_NO_NAME = 'Name is required.';
export const ERROR_COUNCIL_AREA = 'Council is required';
export const ERROR_STATUS = 'Status is required';
export const ERROR_ORG_TYPE = 'Organisation type is required';
export const ERROR_NO_EMAIL = 'Email is required';

export const ERROR_INVALID_EMAIL = 'Invalid email address format';
export const ERROR_NO_CODE = 'Code is required';
export const ERROR_CODE_LENGTH = 'Code must have 6 digits';
export const ERROR_NO_NEW_PASSWORD = 'A new password is required';
export const ERROR_INVALID_PASSWORD = 'Password must be a minimum of 8 characters';
export const ERROR_PASSWORD_CHARACTERS_REQUIRED = 'Password must contain letters and at least one number';
export const ERROR_NO_RETYPE_NEW_PASSWORD = 'Please retype your new password';
export const ERROR_MATCH_PASSWORDS = 'Passwords do not match';
export const ERROR_NO_FIRST_NAME = 'First name is required';
export const ERROR_NO_LAST_NAME = 'Last name is required';
export const ERROR_NO_DOB = 'Date of birth is required';
export const ERROR_DOB_TYPE = 'Please enter a valid date';
export const ERROR_NO_POSTCODE = 'Postcode is required';
export const ERROR_DATE = 'Valid date is required';
export const ERROR_NO_TOWN = 'Town or city is required';
export const ERROR_NO_STREET = 'A street name is required';
export const ERROR_NO_HOUSE = 'A house name or number is required';
export const ERROR_NO_ADDRESS_TYPE = 'Type of accommodation is required';
export const ERROR_US_NO_STATE = 'A 2 letter state is required for US address';
export const ERROR_NO_COUNCIL_AREA = 'Council area is required';

// Error checking
export const usernameRegex =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
