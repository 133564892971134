import { isLeft } from 'fp-ts/lib/Either';
import React, { FC, useEffect, useState } from 'react';
import Loading from '../bits/Loading';
import { Config, ConfigContext } from './config';

const getConfig = async (): Promise<Config> => {
    let configData: unknown;
    if (process.env.NODE_ENV === 'development') {
        configData = {
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || '',
            userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '',
            userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
            graphQlEndpoint: process.env.REACT_APP_GRAPHQL_END_POINT || '',
        };
    } else {
        configData = await (await fetch('/config.json')).json();
    }

    const maybeConfig = Config.decode(configData);
    if (isLeft(maybeConfig)) {
        throw new Error('Config was the wrong shape');
    }
    return maybeConfig.right;
};

export const WithConfig: FC<{ children?: React.ReactNode }> = ({ children }: { children?: React.ReactNode }) => {
    const [config, setConfig] = useState<Config>();

    useEffect(() => {
        const getAndSetConfig = async () => {
            const newConfig = await getConfig();
            setConfig(newConfig);
        };

        getAndSetConfig();
    }, []);

    if (!config) {
        console.log('loading config...');
        return <Loading />;
    }

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
