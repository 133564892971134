import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { TertiaryButton } from '../Buttons/Button';
import { shade100, shade500, bodyBackground } from '../../../theme/colours';
import { bravo } from '../../../theme/sizing';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import { ApplicantTab } from 'proxyaddress-common/types';
import { applicantTabs } from 'proxyaddress-common/constants';

interface TabProps {
    title: string;
    currentTab: ApplicantTab;
}

const StyledApplicantDetailsTabs = styled.div`
    position: absolute;
`;

const Tab = styled(TertiaryButton)<TabProps>`
    height: 2.58rem;
    background: ${(props) => (props.title === props.currentTab ? bodyBackground : shade100)};
    border: 0.001rem solid ${(props) => (props.title === props.currentTab ? shade500 : shade100)};
    border-bottom: none;
    margin-right: ${bravo};
    z-index: ${(props) => (props.title === props.currentTab ? '1' : '0')};
    position: relative;
    &:hover {
        border-bottom: 0.1rem solid ${bodyBackground};
    }
`;

const ApplicantDetailsTabs = (): ReactElement => {
    const { currentApplicantTab, setCurrentApplicantTab } = useContext(StaffUserContext);

    return (
        <StyledApplicantDetailsTabs>
            {applicantTabs.map((tab) => (
                <Tab key={tab} title={tab} currentTab={currentApplicantTab} onClick={() => setCurrentApplicantTab(tab)}>
                    {tab}
                </Tab>
            ))}
        </StyledApplicantDetailsTabs>
    );
};

export default ApplicantDetailsTabs;
