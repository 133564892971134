import { values } from 'ramda';
import React, { ReactElement, useContext, useState } from 'react';
import { GridApi } from '@ag-grid-community/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AdminStateContext } from '../WithAdminState/adminState';
import Grid from '../bits/Grid/Grid';
import { organisationColumns } from '../bits/Grid/gridOptions';
import DashboardButtonSearch from './DashboardButtonSearch';
import { useHistory } from 'react-router-dom';
import Button from '../bits/Buttons/Button';
import { GridWrapper, getOrgAdminCellRenderers } from '../../utils/grid';

const OrganisationAdmin = (): ReactElement => {
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const { organisations, redirectToOrg } = useContext(AdminStateContext);
    const history = useHistory();

    const body = (
        <>
            <Button
                buttonStyle="primary"
                text="+ New organisation"
                onClick={() => history.push('/admin/organisations/new')}
            />
        </>
    );

    return (
        <GridWrapper>
            <DashboardButtonSearch title="Organisations" body={body} />
            {organisations && (
                <Grid
                    gridApi={gridApi}
                    setGridApi={setGridApi}
                    columns={organisationColumns}
                    rowData={values(organisations)}
                    frameworkComponent={{
                        ...getOrgAdminCellRenderers({
                            orgsData: organisations,
                            redirectToOrg,
                        }),
                    }}
                />
            )}
        </GridWrapper>
    );
};

export default OrganisationAdmin;
