import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { values } from 'ramda';
import React, { ReactElement, useContext, useState } from 'react';
import { getStaffDashboardCellRenderers, GridWrapper } from '../../utils/grid';
import Button from '../bits/Buttons/Button';
import SearchBar from '../bits/FormFields/SearchBar';
import Grid from '../bits/Grid/Grid';
import { staffDashboardColumns } from '../bits/Grid/gridOptions';
import DashboardButtonSearch from '../patterns/DashboardButtonSearch';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import Obscure from '../bits/Obscure';
import IconButton from '../bits/Buttons/IconButton';
import { useHistory } from 'react-router-dom';
import { ApplicantWithStaffDetails } from 'proxyaddress-common/types';

const Dashboard = (): ReactElement => {
    const history = useHistory();
    const { applicants, setNewApplication, setCurrentApplicant } = useContext(StaffUserContext);
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const [columnApi, setColumnApi] = useState<ColumnApi | undefined>();
    const [showNames, setShowNames] = useState(false);

    const body = (
        <>
            <Button buttonStyle="primary" text="+ New Applicant" onClick={() => setNewApplication(true)} />
            <SearchBar
                placeholder="Name or PIN"
                onChange={({ target }) => gridApi?.setQuickFilter(target.value)}
                disabled={!gridApi}
            />
        </>
    );

    const handleObscureClick = () => {
        columnApi?.setColumnVisible('obscureNames', showNames);
        columnApi?.setColumnVisible('name', !showNames);
        setShowNames(!showNames);
    };

    const obscureButton = (
        <IconButton
            icon={showNames ? 'switchUnselectedEnabled' : 'switchSelectedEnabled'}
            width="3rem"
            height="3rem"
            onClick={() => handleObscureClick()}
        />
    );

    const redirectToApplicant = (applicant: ApplicantWithStaffDetails, applicantUuid: string) => {
        if (applicant && applicantUuid) {
            setCurrentApplicant(applicant);
            history.push(`/dashboard/applicant/${applicantUuid}`);
        }
    };

    return (
        <>
            <GridWrapper>
                <DashboardButtonSearch body={body} />
                <Obscure button={obscureButton} />
                <Grid
                    gridApi={gridApi}
                    setColumnApi={setColumnApi}
                    setGridApi={setGridApi}
                    columns={staffDashboardColumns}
                    rowData={values(applicants)}
                    frameworkComponent={{
                        ...getStaffDashboardCellRenderers({ applicantData: applicants, redirectToApplicant }),
                    }}
                />
            </GridWrapper>
        </>
    );
};

export default Dashboard;
