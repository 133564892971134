import React, { useState, ReactElement, useContext, useEffect } from 'react';
import { GridApi } from '@ag-grid-community/core';
import { Title2 } from '../../bits/Titles/titles';
import Grid from '../../bits/Grid/Grid';
import { checksColumns } from '../../bits/Grid/gridOptions';
import { getChecksTabRenderers } from '../../../utils/grid';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import { formatCheckDetails } from '../../../utils/staffDashboard';
import styled from 'styled-components';
import { golf } from '../../../theme/sizing';
import { CheckDetails } from 'proxyaddress-common/types';

const GridWrapper = styled.div`
    height: 15rem;
    width: 100%;
    margin: auto;
    margin-bottom: ${golf};
`;

const ChecksTab = (): ReactElement => {
    const { currentApplicant } = useContext(StaffUserContext);
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const [checkDetails, setCheckDetails] = useState<CheckDetails[] | undefined>();

    useEffect(() => {
        if (currentApplicant?.application) {
            const formattedCheckDetails = formatCheckDetails(currentApplicant.application);
            setCheckDetails(formattedCheckDetails);
        }
    }, [currentApplicant]);

    return (
        <>
            <Title2>Checks</Title2>
            <GridWrapper>
                <Grid
                    gridApi={gridApi}
                    setGridApi={setGridApi}
                    columns={checksColumns}
                    rowData={checkDetails}
                    frameworkComponent={{ ...getChecksTabRenderers() }}
                />
            </GridWrapper>
        </>
    );
};

export default ChecksTab;
