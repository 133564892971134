import { ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { shade500 } from '../../../theme/colours';
import { bravo, charlie, delta, echo, foxtrot } from '../../../theme/sizing';
import { getApplicationStatusText } from '../../../utils/application';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import Button from '../../bits/Buttons/Button';
import Label from '../../bits/Form/Label';
import StatusBadge from '../../bits/Grid/StatusBadge';
import { Title2 } from '../../bits/Titles/titles';
import Modal from './Modal';
import { ButtonWrapper } from './RejectApplicationModal';

interface ApproveApplicationModalProps {
    open: boolean;
    setOpen: (arg: boolean) => void;
    applicant: ApplicantWithStaffDetails;
}

const ApplicationStatusWrapper = styled.div`
    margin-top: ${charlie};
    margin-bottom: ${echo};
`;

const ProxyAddressInfoWrapper = styled.div`
    div {
        margin: ${charlie} auto;
    }
`;

const InfoWrapper = styled.div`
    border-left: ${bravo} solid ${shade500};
    padding-left: ${delta};
    margin-top: ${foxtrot};
    margin-bottom: ${echo};
    p {
        font-size: 0.875rem;
        b {
            font-size: 0.875rem;
        }
    }
`;

const ApproveApplicationModal = ({ open, setOpen, applicant }: ApproveApplicationModalProps): ReactElement => {
    return (
        <Modal clickOutsideToClose open={open} setOpen={setOpen}>
            <>
                <Title2>Approve {applicant.name}'s application?</Title2>
                {applicant.applicationStatus && (
                    <ApplicationStatusWrapper>
                        <StatusBadge text={getApplicationStatusText(applicant.applicationStatus)} />
                    </ApplicationStatusWrapper>
                )}
                <Label>
                    <b>The user will be given the following details:</b>
                </Label>

                {/* TODO add display address of ProxyAddress here once PXA-79 'Assign proxy address to user' is done */}
                <ProxyAddressInfoWrapper>
                    {/* TODO add PIN here once PXA-80 'Assign unique PIN to applicant' is done  */}
                </ProxyAddressInfoWrapper>

                <InfoWrapper>
                    <BodyCopy>
                        An email with the details of {applicant.name}'s new ProxyAddress and Proxy Identification Number
                        (PIN) will be sent to <b>{applicant.email}</b>
                    </BodyCopy>
                </InfoWrapper>

                <ButtonWrapper>
                    <Button
                        buttonStyle="tertiary"
                        text="Cancel"
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                    <Button buttonStyle="primary" text="Approve application" />
                </ButtonWrapper>
            </>
        </Modal>
    );
};

export default ApproveApplicationModal;
