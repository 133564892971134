// Brand colours
export const brandA = '#2172ab';
export const brandB = '#5099ce';
export const brandC = '#3b4f52';
export const brandD = '#d0dbdd';

// Colour palette
export const blue600 = '#144467';
export const blue800 = '#1d2835';
export const red100 = '#f9c8ce';
export const red400 = '#e71d36';
export const red600 = '#931020';
export const yellow100 = '#ffd399';
export const yellow400 = '#ffa630';
export const yellow600 = '#7a4500';
export const green100 = '#6befa2';
export const green400 = '#109648';
export const green600 = '#084923';
export const white = '#ffffff';
export const shade50 = '#f5f5f5';
export const shade100 = '#f1f0f0';
export const shade200 = '#d7d5d5';
export const shade500 = '#9b9797';
export const shade600 = '#5e5a5a';
export const shade700 = '#494646';
export const shade800 = '#2a2828';

// Action colours
export const constructive = green400;
export const borderConstructive = green100;
export const contentConstructive = green600;
export const destructive = red400;
export const borderDestructive = red100;
export const contentDestructive = red600;
export const warning = yellow400;
export const borderWarning = yellow100;
export const contentWarning = yellow600;

// Background colours
export const backgroundPrimary = white;
export const backgroundSecondary = shade50;
export const backgroundTertiary = shade100;
export const bodyBackground = shade50;

// Content colours
export const contentPrimary = shade800;
export const contentSecondary = shade700;
export const contentTertiary = shade600;

// Border colours
export const borderOpaque = shade200;
export const borderTransparent = shade800 + '1A'; // 10% transparency
export const borderSelected = shade800;

// Button interaction colours
// How to convert opacity from RGBA to hex: https://stackoverflow.com/questions/5445085/understanding-colors-on-android-six-characters/11019879#11019879
export const hoveredTertiary = brandB + '1A'; // 10% transparency
