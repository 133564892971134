import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { blue600, contentTertiary } from '../../../theme/colours';
import { alpha, bravo, echo } from '../../../theme/sizing';

export interface BodyCopyProps {
    color?: string;
    children: ReactNode;
    textAlign?: 'left' | 'center' | 'right';
}

interface StyledBodyCopyProps {
    color?: string;
    textAlign?: 'left' | 'center' | 'right';
}

export const StyledBodyCopy = styled.p<StyledBodyCopyProps>`
    &,
    margin-top: ${bravo};
    * {
        color: ${(props) => props.color || contentTertiary};
        font-size: ${echo};
        text-align: ${(props) => props.textAlign || 'left'};
    }
    * + * {
        margin-top: ${alpha};
    }
    ol,
    ul {
        margin-left: 1.4em;
        li + li {
            margin-top: 0.5em;
        }
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    a {
        text-decoration: underline;
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
    b,
    strong {
        font-weight: bold;
        color: ${blue600};
    }
`;

const BodyCopy = ({ children, textAlign, ...props }: BodyCopyProps): ReactElement => (
    <StyledBodyCopy textAlign={textAlign} {...props}>
        {children}
    </StyledBodyCopy>
);

export default BodyCopy;
