import { APPLICATION_STARTED, STAFF_ASSIGNED } from 'proxyaddress-common/constants';
import { ActivityLogInput, LogType } from 'proxyaddress-common/types';
import { defaultActivityLogDetails } from 'proxyaddress-common/types/utils';
import { equals, isEmpty, or, values } from 'ramda';
import React, { ReactElement, useContext, useState } from 'react';
import styled from 'styled-components';
import { createActivityLogEntry } from '../../../graphql/activityLog';
import { shade200, shade600 } from '../../../theme/colours';
import { label2 } from '../../../theme/cssSnippets';
import { charlie, golf, hotel, india } from '../../../theme/sizing';
import {
    checkActivityLogNoteToCreate,
    getLogIcon,
    getLogIconSize,
    getLogTitle,
    initialCreateActivityLogEntryErrors,
    noteOptions,
} from '../../../utils/activityLog';
import { formatDetailsDate } from '../../../utils/staffDashboard';
import Button from '../../bits/Buttons/Button';
import Caption from '../../bits/Caption/Caption';
import Form from '../../bits/Form/Form';
import SelectInput from '../../bits/FormFields/SelectInput';
import TextAreaInput from '../../bits/FormFields/TextAreaInput';
import { Icon } from '../../bits/Icons';
import { Title2 } from '../../bits/Titles/titles';
import { StaffUserContext } from '../../WithStaffUser/staffContext';

const NoteForm = styled(Form)`
    margin-top: -${india};
    margin-bottom: ${charlie};
    width: 100%;
    form {
        text-align: left;
    }
`;

const FormLayout = styled.div`
    display: grid;
    grid-template-columns: 5% 95%;
    padding-left: 0;
`;

const NoteTextAreaWrapper = styled.div`
    margin-top: 0;
    display: grid;
    grid-template-columns: 85% 15%;
    button {
        align-self: start;
        display: inline;
        margin: 0 auto;
        margin-top: 0.3rem;
    }
    textarea {
        margin-right: 0.2rem;
    }
`;

const LogDisplay = styled.div`
    display: flex;
    border-top: 0.06rem solid ${shade200};
    border-bottom: 0.06rem solid ${shade200};
    padding: ${charlie} 0;
`;

const LogInfo = styled.div`
    margin-left: 0.4rem;
`;

const LogTitle = styled.p`
    ${label2}
    font-weight: bold;
`;

const LogDate = styled(Caption)`
    font-weight: normal;
    color: ${shade600};
`;

const LogNote = styled.p`
    margin: 0.2rem auto;
    font-size: 0.8rem;
`;

interface IconContainerProps {
    logType: LogType;
}

const IconContainer = styled.div<IconContainerProps>`
    ${(props) =>
        or(equals(props.logType, STAFF_ASSIGNED), equals(props.logType, APPLICATION_STARTED)) && ' margin: 0.5rem;'}
`;

const ActivityLogTab = (): ReactElement => {
    const { currentApplicant, setCurrentApplicant, staffUser, staffUsers } = useContext(StaffUserContext);
    const [noteType, setNoteType] = useState<string>();
    const [note, setNote] = useState('');
    const [errors, setErrors] = useState(initialCreateActivityLogEntryErrors);
    const [createActivityLogEntryMutation, { loading, error }] = createActivityLogEntry.hook();

    const submitNote = async () => {
        const validationResult = checkActivityLogNoteToCreate(note, noteType);

        if (values(validationResult).every(isEmpty) && currentApplicant?.applicantUuid) {
            const activityLogEntry: ActivityLogInput = {
                applicantUuid: currentApplicant?.applicantUuid,
                orgUuid: currentApplicant?.orgUuid,
                createdBy: staffUser?.staffUuid,
                logType: noteType as LogType,
                details: {
                    ...defaultActivityLogDetails,
                    note,
                },
            };

            const logEntryResult = await createActivityLogEntryMutation({ variables: { activityLogEntry } });

            if (currentApplicant && logEntryResult.data) {
                setNote('');
                setCurrentApplicant({
                    ...currentApplicant,
                    activityLog: [logEntryResult.data.createActivityLogEntry, ...currentApplicant.activityLog],
                });
                setErrors(initialCreateActivityLogEntryErrors);
            }
        } else {
            setErrors(validationResult);
        }
    };

    const formBody = (
        <FormLayout>
            <div>
                <Icon icon="comment" size={golf} />
            </div>
            <div>
                <SelectInput
                    label="Add a note"
                    id="address"
                    options={noteOptions}
                    placeholder="Select a note type"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setNoteType(e.target.value)}
                    error={errors.noteTypeError ? errors.noteTypeError : ''}
                />
                <NoteTextAreaWrapper>
                    <TextAreaInput
                        id="note"
                        width="100%"
                        height={hotel}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        error={errors.noteError ? errors.noteError : ''}
                    />
                    <Button buttonStyle="secondary" text="Add" type="submit" />
                </NoteTextAreaWrapper>
            </div>
        </FormLayout>
    );

    return (
        <>
            <Title2>Account activity</Title2>
            <NoteForm body={formBody} onSubmit={submitNote} error={!!error} loading={loading} />

            {currentApplicant?.activityLog.map((logEntry) => (
                <LogDisplay key={logEntry.activityLogUuid}>
                    <IconContainer logType={logEntry.logType}>
                        <Icon icon={getLogIcon(logEntry)} fill={shade600} size={getLogIconSize(logEntry.logType)} />
                    </IconContainer>
                    <LogInfo>
                        <LogTitle>{getLogTitle(logEntry, staffUsers)}</LogTitle>
                        {logEntry.details.note && <LogNote>{logEntry.details.note}</LogNote>}
                        <LogDate>{formatDetailsDate(logEntry.createdAt)}</LogDate>
                    </LogInfo>
                </LogDisplay>
            ))}
        </>
    );
};

export default ActivityLogTab;
