import { isEmpty, values } from 'ramda';
import React, { FormEvent, ReactElement, useContext, useState } from 'react';
import { StaffUser } from 'proxyaddress-common/types/staffUser';
import { createApplicant } from '../../graphql/applicant';
import {
    getCurrentUserId,
    ApplicantErrors,
    ApplicantInitialErrors,
    validateApplicantInput,
    staffUserDropdownValues,
} from '../../utils/users';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import Button from '../bits/Buttons/Button';
import Form from '../bits/Form/Form';
import SelectInput from '../bits/FormFields/SelectInput';
import TextInput from '../bits/FormFields/TextInput';
import AuthContext from '../WithAuth/AuthContext';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import Confirmation from './Confirmation';
import Intro from '../bits/Intro/Intro';
import { useHistory } from 'react-router-dom';

const CreateApplicant = (): ReactElement => {
    const history = useHistory();
    const { currentAuthenticatedUser: user } = useContext(AuthContext);
    const {
        staffUsers,
        organisation: { orgUuid },
        setNewApplication,
    } = useContext(StaffUserContext);
    const [errors, setErrors] = useState<ApplicantErrors>(ApplicantInitialErrors);
    const [createApplicantMutation, { loading, error: createApplicantError }] = createApplicant.hook();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [staffMember, setStaffMember] = useState(getCurrentUserId(user));
    const [success, setSuccess] = useState(false);
    const [showIntro, setShowIntro] = useState(true);

    const formBody = (
        <>
            <BodyCopy>
                Enter some details about the applicant below, they will have the option to change these details on
                application
            </BodyCopy>
            <BodyCopy>
                Ensure that the user’s email address is correct as this is the way that they will be asked to complete
                their application
            </BodyCopy>
            <TextInput
                label="First name (optional)"
                id="firstName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            />
            <TextInput
                label="Last name (optional)"
                id="lastName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            />
            <TextInput
                label="Email address"
                id="staffEmail"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim())}
                error={errors?.emailError}
            />
            <SelectInput
                options={staffUserDropdownValues(staffUsers as Record<string, StaffUser>)}
                label="Staff member"
                id="staffMember"
                defaultValue={getCurrentUserId(user)}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStaffMember(e.target.value)}
            />
            <BodyCopy>This application will cost X credits</BodyCopy>
            <Button buttonStyle="primary" type="submit" text="Send Application" rightIcon="rightArrow" />
        </>
    );

    const validateAndCreateApplicant = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const variables = {
            applicantUser: {
                staffMember,
                createdBy: getCurrentUserId(user),
                orgUuid,
                email,
                firstName,
                lastName,
            },
        };
        const validationResult = validateApplicantInput(variables);

        if (values(validationResult).every(isEmpty)) {
            createApplicantMutation({
                variables,
            });
            setSuccess(true);
        } else {
            setErrors(validationResult);
        }
    };

    const confirmationText = [
        'The ProxyAddress application form has been sent to the user, they should follow the link sent to them via email to complete the sign up process',
        'If the user does not receive an email, tell them to check their junk or spam folders',
        "You can resend this email by looking up the user and selecting 'Send reminder email'",
    ];

    const introText = [
        'This process will start the application for a ProxyAddress for a new user',
        'This service will send them an emailed link to fill in some details and pass an ID check so that they can be verified and added to the system',
        'The user must have an email address to use the service, if they do not, you must do xyz',
        'If a user needs to re-apply for a ProxyAddress, you must do xyz',
    ];

    const title = 'Create a new proxyaddress applicant';

    const backToDashboard = () => {
        setNewApplication(false);
        history.push('/dashboard');
    };

    return (
        <>
            {showIntro && (
                <Intro
                    text={introText}
                    title={title}
                    onSubmit={() => setShowIntro(false)}
                    onCancel={backToDashboard}
                    submitTitle="Start"
                    cancelTitle="Back to dashboard"
                />
            )}
            {!showIntro && success && (
                <Confirmation
                    title="ProxyAddress application sent"
                    text={confirmationText}
                    buttonAction={backToDashboard}
                    buttonTitle="Return to dashboard"
                />
            )}
            {!showIntro && !success && (
                <Form
                    title={title}
                    description=""
                    body={formBody}
                    onCancel={backToDashboard}
                    onSubmit={validateAndCreateApplicant}
                    cancelTitle="Cancel and discard changes"
                    loading={loading}
                    error={!!createApplicantError}
                />
            )}
        </>
    );
};

export default CreateApplicant;
