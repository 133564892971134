import { Address, ProxyAddressOsFields } from 'proxyaddress-common/types';
import { isEmpty, keys, values, equals } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getOsToken } from '../../../graphql/apis';
import {
    AddressErrors,
    addressInitialState,
    getAddressData,
    getSelectedAddressToEdit,
    getSelectedAddress,
    OsAddressAllData,
} from '../../../utils/addresses';
import { ApplicantAddressErrors, getAddressOptions } from '../../../utils/application';
import { countries } from '../../../utils/countries';
import { getProxyAddressFieldsFromOs, ProxyAddressErrors } from '../../../utils/proxyAddress';
import BodyCopy from '../BodyCopy/BodyCopy';
import Button, { LinkButton } from '../Buttons/Button';
import SearchSelectInput, { InputOption } from '../FormFields/SearchSelectInput';
import SelectInput from '../FormFields/SelectInput';
import TextInput from '../FormFields/TextInput';

export interface AddressWithCountry extends Address {
    country?: string;
}

interface AddressFormProps {
    address: AddressWithCountry;
    setAddress: (address: AddressWithCountry) => void;
    errors: ProxyAddressErrors | ApplicantAddressErrors | AddressErrors;
    setProxyAddressOsFields?: (proxyAddressOsFields: ProxyAddressOsFields) => void;
    disableManualInput?: boolean;
    allowCountry?: boolean;
}
const Wrapper = styled.div`
    width: 100%;
    p {
        margin: 0.5rem;
        margin-left: 0.3rem;
    }
    button {
        margin-left: 0.3rem;
    }
`;

const AddressFormLinkButton = styled(LinkButton)`
    margin: 0.5rem auto;
    margin-left: 0;
`;

const AddressForm = ({
    address,
    setAddress,
    errors,
    setProxyAddressOsFields,
    disableManualInput,
    allowCountry,
}: AddressFormProps) => {
    const [osToken, setOsToken] = useState('');
    const [manualEnter, setManualEnter] = useState(false);
    const [postcode, setPostcode] = useState('');
    const [selectedAddress, setSelectedAddress] = useState<OsAddressAllData | undefined>();
    const [addressOptions, setAddressOptions] = useState<Record<string, OsAddressAllData>>({});
    const [fetchOsToken, { data: osTokenData, error: tokenError, loading: tokenLoading }] = getOsToken.lazyHook();
    const [noResultsError, setNoResultsError] = useState('');

    useEffect(() => {
        const shouldPrefill = !equals(address, addressInitialState);
        if (shouldPrefill) {
            setManualEnter(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // when the address is at its initial state, AddressForm component also resets the postcode to be searched and address options to initial state
    useEffect(() => {
        if (values(address).every(isEmpty)) {
            setPostcode('');
            setAddressOptions({});
        }
    }, [address]);

    useEffect(() => {
        if (!osToken && !tokenLoading && !tokenError) fetchOsToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [osToken]);

    useEffect(() => {
        // TODO remove log
        // Leaving in for dev
        console.log('OS TOKEN: ', osTokenData?.getOsToken);
        if (osTokenData?.getOsToken) {
            setOsToken(osTokenData.getOsToken);
        } else {
            setOsToken('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [osTokenData?.getOsToken]);

    const searchPostcode = async () => {
        if (osToken) {
            const addressResults = await getAddressData(osToken, postcode);
            if (isEmpty(addressResults)) {
                return setNoResultsError('Postcode returned no results, please try again');
            }
            setAddressOptions(addressResults);
        }
    };

    const selectAddress = async (addressId: string) => {
        const addressOption = addressOptions[addressId];
        setSelectedAddress(addressOption);
        const formattedAddress = getSelectedAddress(addressOption);
        if (setProxyAddressOsFields) {
            const proxyAddressFieldsFromOs = getProxyAddressFieldsFromOs(addressOption);
            setProxyAddressOsFields(proxyAddressFieldsFromOs);
        }

        setAddress(formattedAddress);
    };

    const editAddress = async () => {
        if (selectedAddress) {
            setAddress({ ...getSelectedAddressToEdit(selectedAddress) });
            setSelectedAddress(undefined);
            setManualEnter(true);
        }
    };

    const removeSelectedAddress = () => {
        setSelectedAddress(undefined);
        setAddress(addressInitialState);
        setAddressOptions({});
    };

    return (
        <Wrapper>
            {manualEnter && (
                <>
                    <TextInput
                        label="House name"
                        id="houseName"
                        value={address.houseName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, houseName: e.target.value })
                        }
                    />
                    <TextInput
                        label="House number"
                        id="houseNumber"
                        value={address.houseNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, houseNumber: e.target.value })
                        }
                    />
                    <TextInput
                        label="Street"
                        id="streetName"
                        value={address.streetName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, streetName: e.target.value })
                        }
                    />
                    <TextInput
                        label="Town or city"
                        id="town"
                        value={address.town}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, town: e.target.value })
                        }
                        error={errors.townError}
                    />
                    <TextInput
                        label={allowCountry ? 'County/State (2 letter state abbreviation for US addresses)' : 'County'}
                        id="county"
                        value={address.county}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, county: e.target.value })
                        }
                        error={allowCountry ? errors.stateError : undefined}
                    />
                    <TextInput
                        label={allowCountry ? 'Postcode/ZIP code' : 'Postcode'}
                        id="postcode"
                        value={address.postcode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, postcode: e.target.value })
                        }
                        error={errors.postcodeError}
                    />
                    {allowCountry && (
                        <SearchSelectInput
                            label="Country"
                            id="country"
                            options={countries}
                            value={address.country}
                            defaultValue={{ value: 'GBR', label: 'United Kingdom' }}
                            onChange={(input) => {
                                if (!input) {
                                    setAddress({ ...address, country: '' });
                                    return;
                                }
                                const country = input as InputOption;
                                setAddress({ ...address, country: country.value });
                            }}
                            isMulti={false}
                            isClearable={false}
                        />
                    )}
                </>
            )}
            {!manualEnter && isEmpty(addressOptions) && (
                <>
                    <TextInput
                        label="Postcode"
                        id="postcode"
                        value={postcode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostcode(e.target.value)}
                        error={noResultsError}
                    />

                    <Button
                        buttonStyle="secondary"
                        disabled={postcode.length < 5 || !osToken}
                        onClick={searchPostcode}
                        text="Search"
                    />
                    {!disableManualInput && (
                        <AddressFormLinkButton
                            onClick={() => {
                                setManualEnter(true);
                                removeSelectedAddress();
                            }}
                        >
                            Enter address manually
                        </AddressFormLinkButton>
                    )}
                </>
            )}
            {!manualEnter && !isEmpty(addressOptions) && (
                <>
                    <SelectInput
                        label="Address"
                        id="address"
                        options={getAddressOptions(addressOptions)}
                        placeholder={`${keys(addressOptions).length} addresses found`}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => selectAddress(e.target.value)}
                    />
                    {!disableManualInput && selectedAddress && (
                        <>
                            <BodyCopy>{selectedAddress.ADDRESS}</BodyCopy>

                            <Button buttonStyle="secondary" onClick={editAddress} text="Edit chosen address" />
                        </>
                    )}
                    <AddressFormLinkButton onClick={removeSelectedAddress}>
                        Search another postcode
                    </AddressFormLinkButton>
                    {!disableManualInput && (
                        <AddressFormLinkButton
                            onClick={() => {
                                setManualEnter(true);
                                removeSelectedAddress();
                            }}
                        >
                            Enter address manually
                        </AddressFormLinkButton>
                    )}
                </>
            )}

            {!disableManualInput && manualEnter && (
                <Button
                    buttonStyle="secondary"
                    disabled={!osToken}
                    onClick={() => setManualEnter(false)}
                    text={'Search by postcode'}
                />
            )}
        </Wrapper>
    );
};

export default AddressForm;
