import { ApolloProvider } from '@apollo/client';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Client, createClientFromCognitoUser, createClientFromCredentials } from '../../graphql/client';
import Loading from '../bits/Loading';
import AuthContext from '../WithAuth/AuthContext';
import { ConfigContext } from '../WithConfig/config';

export const WithApollo: FC<{
    children?: React.ReactNode;
}> = ({ children }: { children?: React.ReactNode }) => {
    const { graphQlEndpoint } = useContext(ConfigContext);
    const { currentCredentials, currentAuthenticatedUser } = useContext(AuthContext);
    const [client, setClient] = useState<Client>();
    const previousCurrentAuthenticatedUser = useRef(currentAuthenticatedUser);

    useEffect(() => {
        const userSignedOut = previousCurrentAuthenticatedUser.current && !currentAuthenticatedUser;
        // User doesn't count as "signed in" if they need to reset their password
        const userSignedIn =
            !previousCurrentAuthenticatedUser.current &&
            currentAuthenticatedUser &&
            currentAuthenticatedUser?.challengeName !== 'NEW_PASSWORD_REQUIRED';
        const userSignedInAfterResetPassword =
            previousCurrentAuthenticatedUser.current &&
            currentAuthenticatedUser &&
            currentAuthenticatedUser?.challengeName !== 'NEW_PASSWORD_REQUIRED';

        previousCurrentAuthenticatedUser.current = currentAuthenticatedUser;

        if (!client || userSignedOut || userSignedIn || userSignedInAfterResetPassword) {
            if (client) {
                // If the currentAuthenticatedUser changes (eg because of login/logout) then we want to
                // force the client to be recreated and force all children to use the new client
                // so set the client as undefined whilst we recreate the client
                // but we don't want to do this if a user user logged in who needs to set a password
                setClient(undefined);
            }

            const getAndAssignClient = async () => {
                // We don't want to use the cognito user if they still need to set a new password!
                const useCognitoUser =
                    currentAuthenticatedUser && currentAuthenticatedUser.challengeName !== 'NEW_PASSWORD_REQUIRED';
                const newClient = useCognitoUser
                    ? createClientFromCognitoUser(graphQlEndpoint)
                    : createClientFromCredentials(graphQlEndpoint);
                setClient(newClient);
            };

            getAndAssignClient();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCredentials, currentAuthenticatedUser]);

    if (!client) {
        return <Loading />;
    }

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
