import { Address, ProxyAddressOptionsInput, ProxyAddressOsFields, ProxyAddressSource } from 'proxyaddress-common/types';
import { isEmpty, values } from 'ramda';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { createProxyAddress } from '../../graphql/proxyAddress';
import { addressInitialState } from '../../utils/addresses';
import {
    formatProxyAddress,
    initialProxyAddressOptions,
    initialProxyAddressOsFields,
    proxyAddressActiveStatusOptions,
    ProxyAddressErrors,
    proxyAddressOptionsInitialErrors,
    proxyAddressSourceOptions,
    validateProxyAddressInput,
} from '../../utils/proxyAddress';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import Button from '../bits/Buttons/Button';
import Caption from '../bits/Caption/Caption';
import AddressForm from '../bits/Form/AddressForm';
import Form from '../bits/Form/Form';
import Label from '../bits/Form/Label';
import DateInputField, { DateInputsWrapper } from '../bits/FormFields/Dates';
import FormField from '../bits/FormFields/FormField';
import SearchSelectInput, { InputOption } from '../bits/FormFields/SearchSelectInput';
import SelectInput, { InputOptions } from '../bits/FormFields/SelectInput';
import { AdminStateContext } from '../WithAdminState/adminState';

const Wrapper = styled.div`
    p {
        margin-left: 0.4rem;
        margin-bottom: 1rem;
    }
    form {
        width: min-content;
    }
`;

const CreateProxyAddress = () => {
    const history = useHistory();
    const [address, setAddress] = useState<Address>(addressInitialState);
    const [proxyAddressOptions, setProxyAddressOptions] =
        useState<ProxyAddressOptionsInput>(initialProxyAddressOptions);
    const [errors, setErrors] = useState<ProxyAddressErrors>(proxyAddressOptionsInitialErrors);
    const [proxyAddressOsFields, setProxyAddressOsFields] = useState<ProxyAddressOsFields>(initialProxyAddressOsFields);
    const [createProxyAddressMutation, { loading: proxyAddressLoading, error: proxyAddressError }] =
        createProxyAddress.hook();
    const { organisations, councilAreas } = useContext(AdminStateContext);
    const [councilAreaOptions, setCouncilAreaOptions] = useState<InputOptions[]>([]);

    useEffect(() => {
        const councilAreaOptionsMap = values(councilAreas).map((area) => {
            return { value: area.councilAreaUuid, label: area.name };
        });

        setCouncilAreaOptions([{ value: '', label: '-' }, ...councilAreaOptionsMap]);
    }, [councilAreas]);

    useEffect(() => {
        if (!proxyAddressOptions.isActive) {
            setProxyAddressOptions({
                ...proxyAddressOptions,
                activationDate: { ...initialProxyAddressOptions.activationDate },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proxyAddressOptions.isActive]);

    const addAddress = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const validationResult = validateProxyAddressInput(address, proxyAddressOptions);

            if (values(validationResult).every(isEmpty)) {
                const addressToCreate = formatProxyAddress(address, proxyAddressOsFields, proxyAddressOptions);

                await createProxyAddressMutation({ variables: { proxyAddress: addressToCreate } });
                history.push('/admin/addresses');
            } else {
                setErrors(validationResult);
            }
        } catch (error) {
            setErrors({
                ...errors,
                incorrectFormError: 'Something went wrong. Please ensure you have filled in all required fields.',
            });
        }
    };

    const formBody = (
        <>
            <BodyCopy>Use this form to add a new ProxyAddress</BodyCopy>

            <AddressForm
                address={address}
                setAddress={setAddress}
                setProxyAddressOsFields={setProxyAddressOsFields}
                errors={errors}
                disableManualInput
            />
            <SearchSelectInput
                label="Council area"
                id="councilArea"
                options={councilAreaOptions}
                onChange={(input) => {
                    if (!input) {
                        setProxyAddressOptions({
                            ...proxyAddressOptions,
                            councilAreaUuid: '',
                        });
                    }
                    const councilArea = input as InputOption;
                    setProxyAddressOptions({
                        ...proxyAddressOptions,
                        councilAreaUuid: councilArea.value,
                    });
                }}
                error={errors?.councilAreaError}
                isMulti={false}
                isClearable={false}
            />
            <SelectInput
                label="Status"
                id="isActive"
                options={proxyAddressActiveStatusOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setProxyAddressOptions({
                        ...proxyAddressOptions,
                        isActive: e.target.value === 'Active' ? true : false,
                    })
                }
            />

            <SelectInput
                label="Address source"
                id="source"
                options={proxyAddressSourceOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setProxyAddressOptions({ ...proxyAddressOptions, source: e.target.value as ProxyAddressSource })
                }
            />
            <FormField error={errors.expiryDateError}>
                <Label>Expiry date</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="expiryDay"
                        type="text"
                        value={proxyAddressOptions.expiryDate.expiryDay}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                expiryDate: {
                                    ...proxyAddressOptions.expiryDate,
                                    expiryDay: e.target.value,
                                },
                            })
                        }
                        preText="Day"
                        size="small"
                    />

                    <DateInputField
                        id="expiryMonth"
                        type="text"
                        value={proxyAddressOptions.expiryDate.expiryMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                expiryDate: {
                                    ...proxyAddressOptions.expiryDate,
                                    expiryMonth: e.target.value,
                                },
                            })
                        }
                        preText="Month"
                        size="small"
                    />

                    <DateInputField
                        id="expiryYears"
                        type="text"
                        value={proxyAddressOptions.expiryDate.expiryYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                expiryDate: {
                                    ...proxyAddressOptions.expiryDate,
                                    expiryYear: e.target.value,
                                },
                            })
                        }
                        preText="Year"
                    />
                </DateInputsWrapper>
                <Caption>For example, 3 11 2024</Caption>
                <Caption>If left blank, expiry date will default to a year from today</Caption>
            </FormField>
            <FormField error={errors.activationDateError}>
                <Label>Activation date</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="activationDay"
                        type="text"
                        value={proxyAddressOptions.activationDate.activationDay}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                activationDate: {
                                    ...proxyAddressOptions.activationDate,
                                    activationDay: e.target.value,
                                },
                            })
                        }
                        preText="Day"
                        size="small"
                        disabled={!proxyAddressOptions.isActive}
                    />

                    <DateInputField
                        id="activationMonth"
                        type="text"
                        value={proxyAddressOptions.activationDate.activationMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                activationDate: {
                                    ...proxyAddressOptions.activationDate,
                                    activationMonth: e.target.value,
                                },
                            })
                        }
                        preText="Month"
                        size="small"
                        disabled={!proxyAddressOptions.isActive}
                    />

                    <DateInputField
                        id="activationYear"
                        type="text"
                        value={proxyAddressOptions.activationDate.activationYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                activationDate: {
                                    ...proxyAddressOptions.activationDate,
                                    activationYear: e.target.value,
                                },
                            })
                        }
                        preText="Year"
                        disabled={!proxyAddressOptions.isActive}
                    />
                </DateInputsWrapper>
                <Caption>For example, 3 11 2024</Caption>
                <Caption>If left blank, activation date will default to today's date</Caption>
            </FormField>
            <SearchSelectInput
                options={values(organisations).map((org) => ({ value: org.orgUuid, label: org.name }))}
                label="Organisations"
                id="organisations"
                onChange={(options) => {
                    Array.isArray(options) &&
                        setProxyAddressOptions({
                            ...proxyAddressOptions,
                            orgUuids: options.map((option) => option.value),
                        });
                }}
                isMulti={true}
                isClearable={true}
            />

            <Button
                buttonStyle="primary"
                disabled={proxyAddressLoading}
                type="submit"
                text={'Continue'}
                rightIcon="rightArrow"
            />
        </>
    );

    return (
        <Wrapper>
            <Form
                title="Add new ProxyAddress"
                cancelTitle="Cancel and discard changes"
                onCancel={() => history.push('/admin/addresses')}
                body={formBody}
                onSubmit={(e) => addAddress(e)}
                loading={proxyAddressLoading}
                error={!!proxyAddressError}
            />
        </Wrapper>
    );
};

export default CreateProxyAddress;
