import { ICellRendererParams } from '@ag-grid-community/core';
import * as c from 'proxyaddress-common/constants';
import { CouncilAreaOutput, StaffUser, ProxyAddress, ApplicantWithStaffDetails, Org } from 'proxyaddress-common/types';
import { isNil, keys } from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../components/bits/Buttons/Button';
import Initials from '../components/bits/Grid/Initials';
import StatusBadge from '../components/bits/Grid/StatusBadge';
import { Icon } from '../components/bits/Icons';
import { red400, shade600 } from '../theme/colours';
import { getDisplayAddress } from './addresses';
import { formatGridDate, getApplicationStatusText } from './application';
import { formatProxyAddressDates, formatProxyAddressSource, getProxyAddressBuildingStatus } from './proxyAddress';
import { formatDetailsDate, getStaffInitials } from './staffDashboard';

export const GridWrapper = styled.div`
    height: 90vh;
    width: 85vw;
    margin: auto;
`;

export const obscureName = (name: string) => {
    const obscuredNameArr = name.split('').map((char, index) => {
        const prevChar = name[index - 1];
        if (/[@ _-]/.test(prevChar)) return char;
        if (index !== 0 && !/[@ -._]/.test(char)) return '*';
        else return char;
    });

    return obscuredNameArr.join('');
};

interface StaffCellRendererProps {
    applicantData: Record<string, ApplicantWithStaffDetails>;
    redirectToApplicant: (applicant: ApplicantWithStaffDetails, applicantUuid: string) => void;
}

interface OrgCellRendererProps {
    orgsData: Record<string, Org | undefined>;
    redirectToOrg: (org: Org | undefined, orgUuid: string) => void;
}

interface AdminAllStaffProps {
    staffData: Record<string, StaffUser>;
    orgsData: Record<string, Org | undefined>;
    redirectToOrg: (org: Org | undefined, orgUuid: string) => void;
}

interface AdminProxyAddressesProps {
    councilAreas: Record<string, CouncilAreaOutput>;
    redirectToProxyAddress: (proxyAddress: ProxyAddress, proxyAddressUuid: string) => void;
}

const modifiedAtFunc = ({ data }: ICellRendererParams) => {
    const { modifiedAt } = data;
    if (isNil(modifiedAt)) return '-';
    else return formatGridDate(modifiedAt);
};

const isActiveRenderer = ({ data }: ICellRendererParams) => {
    const { isActive } = data;
    if (isActive) return <StatusBadge text="ACTIVE" />;
    else return <StatusBadge text="INACTIVE" />;
};

const statusRenderer = ({ data }: ICellRendererParams) => {
    const { status } = data;
    return <StatusBadge text={status} />;
};

export const getStaffDashboardCellRenderers = ({ applicantData, redirectToApplicant }: StaffCellRendererProps) => {
    return {
        applicantLinkRenderer: ({ rowIndex, value }: ICellRendererParams) => {
            const applicantUuid = keys(applicantData)[rowIndex];
            const applicant = applicantData[applicantUuid];
            return (
                <Button
                    buttonStyle="gridLink"
                    text={value}
                    onClick={() => redirectToApplicant(applicant, applicantUuid)}
                />
            );
        },
        obscureNamesRenderer: ({ rowIndex, value }: ICellRendererParams) => {
            const applicantUuid = keys(applicantData)[rowIndex];
            const applicant = applicantData[applicantUuid];
            const hiddenName = obscureName(value);
            return (
                <Button
                    buttonStyle="gridLink"
                    text={hiddenName}
                    onClick={() => redirectToApplicant(applicant, applicantUuid)}
                />
            );
        },
        pinRenderer: ({ data }: ICellRendererParams) => {
            const { pin } = data;
            if (isNil(pin)) return '  -';
            else return pin;
        },
        lastUpdatedRenderer: modifiedAtFunc,
        idCheckRenderer: ({ data }: ICellRendererParams) => {
            const { application } = data;

            const idCheck = application?.idCheck;

            if (!idCheck || !idCheck?.checkId) return '-';
            if (idCheck.checkResult === c.CONSIDER) return <Icon icon="cross" fill={red400} />;
            if (idCheck.checkResult === c.CLEAR) return <Icon icon="tick" fill={shade600} />;
            if (idCheck.checkStatus === 'submitted') return 'Submitted';
            return idCheck.checkResult || idCheck.checkStatus;
        },
        statusRenderer: ({ data }: ICellRendererParams) => {
            const { applicationStatus } = data;
            const applicationStatusText = getApplicationStatusText(applicationStatus);
            return <StatusBadge text={applicationStatusText} />;
        },
        staffRenderer: ({ data }: ICellRendererParams) => {
            const {
                staffMember: { name },
            } = data;
            const initials = getStaffInitials(name);
            return <Initials initials={initials} />;
        },
    };
};

export const getOrgAdminCellRenderers = ({ orgsData, redirectToOrg }: OrgCellRendererProps) => {
    return {
        orgLinkRenderer: ({ rowIndex, value }: ICellRendererParams) => {
            const orgUuid = keys(orgsData)[rowIndex];
            const org = orgsData[orgUuid];
            return <Button buttonStyle="gridLink" text={value} onClick={() => redirectToOrg(org, orgUuid)} />;
        },
        statusRenderer,
        mainContactRenderer: ({ value }: ICellRendererParams) => {
            if (value === '') return '-';
            else return value;
        },
    };
};

export const getAdminAllStaffCellRenderers = ({ orgsData, redirectToOrg }: AdminAllStaffProps) => {
    return {
        staffLinkRenderer: ({ value }: ICellRendererParams) => {
            // TODO add link to wherever clicking on staff member name goes
            return <Link to={`/`}>{value}</Link>;
        },
        orgLinkRenderer: ({ data }: ICellRendererParams) => {
            const { orgUuid } = data;
            const org = orgsData[orgUuid];
            if (org)
                return <Button buttonStyle="gridLink" text={org.name} onClick={() => redirectToOrg(org, orgUuid)} />;
            else return '-';
        },
        isAdminRenderer: ({ data }: ICellRendererParams) => {
            const { isAdmin } = data;
            if (isAdmin) return <Icon icon="tick" size="1rem" />;
            else return '-';
        },
        createdAtRenderer: ({ data }: ICellRendererParams) => {
            const { createdAt } = data;
            return formatGridDate(createdAt);
        },
        modifiedAtRenderer: modifiedAtFunc,
        isActiveRenderer,
    };
};

export const getCouncilAreasCellRenderers = () => {
    return {
        statusRenderer,
        councilStatusRenderer: ({ value }: ICellRendererParams) => {
            return <StatusBadge text={value} />;
        },
    };
};

export const getOrgDetailsRenderers = () => {
    return {
        statusRenderer: isActiveRenderer,
    };
};

export const getChecksTabRenderers = () => {
    return {
        dateBegunRenderer: ({ data }: ICellRendererParams) => {
            const { startedAt } = data;
            if (isNil(startedAt)) return '-';
            else return formatDetailsDate(startedAt);
        },
        dateIssuedRenderer: ({ data }: ICellRendererParams) => {
            const { completedAt } = data;
            if (isNil(completedAt)) return '-';
            return formatDetailsDate(completedAt);
        },
        statusRenderer: ({ data }: ICellRendererParams) => {
            const { status } = data;
            return <StatusBadge text={status.toUpperCase()} />;
        },
    };
};

export const getProxyAddressesCellRenderers = ({ councilAreas, redirectToProxyAddress }: AdminProxyAddressesProps) => {
    return {
        displayAddressRenderer: ({ data: proxyAddress }: ICellRendererParams) => {
            const displayAddress = getDisplayAddress(proxyAddress);
            const { proxyAddressUuid } = proxyAddress;
            return (
                <Button
                    buttonStyle="gridLink"
                    text={displayAddress}
                    onClick={() => redirectToProxyAddress(proxyAddress, proxyAddressUuid)}
                />
            );
        },
        statusRenderer: ({ data }: ICellRendererParams) => {
            const { isActive } = data;
            return <StatusBadge text={isActive ? 'ACTIVE' : 'INACTIVE'} />;
        },
        councilAreaRenderer: ({ data }: ICellRendererParams) => {
            const { councilAreaUuid } = data;
            return councilAreas[councilAreaUuid].name;
        },
        sourceRenderer: ({ data }: ICellRendererParams) => {
            const { source } = data;
            return formatProxyAddressSource(source);
        },
        expiryDateRenderer: ({ data }: ICellRendererParams) => {
            const { expiryDate } = data;
            return formatProxyAddressDates(expiryDate);
        },
        activationDateRenderer: ({ data }: ICellRendererParams) => {
            const { activationDate } = data;
            return formatProxyAddressDates(activationDate);
        },
        buildingStatusRenderer: ({ data }: ICellRendererParams) => {
            const { blpuStateCode } = data;
            return getProxyAddressBuildingStatus(blpuStateCode);
        },
    };
};
