import * as t from 'io-ts';
import optionalToUndefined from '../t/optionalToUndefined';
import { StringFromDate } from './utils';

// Onfido have started to define their api responses but it is not complete
// github.com/onfido/onfido-openapi-spec so some responses are still of type unknown

// N.B. types here differ from api spec as converted to camelcase in onfido-node
// https://github.com/onfido/onfido-node/blob/master/src/formatting.ts

const OnfidoAddress = t.partial({
    addressLine1: t.string,
    country: t.string,
    postalCode: t.string,
    stateProvince: t.string,
    town: t.string,
    locatorType: t.string,
});

export const ApplicantCheckData = t.partial({
    id: t.string,
    reportIds: t.array(t.string),
    createdAt: t.string,
    href: t.string,
    applicantId: t.string,
    applicantProvidesData: t.boolean,
    tags: t.array(t.string),
    checkResult: t.string,
    formUri: t.string,
    redirectUri: t.string,
    resultsUri: t.string,
    privacyNoticesReadConsentGiven: t.boolean,
    webhookIds: t.array(t.string),
});

export const ApplicantCheck = t.type({
    modifiedAt: t.string,
    createdAt: t.string,
    PK: t.string,
    SK: t.string,
    GSI_PK: t.string,
    GSI_SK: t.string,
    applicantUuid: t.string,
    orgUuid: t.string,
    checkId: t.string,
    applicationUuid: t.string,
    onfidoApplicantId: t.string,
    checkStatus: t.string,
    checkType: t.string,
    checkData: ApplicantCheckData,
});

export type ApplicantCheck = t.TypeOf<typeof ApplicantCheck>;

const Result = t.partial({
    result: t.string,
});

const BreakdownResult = t.partial({
    result: t.string,
    properties: t.UnknownRecord,
});

// github.com/onfido/onfido-openapi-spec/blob/master/schemas/reports/watchlist_enhanced_breakdown.yaml
const BreakdownWatchlist = t.partial({
    // description: Asserts if there are any records found in the proprietary database of Politically Exposed Persons sourced from government lists, websites and other media sources.
    politicallyExposedPerson: Result,
    // description: Asserts if there are any records found of negative events reported by publicly and generally available media sources.
    sanction: Result,
    // description: Asserts if there are any records found in Government and International Organisations Sanctions Lists.
    adverseMedia: Result,
    // description: Asserts if there are any records found in Law-Enforcement and Regulatory bodies Monitored Lists (including Terrorism, Money Laundering and Most Wanted lists).
    monitoredLists: Result,
});

const PropertiesWatchlist = t.partial({
    address: t.array(OnfidoAddress),
    alias: t.array(t.partial({ aliasName: t.string, aliasType: t.string })),
    associate: t.array(
        t.partial({ entityName: t.string, relationshipDirection: t.string, relationshipType: t.string }),
    ),
    attribute: t.array(t.partial({ attributeType: t.string, attributeValue: t.string })),
    dateOBirth: t.array(StringFromDate),
    event: t.array(
        t.partial({
            category: t.string,
            eventDate: t.string,
            eventDescription: t.string,
            source: t.partial({ sourceDate: t.string, sourceFormat: t.string, sourceName: t.string }),
            subCategory: t.string,
        }),
    ),
    fullName: t.string,
    position: t.array(t.string),
    source: t.array(
        t.partial({
            sourcHeadline: t.string,
            sourceName: t.string,
            sourceUrl: t.string,
        }),
    ),
});

const DocumentImageReasons = t.partial({
    // description: When an image of the document is too dark to be able to see data points.
    darkPhoto: t.string,
    // description: When there is light reflecting on the document causing glare to obstruct data points.
    glareOnPhoto: t.string,
    // description: When data points are blurred and no reference can be made elsewhere in the document or if the data points are too blurry and 'they could be something else'.
    blurredPhoto: t.string,
    // description: When data points have been covered either by the applicant or by another object such as a sticker.
    coveredPhoto: t.string,
    // description: Any other reason not listed, such as when holograms are obscuring data points.
    otherPhotoIssue: t.string,
    // description: When a document is damaged and we are unable to make out data points.
    damagedDocument: t.string,
    // description: When the incorrect side of a document has been uploaded, and we have not received the front.
    incorrectSide: t.string,
    // description: When data points are not included in the image due to the document being cut off.
    cutOffDocument: t.string,
    // description: If no document has been uploaded or there is a blank image.
    noDocumentInImage: t.string,
    // description: When 2 different documents are submitted in the same check.
    twoDocumentsUploaded: t.string,
});

const DocumentQualityReasons = t.partial({
    // description: When data points are obscured to the point that we cannot confirm if the fonts match the expected ones.
    obscuredDataPoints: t.string,
    // description: When a critical security feature is obscured. This can also refer to when the holder's wet signature, necessary for the document to be valid, is not present.
    obscuredSecurityFeatures: t.string,
    // description: One of 3 reasons (1) OCR Assisted Scans (i.e. when you're able to move the mouse and highlight part of text), (2) Severely Washed out Background, (3) Overlapping Text.
    abnormalDocumentFeatures: t.string,
    // description: Any digital text or electronic watermarks on the document.
    watermarksDigitalTextOverlay: t.string,
    // description: If the corner has been physically cut off. This can be found on some documents that are no longer valid.
    cornerRemoved: t.string,
    // description: A punched hole is present.
    puncturedDocument: t.string,
    // description: When the back of a document is needed for processing, but is not available.
    missingBack: t.string,
    // description: When a document has been published digitally, there aren’t enough security features to review so we cannot perform a full fraud assessment.
    digitalDocument: t.string,
});

const OriginalDocumentReasons = t.partial({
    // description: When the applicant's document is on a physical screen or device.
    photoOfScreen: t.string,
    // description: When the applicant has used their mobile phone, tablet, or computer to take a photo within the device.
    screenshot: t.string,
    // description: When the applicant has previously captured an image of the document, printed it out, and has now taken a photo of this print out to upload.
    documentOnPrintedPaper: t.string,
    // description: When the document has clearly been captured using a scanner and there are visible indicators of this
    scan: t.string,
});

// https://github.com/onfido/onfido-openapi-spec/blob/master/schemas/reports/document_breakdown.yaml
const BreakdownIdCheck = t.partial({
    dataComparison: t.partial({
        result: t.string,
        breakdown: t.partial({
            issuingCountry: BreakdownResult,
            gender: BreakdownResult,
            dateOfExpiry: BreakdownResult,
            lastName: BreakdownResult,
            documentType: BreakdownResult,
            documentNumbers: BreakdownResult,
            firstName: BreakdownResult,
            dateOfBirth: BreakdownResult,
        }),
    }),
    dataValidation: t.partial({
        result: t.string,
        breakdown: t.partial({
            gender: BreakdownResult,
            dateOfBirth: BreakdownResult,
            documentNumbers: BreakdownResult,
            documentExpiration: BreakdownResult,
            expiryDate: BreakdownResult,
            mrz: BreakdownResult,
            barcode: BreakdownResult,
        }),
    }),
    imageIntegrity: t.partial({
        result: t.string,
        breakdown: t.partial({
            supportedDocument: BreakdownResult,
            colourPicture: BreakdownResult,
            imageQuality: t.partial({ result: t.string, properties: DocumentImageReasons }),
            conclusiveDocumentQuality: t.partial({ result: t.string, properties: DocumentQualityReasons }),
        }),
    }),
    visualAuthenticity: t.partial({
        result: t.string,
        breakdown: t.partial({
            fonts: BreakdownResult,
            pictureFaceIntegrity: BreakdownResult,
            template: BreakdownResult,
            securityFeatures: BreakdownResult,
            originalDocumentPresent: t.partial({ result: t.string, properties: OriginalDocumentReasons }),
            digitalTampering: BreakdownResult,
            other: BreakdownResult,
            faceDetection: BreakdownResult,
        }),
    }),
    dataConsistency: t.partial({
        result: t.string,
        breakdown: t.partial({
            dateOfExpiry: BreakdownResult,
            documentNumbers: BreakdownResult,
            issuingCountry: BreakdownResult,
            documentType: BreakdownResult,
            dateOfBirth: BreakdownResult,
            gender: BreakdownResult,
            firstName: BreakdownResult,
            nationality: BreakdownResult,
            lastName: BreakdownResult,
            multipleDataSourcesPresent: BreakdownResult,
        }),
    }),
    policeRecord: Result,
    compromisedDocument: Result,
    ageValidation: t.UnknownRecord,
    issuingAuthority: t.partial({
        result: t.string,
        breakdown: t.partial({
            nfcActiveAuthentication: BreakdownResult,
            nfcPassiveAuthentication: BreakdownResult,
        }),
    }),
});

const PropertiesIdCheck = t.partial({
    address: t.array(OnfidoAddress),
    dateOfBirth: t.array(t.string),
    event: t.array(
        t.partial({
            category: t.string,
            eventDate: t.string,
            eventDescription: t.string,
            source: t.partial({ sourceDate: t.string, sourceFormat: t.string, sourceName: t.string }),
            subCategory: t.string,
        }),
    ),
    fulName: t.string,
    position: t.array(t.string),
    source: t.array(t.partial({ sourceHeadline: t.string, sourceName: t.string, sourceUrl: t.string })),
});

export const OnfidoReport = t.partial({
    id: t.string,
    createdAt: t.string,
    name: t.string,
    href: t.string,
    status: optionalToUndefined(t.string),
    result: optionalToUndefined(t.string),
    subResult: optionalToUndefined(t.string),
    properties: t.partial({
        records: t.array(t.intersection([PropertiesIdCheck, PropertiesWatchlist])),
    }),
    breakdown: t.intersection([BreakdownIdCheck, BreakdownWatchlist]),
    documents: t.array(t.partial({ id: t.string })),
    checkId: t.string,
});

export type OnfidoReport = t.TypeOf<typeof OnfidoReport>;

export const OnfidoReports = t.array(OnfidoReport);

export type OnfidoReports = t.TypeOf<typeof OnfidoReports>;
