import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { delta } from '../../theme/sizing';
import { Icon } from '../bits/Icons';
import { ReactComponent as ProxyAddressNegative } from '../bits/Icons/Logo/ProxyAddressNegative.svg';
import { ReactComponent as ProxyAddressPositive } from '../bits/Icons/Logo/ProxyAddressPositive.svg';

interface LogoWrapperProps {
    theme: 'primary' | 'negative';
}

const LogoWrapper = styled.div`
    width: 30%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    svg:nth-child(1) {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
    }
    svg:nth-child(2) {
        margin-top: ${delta};
        height: 2rem;
        margin-left: 0.5rem;
    }
`;

const FullLogo = ({ theme }: LogoWrapperProps): ReactElement => {
    return (
        <LogoWrapper>
            <Icon icon={theme === 'negative' ? 'logoNegative' : 'logoPrimary'} size="2.2rem" />
            {theme === 'negative' ? <ProxyAddressNegative /> : <ProxyAddressPositive />}
        </LogoWrapper>
    );
};

export default FullLogo;
