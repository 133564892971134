import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { brandB } from '../../theme/colours';
import { charlie } from '../../theme/sizing';
import FullLogo from './FullLogo';
import Button from '../bits/Buttons/Button';
import AuthContext from '../WithAuth/AuthContext';

const Header = styled.h1`
    background-color: ${brandB};
    width: 100%;
    padding-bottom: ${charlie};
    margin-bottom: 4.5rem;
`;

const NegativeHeader = ({ showSignOut }: { showSignOut?: boolean }): ReactElement => {
    // Putting signOut here for dev will need moving
    const { signOut } = useContext(AuthContext);
    return (
        <Header>
            <FullLogo theme={'negative'} />
            {showSignOut && <Button buttonStyle="link" text="Sign Out" onClick={() => signOut()} />}
        </Header>
    );
};

export default NegativeHeader;
