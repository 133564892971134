import { gql } from '@apollo/client';
import wrapQuery from './wrapQuery';

interface GetOsTokenData {
    getOsToken: string;
}

export const getOsTokenQuery = gql`
    query getOsToken {
        getOsToken
    }
`;

export const getOsToken = wrapQuery<void, GetOsTokenData>(getOsTokenQuery);

interface GetOnfidoSdkTokenData {
    getOnfidoSdkToken: string;
}

export interface GetOnfidoSdkParams {
    orgUuid: string;
    applicantUuid: string;
    applicationUuid: string;
}

export const getOnfidoSdkTokenQuery = gql`
    query getOnfidoSdkToken($applicantUuid: String!, $orgUuid: String!, $applicationUuid: String!) {
        getOnfidoSdkToken(applicantUuid: $applicantUuid, orgUuid: $orgUuid, applicationUuid: $applicationUuid)
    }
`;

export const getOnfidoSdkToken = wrapQuery<GetOnfidoSdkParams, GetOnfidoSdkTokenData>(getOnfidoSdkTokenQuery);

interface SubmitOnfidoCheckData {
    submitOnfidoCheck: string;
}

export interface SubmitOnfidoCheckParams {
    orgUuid: string;
    applicantUuid: string;
    applicationUuid: string;
    staffStartedByUuid: string;
}

export const submitOnfidoCheckQuery = gql`
    query submitOnfidoCheck(
        $applicantUuid: String!
        $orgUuid: String!
        $applicationUuid: String!
        $staffStartedByUuid: String!
    ) {
        submitOnfidoCheck(
            applicantUuid: $applicantUuid
            orgUuid: $orgUuid
            applicationUuid: $applicationUuid
            staffStartedByUuid: $staffStartedByUuid
        )
    }
`;

export const submitOnfidoCheck = wrapQuery<SubmitOnfidoCheckParams, SubmitOnfidoCheckData>(submitOnfidoCheckQuery);
