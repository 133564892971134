import React, { FormEvent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { echo } from '../../../theme/sizing';
import Loading from '../Loading';
import Button from '../Buttons/Button';
import { Title2 } from '../Titles/titles';

interface FormProps {
    body: ReactNode;
    title?: string;
    cancelTitle?: string;
    description?: string;
    onCancel?: () => void;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    loading: boolean;
    error: boolean;
}

const StyledForm = styled.div`
    text-align: centre;
    display: flex;
    flex-direction: column;
    margin-top: ${echo};
`;

const StyledFormBody = styled.form.attrs(({ onSubmit }) => ({
    onSubmit: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onSubmit) {
            onSubmit(e);
        }
    },
}))`
    text-align: left;
    > * + * {
        margin-top: ${echo};
    }
    width: 50%;
    margin: auto;
    @media (max-width: 650px) {
        width: 100%;
        margin-left: 5px;
    }
`;

const Form = ({
    body,
    title,
    description,
    onCancel,
    onSubmit,
    cancelTitle,
    loading,
    error,
    ...props
}: FormProps): ReactElement => (
    <StyledForm>
        <StyledFormBody onSubmit={(e) => onSubmit(e)} {...props}>
            <Title2>{title}</Title2>
            {onCancel && <Button buttonStyle="link" text={cancelTitle} onClick={onCancel} leftIcon="backArrow" />}
            {loading && <Loading />}
            {error && <Title2>Something went wrong. Please try again or contact an admin user</Title2>}
            {!loading && !error && body}
        </StyledFormBody>
    </StyledForm>
);

export default Form;
