import React, { ReactElement, useState, useEffect } from 'react';
import Form from '../../bits/Form/Form';
import TextInput from '../../bits/FormFields/TextInput';
import FormField from '../../bits/FormFields/FormField';
import Label from '../../bits/Form/Label';
import DateInputField, { DateInputsWrapper } from '../../bits/FormFields/Dates';
import Caption from '../../bits/Caption/Caption';
import { EditDetailsErrors, EditApplicantDetailsForm } from '../../../utils/applicant';
import { equals, isEmpty } from 'ramda';
import { Address } from 'proxyaddress-common/types';
import AddressForm from '../../bits/Form/AddressForm';
import { addressInitialState, getDisplayAddress } from '../../../utils/addresses';
import { Title2 } from '../../bits/Titles/titles';
import styled from 'styled-components';
import { shade200 } from '../../../theme/colours';
import { PrimaryButton } from '../../bits/Buttons/Button';
import SelectInput from '../../bits/FormFields/SelectInput';
import { titles } from '../../../utils/application';

interface EditApplicantPersonalDetailsProps {
    details: EditApplicantDetailsForm;
    setDetails: (details: EditApplicantDetailsForm) => void;
    updateError: boolean;
    errors: EditDetailsErrors;
    submit: () => void;
}

const Wrapper = styled.div`
    form {
        margin-top: -3rem;
        margin-left: 0;
        > h2 {
            margin-left: 0.2rem;
        }
    }
    p {
        margin-left: 0.3rem;
        margin-bottom: 1rem;
    }
`;

const AddressTitle = styled(Title2)`
    margin-top: 0.5rem;
    padding-left: 0.2rem;
`;

const SubformWrapper = styled.div`
    margin-top: 3rem;
    border-top: 0.1rem solid ${shade200};
    width: 100%;
`;

const SubmitButton = styled(PrimaryButton)`
    margin-left: 0.3rem;
`;

const EditApplicantPersonalDetails = ({
    details,
    setDetails,
    updateError,
    errors,
    submit,
}: EditApplicantPersonalDetailsProps): ReactElement => {
    const [postalAddress, setPostalAddress] = useState<Address>(details.postalAddress || addressInitialState);
    const [currentLocation, setCurrentLocation] = useState<Address>(details.currentLocation || addressInitialState);

    useEffect(() => {
        // Ensures a possibly undefined postal address is not updated to have a postal address with all empty string fields
        const shouldUpdate = !equals(postalAddress, addressInitialState);

        if (shouldUpdate) {
            setDetails({
                ...details,
                postalAddress: { ...postalAddress, displayAddress: getDisplayAddress(postalAddress) },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postalAddress]);

    useEffect(() => {
        // Ensures a possibly undefined postal address is not updated to have a postal address with all empty string fields
        const shouldUpdate = !equals(postalAddress, addressInitialState);
        if (shouldUpdate) {
            setDetails({
                ...details,
                currentLocation: { ...currentLocation, displayAddress: getDisplayAddress(currentLocation) },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation]);

    const formBody = (
        <>
            <Caption>
                <p>Remember to click 'Save details' when you are finished.</p>
                <p>Note: An applicant's details cannot be edited once a check has been requested.</p>
            </Caption>
            <SelectInput
                options={titles}
                label="Title"
                id="title"
                defaultValue={details.title}
                onChange={(e) => setDetails({ ...details, title: e.target.value })}
            />
            <TextInput
                id="firstName"
                label="First name"
                defaultValue={details.firstName}
                onChange={(e) => setDetails({ ...details, firstName: e.target.value })}
            />
            <TextInput
                id="middleNames"
                label="Middle names"
                defaultValue={details.middleNames}
                onChange={(e) => setDetails({ ...details, middleNames: e.target.value })}
            />
            <TextInput
                id="lastName"
                label="Last name"
                defaultValue={details.lastName}
                onChange={(e) => setDetails({ ...details, lastName: e.target.value })}
            />
            <FormField error={errors.dateOfBirthError}>
                <Label>Date of birth</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="Day"
                        type="text"
                        value={details.dateOfBirth.dobDay}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setDetails({ ...details, dateOfBirth: { ...details.dateOfBirth, dobDay: e.target.value } })
                        }
                        preText="Day"
                        hasError={!isEmpty(errors.dateOfBirthError)}
                        size="small"
                    />
                    <DateInputField
                        id="Month"
                        type="text"
                        value={details.dateOfBirth.dobMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setDetails({
                                ...details,
                                dateOfBirth: { ...details.dateOfBirth, dobMonth: e.target.value },
                            })
                        }
                        preText="Month"
                        hasError={!isEmpty(errors.dateOfBirthError)}
                        size="small"
                    />
                    <DateInputField
                        id="Year"
                        type="text"
                        value={details.dateOfBirth.dobYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setDetails({ ...details, dateOfBirth: { ...details.dateOfBirth, dobYear: e.target.value } })
                        }
                        preText="Year"
                        hasError={!isEmpty(errors.dateOfBirthError)}
                    />
                </DateInputsWrapper>
                <Caption>For example, 25 11 1965</Caption>
            </FormField>

            <TextInput
                id="correspondenceEmail"
                label="Correspondence email"
                defaultValue={details.correspondenceEmail}
                onChange={(e) => setDetails({ ...details, correspondenceEmail: e.target.value.trim() })}
                error={errors.correspondenceEmailError}
            />

            <TextInput
                id="phoneNumber"
                label="Phone number"
                defaultValue={details.phoneNumber}
                onChange={(e) => setDetails({ ...details, phoneNumber: e.target.value })}
            />
            <SubformWrapper>
                <AddressTitle>Postal address </AddressTitle>
                <AddressForm address={postalAddress} setAddress={setPostalAddress} errors={errors.postalAddressError} />
            </SubformWrapper>
            <SubformWrapper>
                <AddressTitle>Current location </AddressTitle>
                <AddressForm
                    address={currentLocation}
                    setAddress={setCurrentLocation}
                    errors={errors.currentLocationError}
                />
            </SubformWrapper>
            <SubmitButton type="submit">Save details</SubmitButton>
        </>
    );

    return (
        <Wrapper>
            <Form body={formBody} onSubmit={submit} loading={false} error={updateError} />
        </Wrapper>
    );
};

export default EditApplicantPersonalDetails;
