import React, { FormEvent, ReactElement, useContext, useState } from 'react';
import styled from 'styled-components';
import TextInput from '../bits/FormFields/TextInput';
import Confirmation from '../patterns/Confirmation';
import AuthContext, { CognitoError } from '../WithAuth/AuthContext';
import AmplifyBase from './AmplifyBase';
import Button from '../bits/Buttons/Button';
import { useHistory } from 'react-router-dom';
import { foxtrot } from '../../theme/sizing';
import { formatUsername } from '../../utils/users';

interface ForgotPasswordProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange: (authState: string, data?: any) => void;
}

const Wrapper = styled.div`
    display: block;
    justify-content: center;
    form {
        width: 150%;
    }
    button {
        margin-bottom: ${foxtrot};
    }
`;

const ForgotPassword = ({ onStateChange }: ForgotPasswordProps): ReactElement => {
    const history = useHistory();
    const { forgotPassword } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [error, setError] = useState<{ message: string } | undefined>();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleError = (code: string) => {
        switch (code) {
            case 'UserNotFoundException':
                return 'Email does not exist';
            case 'LimitExceededException':
                return 'Too many attempts. Please try again later.';
            default:
                return `Unknown error has occured: ${code}`;
        }
    };

    const onNewPassword = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (username.length === 0) return setError({ message: 'Email is required' });

        const validUsername = formatUsername(username);
        if (!validUsername) return setError({ message: 'Invalid email address format' });

        try {
            await forgotPassword(validUsername);
            setShowConfirmation(true);
        } catch (errorResponse) {
            const err = errorResponse as CognitoError;
            console.log(err);
            setError({ message: handleError(err.code) });
        }
    };

    const body = (
        <>
            <p>Here you can reset the password to your ProxyAddress account</p>
            <TextInput
                label="Email address"
                id="username"
                placeholder="Email"
                onChange={({ target }) => setUsername(target.value)}
                value={username}
            />
        </>
    );

    const navButton = (
        <Button
            buttonStyle="link"
            leftIcon="backArrow"
            text="Remembered? Back to login"
            onClick={() => onStateChange('signIn')}
        />
    );

    // TODO correct email
    const confirmationText = [
        'A verification code and link to reset your password has been sent to your email.',
        'You can also press the button below to be taken to the new password page.',
        'If you need immediate help, please contact EMAIL HERE',
    ];

    return (
        <Wrapper>
            {!showConfirmation ? (
                <AmplifyBase
                    heading="Forgotten password"
                    body={body}
                    submitLabel="Send password reset email"
                    onSubmit={onNewPassword}
                    navButton={navButton}
                    error={error}
                />
            ) : (
                <>
                    <Button
                        buttonStyle="link"
                        text="Back to login"
                        leftIcon="backArrow"
                        onClick={() => onStateChange('signIn')}
                    />
                    <Confirmation
                        title="Password reset email sent"
                        text={confirmationText}
                        buttonTitle="Reset password"
                        rightIcon="rightArrow"
                        buttonAction={() => {
                            history.push('/resetPassword');
                        }}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default ForgotPassword;
