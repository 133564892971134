import { createContext } from 'react';
import { StaffUser } from 'proxyaddress-common/types/staffUser';
import { Org } from 'proxyaddress-common/types/organisation';
import { ApplicantWithStaffDetails, ApplicantTab } from 'proxyaddress-common/types';
import { InputOptions } from '../bits/FormFields/SelectInput';

export interface StaffUserState {
    staffUser: StaffUser;
    setStaffUser: (staffUser: StaffUser) => void;
    staffUsers: Record<string, StaffUser>;
    staffOptions: InputOptions[];
    organisation: Org;
    setNewApplication: (newApplication: boolean) => void;
    newApplication: boolean;
    applicants: Record<string, ApplicantWithStaffDetails>;
    currentApplicant: ApplicantWithStaffDetails | undefined;
    setCurrentApplicant: (applicant: ApplicantWithStaffDetails | undefined) => void;
    currentApplicantTab: ApplicantTab;
    setCurrentApplicantTab: (tabWindow: ApplicantTab) => void;
}

export const StaffUserContext = createContext<StaffUserState>({} as StaffUserState);
