import styled from 'styled-components';
import React, { ReactElement } from 'react';
import { echo } from '../../../theme/sizing';
import BodyCopy from '../BodyCopy/BodyCopy';

import { Title2 } from '../Titles/titles';

interface ErrorPageProps {
    message?: string;
}

const StyledErrorPage = styled.div`
    text-align: centre;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: ${echo};
`;

const ErrorPage = ({ message }: ErrorPageProps): ReactElement => (
    <StyledErrorPage>
        <Title2>Something went wrong!</Title2>
        <BodyCopy>An error ocurred please refresh the page. If the problem continues please contact support.</BodyCopy>
        <BodyCopy textAlign="left">Error: {message || 'unknown error'}</BodyCopy>
    </StyledErrorPage>
);

export default ErrorPage;
