import axios from 'axios';
import { isRight } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { ApplicantAddress, Address, ProxyAddress } from 'proxyaddress-common/types';
import { pick, prop, reduce, sortBy } from 'ramda';

export const TOsAddressLPI = t.type({
    UPRN: t.string,
    ADDRESS: t.string,
    TOWN_NAME: t.string,
    POSTCODE_LOCATOR: t.string,
});
export const POsAddressLPI = t.partial({
    USRN: t.string,
    LPI_KEY: t.string,
    PAO_TEXT: t.string,
    PAO_START_NUMBER: t.string,
    STREET_DESCRIPTION: t.string,
    ADMINISTRATIVE_AREA: t.string,
    RPC: t.string,
    X_COORDINATE: t.number,
    Y_COORDINATE: t.number,
    STATUS: t.string,
    LOGICAL_STATUS_CODE: t.string,
    LOCALITY_NAME: t.string,
    CLASSIFICATION_CODE: t.string,
    CLASSIFICATION_CODE_DESCRIPTION: t.string,
    LOCAL_CUSTODIAN_CODE: t.number,
    LOCAL_CUSTODIAN_CODE_DESCRIPTION: t.string,
    POSTAL_ADDRESS_CODE: t.string,
    POSTAL_ADDRESS_CODE_DESCRIPTION: t.string,
    BLPU_STATE_CODE_DESCRIPTION: t.string,
    TOPOGRAPHY_LAYER_TOID: t.string,
    LAST_UPDATE_DATE: t.string,
    ENTRY_DATE: t.string,
    STREET_STATE_CODE: t.string,
    STREET_STATE_CODE_DESCRIPTION: t.string,
    STREET_CLASSIFICATION_CODE: t.string,
    STREET_CLASSIFICATION_CODE_DESCRIPTION: t.string,
    LPI_LOGICAL_STATUS_CODE: t.string,
    LPI_LOGICAL_STATUS_CODE_DESCRIPTION: t.string,
    LANGUAGE: t.string,
    MATCH: t.number,
    MATCH_DESCRIPTION: t.string,
    BLPU_STATE_CODE: t.string,
    BLPU_STATE_DATE: t.string,
    ORGANISATION: t.string,
    SAO_START_NUMBER: t.string,
    PARENT_UPRN: t.string,
});

export const OsAddressLPI = t.intersection([TOsAddressLPI, POsAddressLPI]);

// eslint-disable-next-line
export type OsAddressLPI = t.TypeOf<typeof OsAddressLPI>;

export const TOsAddressDPA = t.type({
    UPRN: t.string,
    ADDRESS: t.string,
    POSTCODE: t.string,
    POST_TOWN: t.string,
});
export const POsAddressDPA = t.partial({
    UDPRN: t.string,
    BUILDING_NUMBER: t.string,
    ORGANISATION_NAME: t.string,
    BUILDING_NAME: t.string,
    THOROUGHFARE_NAME: t.string,
    DEPENDENT_LOCALITY: t.string,
    RPC: t.string,
    X_COORDINATE: t.number,
    Y_COORDINATE: t.number,
    STATUS: t.string,
    LOGICAL_STATUS_CODE: t.string,
    CLASSIFICATION_CODE: t.string,
    CLASSIFICATION_CODE_DESCRIPTION: t.string,
    LOCAL_CUSTODIAN_CODE: t.number,
    LOCAL_CUSTODIAN_CODE_DESCRIPTION: t.string,
    POSTAL_ADDRESS_CODE: t.string,
    POSTAL_ADDRESS_CODE_DESCRIPTION: t.string,
    BLPU_STATE_CODE: t.union([t.string, t.null]),
    BLPU_STATE_CODE_DESCRIPTION: t.string,
    TOPOGRAPHY_LAYER_TOID: t.string,
    LAST_UPDATE_DATE: t.string,
    ENTRY_DATE: t.string,
    BLPU_STATE_DATE: t.string,
    LANGUAGE: t.string,
    MATCH: t.number,
    MATCH_DESCRIPTION: t.string,
    PARENT_UPRN: t.string,
});

export const OsAddressDPA = t.intersection([TOsAddressDPA, POsAddressDPA]);

// eslint-disable-next-line
export type OsAddressDPA = t.TypeOf<typeof OsAddressDPA>;

export const OsAddressAllData = t.intersection([OsAddressDPA, OsAddressLPI]);
// eslint-disable-next-line
export type OsAddressAllData = t.TypeOf<typeof OsAddressAllData>;

export const addressInitialState = {
    houseName: '',
    houseNumber: '',
    streetName: '',
    town: '',
    county: '',
    locality: '',
    postcode: '',
    displayAddress: '',
    administrativeArea: '',
    uprn: '',
    classification: '',
    classificationCode: '',
};

export const addressInfoInitialState = {
    addressType: '',
    dateMovedIn: '',
    dateMovedOut: '',
};

export interface AddressInfo {
    addressType: string;
    dateMovedIn: string;
    dateMovedOut: string;
}

const osPlacesBaseUrl = 'https://api.os.uk/search/places/v1/postcode';

const makeOsPostCodeSearchUrl = (postcode: string): string => `${osPlacesBaseUrl}?postcode=${postcode}&dataset=LPI,DPA`;

export const getAddressData = async (token: string, postcode: string): Promise<Record<string, OsAddressAllData>> => {
    if (token) {
        const result = await axios.get(makeOsPostCodeSearchUrl(postcode), {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (result?.data?.results) {
            return reduce<any, any>(
                (acc, record) => {
                    if (record.DPA) {
                        const maybeOsAddress = OsAddressDPA.decode(record.DPA);
                        if (isRight(maybeOsAddress)) {
                            const UPRN = maybeOsAddress.right.UPRN;
                            if (acc[UPRN]) {
                                return { ...acc, [UPRN]: { ...acc[UPRN], ...maybeOsAddress.right } };
                            }
                            return { ...acc, [UPRN]: maybeOsAddress.right };
                        }
                        console.log(PathReporter.report(maybeOsAddress).join('\n\n'));
                        throw new Error('Address data DPA was not the right shape');
                    }
                    if (record.LPI) {
                        const maybeOsAddress = OsAddressLPI.decode(record.LPI);
                        if (isRight(maybeOsAddress)) {
                            const UPRN = maybeOsAddress.right.UPRN;
                            if (acc[UPRN]) {
                                return { ...acc, [UPRN]: { ...acc[UPRN], ...maybeOsAddress.right } };
                            }
                            return acc;
                        }
                        console.log(PathReporter.report(maybeOsAddress).join('\n\n'));
                        throw new Error('Address data LPI was not the right shape');
                    }
                    return acc;
                },
                {},
                result.data.results,
            );
        }
    }
    return {};
};

export const getSelectedAddress = (selectedAddress: OsAddressAllData): Address => {
    return {
        town: selectedAddress.POST_TOWN || selectedAddress.TOWN_NAME,
        postcode: selectedAddress.POSTCODE || selectedAddress.POSTCODE_LOCATOR,
        houseNumber: selectedAddress.BUILDING_NUMBER || '',
        houseName: selectedAddress.BUILDING_NAME || '',
        streetName: selectedAddress.THOROUGHFARE_NAME || selectedAddress.STREET_DESCRIPTION || '',
        locality: selectedAddress.DEPENDENT_LOCALITY || selectedAddress.LOCALITY_NAME || '',
        administrativeArea: selectedAddress.ADMINISTRATIVE_AREA || '',
        county: selectedAddress.ADMINISTRATIVE_AREA || '',
        uprn: selectedAddress.UPRN,
        classification: selectedAddress.CLASSIFICATION_CODE_DESCRIPTION || '',
        classificationCode: selectedAddress.CLASSIFICATION_CODE || '',
        displayAddress: selectedAddress.ADDRESS,
    };
};

export const getSelectedAddressToEdit = (selectedAddress: OsAddressAllData): Address => {
    return {
        ...pick(
            ['houseName', 'houseNumber', 'streetName', 'locality', 'town', 'county', 'postcode', 'displayAddress'],
            getSelectedAddress(selectedAddress),
        ),
        ...pick(['administrativeArea', 'uprn', 'classification', 'classificationCode'], addressInitialState),
    };
};

export const getDisplayAddress = (address: Address | ProxyAddress) => {
    const { houseName, houseNumber, streetName, town, county, locality, postcode } = address;

    //  If all fields of an address are empty, the display address is an empty string
    return `${houseName && houseName + ', '}${houseNumber && houseNumber + ' '}${streetName && streetName + ', '}${
        locality && locality + ','
    }${town && locality ? ' ' + town + ', ' : town && town + ', '}${county && county + ', '}${postcode}`;
};

export const formatApplicantAddress = (addressInfo: AddressInfo, address: Address): ApplicantAddress => {
    const displayAddress = getDisplayAddress(address);
    const addressToValidate = { ...address, ...addressInfo, displayAddress };

    const maybeAddress = ApplicantAddress.decode(addressToValidate);
    if (!isRight(maybeAddress)) {
        console.log(PathReporter.report(maybeAddress).join('\n\n'));
        throw new Error('Applicant address is wrong shape');
    }
    return maybeAddress.right;
};

export const sortAddressByDate = (addresses: ApplicantAddress[]): ApplicantAddress[] => {
    return sortBy(prop('dateMovedIn'))(addresses);
};

export interface AddressErrors {
    townError: string;
    postcodeError: string;
    stateError?: string;
}
