import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { bodyBackground, shade500 } from '../../../theme/colours';
import ApplicantDetailsTabs from '../../bits/Tabs/ApplicantDetailsTabs';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import ChecksTab from './ChecksTab';
import PersonalDetailsTab from './PersonalDetailsTab';
import { useHistory } from 'react-router-dom';
import ActivityLogTab from './ActivityLogTab';
import { ACTIVITY, CHECKS, PERSONAL_DETAILS } from 'proxyaddress-common/constants';

const StyledApplicantDetailsTabPages = styled.div`
    border: 0.001rem solid ${shade500};
    margin-top: 2.54rem;
    position: relative;
    background: ${bodyBackground};
    padding: 1rem;
`;

const ApplicantDetailsTabPages = (): ReactElement => {
    const history = useHistory();
    const { currentApplicantTab, currentApplicant } = useContext(StaffUserContext);

    const getTabPage = () => {
        if (currentApplicant) {
            switch (currentApplicantTab) {
                case PERSONAL_DETAILS:
                    return <PersonalDetailsTab applicant={currentApplicant} />;
                case CHECKS:
                    return <ChecksTab />;
                case ACTIVITY:
                    return <ActivityLogTab />;
                default:
                    return <PersonalDetailsTab applicant={currentApplicant} />;
            }
        } else {
            history.push('/dashboard');
            return;
        }
    };

    return (
        <div>
            <ApplicantDetailsTabs />
            <StyledApplicantDetailsTabPages>{getTabPage()}</StyledApplicantDetailsTabPages>
        </div>
    );
};

export default ApplicantDetailsTabPages;
