import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import AuthContext from '../components/WithAuth/AuthContext';
import { getRedirectPath } from './users';

interface UserRouteProps extends RouteProps {
    allowedUserType: string;
}

const UserRoute: FC<UserRouteProps> = ({ children, component, render, allowedUserType, ...props }: UserRouteProps) => {
    const { userType } = useContext(AuthContext);
    const isAllowedUser = userType === allowedUserType;

    const redirectPath = getRedirectPath(userType || '');

    const RouteComponent = isAllowedUser ? component : () => <Redirect to={redirectPath} />;

    const isUserRender = (routeProps: RouteComponentProps) => (render ? render(routeProps) : children);
    const isNotUserRender = ({ location }: RouteComponentProps) => (
        <Redirect to={{ pathname: redirectPath, state: { from: location } }} />
    );
    const renderFunction = isAllowedUser ? isUserRender : isNotUserRender;
    return <Route {...props} component={RouteComponent} render={RouteComponent ? undefined : renderFunction} />;
};

export default UserRoute;
