import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { shade600 } from '../../theme/colours';
import { label1, title2 } from '../../theme/cssSnippets';
import { foxtrot, golf, hotel, india } from '../../theme/sizing';
import Button from '../bits/Buttons/Button';
import InfoBox from '../bits/InfoBox';

const Header = styled.h1`
    ${title2};
`;

const Wrapper = styled.div`
    padding-right: ${hotel};
    margin-right: ${foxtrot};
    border-right: 0.07rem solid ${shade600};
    button {
        display: block;
        margin-top: ${golf};
        margin-bottom: ${india};
    }

    label {
        ${label1};
        margin-top: ${foxtrot};
        display: block;
    }

    h1 {
        margin-bottom: ${golf};
    }
`;

const Register = (): ReactElement => {
    const history = useHistory();

    const onRegister = () => {
        // TODO reroute
    };

    const onStartSignup = () => {
        history.push('/councilRegister');
    };

    const infoBoxBody = (
        <>
            <h2>Are you a local council looking to register?</h2>
            <p>
                Sign up to ProxyAddress{' '}
                <Link to="/councilRegister" onClick={onStartSignup}>
                    here
                </Link>
            </p>
        </>
    );

    return (
        <Wrapper>
            <Header>Register for ProxyAddress</Header>
            <p>Looking to sign up for a ProxyAddress?</p>
            <label>Select your local council to start</label>
            <Button buttonStyle="primary" rightIcon="rightArrow" text="Start sign up" onClick={onRegister} />
            <InfoBox body={infoBoxBody} />
        </Wrapper>
    );
};

export default Register;
