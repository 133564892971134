import * as t from 'io-ts';
import * as c from '../constants';
import { LocalFile } from 'papaparse';
import { omit } from 'ramda';

const Region = t.union([t.literal(c.ENGLAND_AND_WALES), t.literal(c.SCOTLAND), t.literal(c.NORTHERN_IRELAND)]);

export type Region = t.TypeOf<typeof Region>;

const CouncilAreaStatus = t.union([t.literal(c.ACTIVE), t.literal(c.INACTIVE), t.literal(c.PENDING)]);

export const CouncilArea = t.type({
    name: t.string,
    region: Region,
    councilStatus: CouncilAreaStatus,
    councilAreaUuid: t.string,
});

export type CouncilArea = t.TypeOf<typeof CouncilArea>;

export const RegionOutput = t.union([
    t.literal(c.ENGLAND_AND_WALES_OUTPUT),
    t.literal(c.SCOTLAND_OUTPUT),
    t.literal(c.NORTHERN_IRELAND_OUTPUT),
]);

export type RegionOutput = t.TypeOf<typeof RegionOutput>;

export const CouncilAreaInput = t.type({ ...omit(['councilAreaUuid'], CouncilArea.props) });

export type CouncilAreaInput = t.TypeOf<typeof CouncilAreaInput>;

export const CouncilAreaOutput = t.type({ ...omit(['region'], CouncilArea.props), region: RegionOutput });

export type CouncilAreaOutput = t.TypeOf<typeof CouncilAreaOutput>;

// Problem with LocalFile type incorrect, have fixed by extending - says there is no name property, but there is
type LocalFileName = { name: string };
export type DroppedLocalFile = LocalFile & LocalFileName;

export type FileError = {
    message: string;
};
