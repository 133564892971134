import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { borderDestructive, borderSelected, red400 } from '../../../theme/colours';
import { label1, body1, cssVisuallyHidden } from '../../../theme/cssSnippets';
import { alpha, foxtrot } from '../../../theme/sizing';
import { StyledCaption } from '../Caption/Caption';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface FormFieldProps {
    children: ReactNode;
    error?: string;
    hideLabel?: boolean;
}

const StyledFormField = styled.div<FormFieldProps>`
    display: flex;
    flex-direction: column;
    + * {
        margin-top: ${foxtrot};
    }
    ,
    label {
        ${label1};
        color: ${(props) => (!props.error ? borderSelected : red400)}}
    }
    input,
    select,
    textarea {
        appearance: none;
        background-color: transparent;
        border:  ${alpha} solid ${(props) => (!props.error ? borderSelected : red400)}};
        ${body1};
        padding-left: 0.3rem;
        &::placeholder {
            opacity: 0.6;
            padding-left: 0.2rem;
        }
        
    }
    select,
    textarea {
        width: 100%;
    }
    ${StyledCaption} {
        margin-top: ${alpha};
        opacity: 0.6;
    }
    ${ErrorMessage} {
        opacity: 1;
        text-align: left;
    }
    ${(props) =>
        props.error &&
        `select,
        textarea {
            border-color: ${borderDestructive};
        }`}
    ${(props) =>
        props.hideLabel &&
        `
        label {
            ${cssVisuallyHidden};
        }
    `}
`;

const FormField = ({ children, error, ...props }: FormFieldProps): ReactElement => (
    <StyledFormField error={error} {...props}>
        {children}
        {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledFormField>
);

export default FormField;
