import { ADMIN_USER_GROUP, APPLICANT_USER_GROUP, STAFF_USER_GROUP } from 'proxyaddress-common/constants/users';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import requireAuthentication from './components/authentication/requireAuthentication';
import Admin from './components/templates/Admin';
import ApplicantPage from './components/templates/ApplicantPage';
import LandingPage from './components/templates/LandingPage';
import Staff from './components/templates/Staff';
import { WithAdminState } from './components/WithAdminState/WithAdminState';
import { WithApplicant } from './components/WithApplicant';
import AuthContext from './components/WithAuth/AuthContext';
import { WithStaffContext } from './components/WithStaffUser/WithStaffContext';
import UserRoute from './utils/UserRoute';
import ResetPassword from './components/authentication/ResetPassword';

export const App = (): React.ReactElement => {
    const { currentAuthenticatedUser, userType } = useContext(AuthContext);
    console.log({ currentAuthenticatedUser, userType });

    return (
        <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            {currentAuthenticatedUser && userType ? (
                <>
                    <UserRoute path="/admin" allowedUserType={ADMIN_USER_GROUP}>
                        <WithAdminState>
                            <Route path="/admin">{requireAuthentication(Admin)}</Route>
                        </WithAdminState>
                    </UserRoute>
                    <UserRoute path="/dashboard" allowedUserType={STAFF_USER_GROUP}>
                        <WithStaffContext>
                            <Route path="/dashboard">{requireAuthentication(Staff)}</Route>
                        </WithStaffContext>
                    </UserRoute>
                    <UserRoute path="/application" allowedUserType={APPLICANT_USER_GROUP}>
                        <WithApplicant>
                            <Route path="/application">{requireAuthentication(ApplicantPage)}</Route>
                        </WithApplicant>
                    </UserRoute>
                </>
            ) : (
                <Route>{requireAuthentication(LandingPage)}</Route>
            )}
        </Switch>
    );
};
