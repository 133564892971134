import { isRight } from 'fp-ts/lib/Either';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { PERSONAL_DETAILS } from 'proxyaddress-common/constants';
import { STAFF_USER_GROUP } from 'proxyaddress-common/constants/users';
import { Applicant, ApplicantTab, ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import { Org } from 'proxyaddress-common/types/organisation';
import { StaffUser } from 'proxyaddress-common/types/staffUser';
import { reduce } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { listApplicantsByOrg } from '../../graphql/applicant';
import { getOrganisation } from '../../graphql/organisation';
import { getStaffUser, listStaffUsersByOrg } from '../../graphql/staffUser';
import { defaultOrganisation } from '../../utils/organisation';
import { formatOrgApplicant, getApplicantUuid } from '../../utils/staffDashboard';
import { getCurrentUserUuids } from '../../utils/users';
import { useDataFormatter } from '../hooks/hooks';
import AuthContext from '../WithAuth/AuthContext';
import { StaffUserContext } from './staffContext';
import { InputOptions } from '../bits/FormFields/SelectInput';
import Loading from '../bits/Loading';

export const WithStaffContext: FC<{ children?: React.ReactNode }> = ({ children }: { children?: React.ReactNode }) => {
    const { currentAuthenticatedUser: user, userType } = useContext(AuthContext);

    if (!user || userType !== STAFF_USER_GROUP) {
        throw new Error('User not permitted');
    }

    const history = useHistory();
    const location = useLocation();
    const [staffUser, setStaffUser] = useState<StaffUser>();
    const [organisation, setOrganisation] = useState<Org>(defaultOrganisation);
    const [newApplication, setNewApplication] = useState(false);
    const [applicants, setApplicants] = useState<Record<string, ApplicantWithStaffDetails>>();
    const [currentApplicant, setCurrentApplicant] = useState<ApplicantWithStaffDetails | undefined>();
    const [currentApplicantTab, setCurrentApplicantTab] = useState<ApplicantTab>(PERSONAL_DETAILS);
    const [staffOptions, setStaffOptions] = useState<InputOptions[]>();

    const { orgUuid, userUuid } = getCurrentUserUuids(user);
    const { data: GetStaffUserData } = getStaffUser.hook({ orgUuid, staffUuid: userUuid });
    const { data: ListStaffUsersByOrgData } = listStaffUsersByOrg.hook({ orgUuid });
    const { data: getOrganisationData } = getOrganisation.hook({ orgUuid });
    const [listApplicantsQuery, { data: ListApplicantsByOrgData }] = listApplicantsByOrg.lazyHook({
        orgUuid,
    });

    useEffect(() => {
        if (applicants && location.pathname.startsWith('/dashboard/applicant')) {
            const applicantUuid = getApplicantUuid(location.pathname);
            if (applicants[applicantUuid]) setCurrentApplicant(applicants[applicantUuid]);
            else {
                setCurrentApplicant(undefined);
                history.push('/dashboard');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicants]);

    const staffUsers = useDataFormatter({
        data: ListStaffUsersByOrgData?.listStaffUsersByOrg,
        Type: StaffUser,
        targetUuid: 'staffUuid',
    }) as Record<string, StaffUser>;

    useEffect(() => {
        if (ListStaffUsersByOrgData?.listStaffUsersByOrg) {
            setStaffOptions(
                ListStaffUsersByOrgData?.listStaffUsersByOrg.map((staffMember) => {
                    return { value: staffMember.staffUuid, label: staffMember.name };
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ListStaffUsersByOrgData?.listStaffUsersByOrg]);

    useEffect(() => {
        if (getOrganisationData?.getOrganisation) {
            const maybeOrganisation = Org.decode(getOrganisationData?.getOrganisation);
            if (!isRight(maybeOrganisation)) {
                console.log(PathReporter.report(maybeOrganisation).join('\n'));
                throw new Error('Organisation was not the right shape');
            }
            setOrganisation(maybeOrganisation.right);
        } else {
            setOrganisation(defaultOrganisation);
        }
    }, [getOrganisationData]);

    useEffect(() => {
        if (GetStaffUserData?.getStaffUser) {
            const maybeStaffUser = StaffUser.decode(GetStaffUserData?.getStaffUser);
            if (!isRight(maybeStaffUser)) {
                throw new Error('StaffUser was not the right shape');
            }
            setStaffUser(maybeStaffUser.right);
        } else {
            setStaffUser(undefined);
        }
    }, [GetStaffUserData]);

    useEffect(() => {
        if (newApplication) {
            history.push('/dashboard/application/new');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newApplication]);

    useEffect(() => {
        if (organisation) {
            listApplicantsQuery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation]);

    useEffect(() => {
        if (ListApplicantsByOrgData?.listApplicantsByOrg && staffUsers) {
            const ApplicantsMap = reduce<Applicant, Record<string, ApplicantWithStaffDetails>>(
                (acc, app) => {
                    const maybeApplicant = Applicant.decode(app);
                    if (isRight(maybeApplicant)) {
                        const applicantUuid = maybeApplicant.right.applicantUuid;
                        return {
                            ...acc,
                            [applicantUuid]: formatOrgApplicant({ applicant: maybeApplicant.right, staffUsers }),
                        };
                    }
                    throw new Error('Applicant was not the right shape');
                },
                {},
                ListApplicantsByOrgData?.listApplicantsByOrg,
            );
            if (currentApplicant) {
                setCurrentApplicant(ApplicantsMap[currentApplicant.applicantUuid]);
            }
            setApplicants(ApplicantsMap);
        } else {
            setApplicants(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ListApplicantsByOrgData, staffUsers]);

    if (!staffUser || !organisation || !applicants || !staffUsers || !staffOptions) {
        return <Loading />;
    }

    return (
        <StaffUserContext.Provider
            value={{
                staffUser,
                setStaffUser,
                staffUsers,
                staffOptions,
                organisation,
                newApplication,
                setNewApplication,
                applicants,
                currentApplicant,
                setCurrentApplicant,
                currentApplicantTab,
                setCurrentApplicantTab,
            }}
        >
            {children}
        </StaffUserContext.Provider>
    );
};
