import React, { FormEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { ACTIVE, COUNCIL } from 'proxyaddress-common/constants';
import { createOrganisation } from '../../graphql/organisation';
import AuthContext from '../WithAuth/AuthContext';
import { getCurrentUserId } from '../../utils/users';
import TextInput from '../bits/FormFields/TextInput';
import {
    OrganisationErrors,
    OrganisationInitialErrors,
    orgTypeOptions,
    statusOptions,
    validateOrganisationInput,
} from '../../utils/organisation';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import SelectInput, { InputOptions } from '../bits/FormFields/SelectInput';
import { isEmpty, values } from 'ramda';
import Form from '../bits/Form/Form';
import Button from '../bits/Buttons/Button';
import { useHistory } from 'react-router-dom';
import SearchSelectInput from '../bits/FormFields/SearchSelectInput';
import { AdminStateContext } from '../WithAdminState/adminState';

const CreateOrganisation = (): ReactElement => {
    const history = useHistory();
    const { currentAuthenticatedUser: user } = useContext(AuthContext);
    const { refetchData, councilAreas } = useContext(AdminStateContext);
    const [errors, setErrors] = useState<OrganisationErrors>(OrganisationInitialErrors);
    const [createOrganisationMutation, { loading, error: CreateOrganisationError }] = createOrganisation.hook();
    const [name, setName] = useState('');
    const [councilArea, setCouncilArea] = useState<string[]>([]);
    const [status, setStatus] = useState(ACTIVE);
    const [orgType, setOrgType] = useState(COUNCIL);
    const [email, setEmail] = useState('');
    const [councilAreaOptions, setCouncilAreaOptions] = useState<InputOptions[]>([]);

    useEffect(() => {
        const councilAreaOptionsMap = values(councilAreas).map((area) => {
            return { value: area.councilAreaUuid, label: area.name };
        });

        setCouncilAreaOptions(councilAreaOptionsMap);
    }, [councilAreas]);

    const formBody = (
        <>
            <BodyCopy>Use this form to add a new organisation</BodyCopy>
            <TextInput
                label="Organisation Name"
                id="name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                error={errors?.nameError}
            />
            <SelectInput
                options={statusOptions}
                label="Status"
                id="status"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStatus(e.target.value)}
                error={errors?.statusError}
            />
            <SelectInput
                options={orgTypeOptions}
                label="Organisation type"
                id="orgType"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setOrgType(e.target.value)}
                error={errors?.orgTypeError}
            />
            <SearchSelectInput
                options={councilAreaOptions}
                label="Council area"
                id="councilArea"
                onChange={(options) => {
                    Array.isArray(options) && setCouncilArea(options.map((option) => option.value));
                }}
                error={errors?.councilError}
                isMulti={true}
                isClearable={true}
            />
            <TextInput
                label="Main contact email address (optional)"
                id="contactEmail"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim())}
            />
            <Button buttonStyle="primary" rightIcon="rightArrow" text="Continue" type="submit" />
        </>
    );

    const validateAndCreateOrganisation = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const variables = {
            organisation: {
                name,
                councilAreaUuids: councilArea,
                createdBy: getCurrentUserId(user),
                status,
                orgType,
                subscriptionEmail: email,
            },
        };
        const validationResult = validateOrganisationInput(variables);

        if (values(validationResult).every(isEmpty)) {
            createOrganisationMutation({
                variables,
            });
            // issue with cache updating but not rerendering https://github.com/apollographql/apollo-client/issues/5963
            refetchData();
            history.push('/admin/organisations');
        } else {
            setErrors(validationResult);
        }
    };

    return (
        <Form
            title="Add an organisation"
            description="Use this form to add a new organisation"
            body={formBody}
            onCancel={() => history.push('/admin/organisations')}
            onSubmit={validateAndCreateOrganisation}
            cancelTitle="Cancel and discard changes"
            loading={loading}
            error={!!CreateOrganisationError}
        />
    );
};

export default CreateOrganisation;
