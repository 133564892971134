import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { blue600, brandA, white, shade200, shade500, blue800, brandC, hoveredTertiary } from '../../../theme/colours';
import { title4, body1, inlineTextSvg } from '../../../theme/cssSnippets';
import { delta, echo } from '../../../theme/sizing';
import { Icon, IconOptions } from '../Icons';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    buttonStyle: 'primary' | 'secondary' | 'tertiary' | 'link' | 'gridLink';
    leftIcon?: IconOptions;
    rightIcon?: IconOptions;
    type?: 'submit' | 'reset' | 'button';
    text?: string;
    id?: string;
}

const UnstyledButton = styled.button.attrs(({ type, onClick }) => ({
    type: type || 'button',
    onClick: (e) => {
        if (!type || type === 'button') {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))`
    align-items: center;
    font-weight: 600;
    margin: 0 0.5rem 0 0;
    padding: ${delta} ${echo};
    ${title4};
`;

export const PrimaryButton = styled(UnstyledButton)`
    background: ${brandA};
    color: ${white};
    ${inlineTextSvg}
    > svg {
        fill: ${white};
    }
    &:hover {
        background: ${blue600};
    }
    &:disabled {
        opacity: 0.3;
    }
`;

const SecondaryButton = styled(UnstyledButton)`
    background: ${shade200};
    color: ${blue800};
    &:hover {
        background: ${shade500};
    }
    &:disabled {
        opacity: 0.3;
    }
`;

export const TertiaryButton = styled(UnstyledButton)`
    background: transparent;
    color: ${brandC};
    text-decoration: underline;
    &:hover {
        background: ${hoveredTertiary};
        color: ${blue600};
    }
    &:disabled {
        color: ${brandA};
        opacity: 0.3;
    }
`;

export const LinkButton = styled(UnstyledButton)`
    ${body1};
    background: transparent;
    color: ${brandA};
    text-decoration: underline;
    border: transparent;
    padding: 0;
    ${inlineTextSvg}
    svg {
        fill: ${brandA};
    }
    &:hover {
        color: ${blue600};
    }
    &:disabled {
        color: ${brandA};
        opacity: 0.3;
    }
    &:hover > * {
        fill: ${blue600};
    }
    &:disabled > * {
        fill: ${brandA};
    }
`;

const GridLinkButton = styled(LinkButton)`
    font-weight: normal;
    font-size: 100%;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    border: 0;
    letter-spacing: normal;
`;

const getButtonType = (buttonStyle: string) => {
    switch (buttonStyle) {
        case 'primary':
            return PrimaryButton;
        case 'secondary':
            return SecondaryButton;
        case 'tertiary':
            return TertiaryButton;
        case 'link':
            return LinkButton;
        case 'gridLink':
            return GridLinkButton;
        default:
            return UnstyledButton;
    }
};

const Button = ({ buttonStyle, leftIcon, rightIcon, text, ...buttonProps }: ButtonProps): ReactElement => {
    const StyledButton = getButtonType(buttonStyle);

    return (
        <StyledButton {...buttonProps}>
            <>
                {leftIcon && <Icon icon={leftIcon} />}
                {text}
                {rightIcon && <Icon icon={rightIcon} />}
            </>
        </StyledButton>
    );
};

export default Button;
