import {
    APPLICANT_DETAILS_UPDATED,
    APPLICATION_CREATED,
    APPLICATION_DECISION,
    APPLICATION_STARTED,
    CHECK_STARTED,
    EMAIL,
    ID_CHECK,
    NOTE,
    REMINDER,
    STAFF_ASSIGNED,
    WATCHLIST,
} from 'proxyaddress-common/constants';
import { ActivityLogEntryType, Applicant, LogType, StaffUser } from 'proxyaddress-common/types';
import { and, equals, filter, isEmpty, isNil, whereEq } from 'ramda';
import { IconOptions } from '../components/bits/Icons';
import { foxtrot, hotel } from '../theme/sizing';

export const checkHasNotStartedApplication = (applicant: Applicant): boolean => {
    // If already has an activity log of logType 'APPLICATION_STARTED' with the same application uuid as the current application,
    // means applicant has already started the application once but have navigated backwards through the application process
    const currentApplicationUuid = applicant.application?.applicationUuid;

    const isApplicationStartType = whereEq({ logType: APPLICATION_STARTED });
    const isStartedCurrentApplication = (logEntry: ActivityLogEntryType) => {
        const applicationLogBelongsTo = logEntry.details?.applicationUuid;
        return and(isApplicationStartType(logEntry), equals(applicationLogBelongsTo, currentApplicationUuid));
    };

    return isEmpty(filter(isStartedCurrentApplication, applicant.activityLog));
};

const getCheckName = (logType: string): string => {
    switch (logType) {
        case ID_CHECK:
            return 'ID check';
        case WATCHLIST:
            return 'Watchlist';
        default:
            return 'Check';
    }
};

export const getLogTitle = (logEntry: ActivityLogEntryType, staffUsers: Record<string, StaffUser>): string => {
    const {
        logType,
        details: { checkType, newStaffAssignedUuid },
    } = logEntry;

    if (checkType) {
        return `${getCheckName(checkType)} ${logType === CHECK_STARTED ? 'started' : 'returned'}`;
    }

    switch (logType) {
        case APPLICATION_CREATED:
            return `Application created by ${staffUsers[logEntry.createdBy].name}`;
        case APPLICATION_STARTED:
            return `Application started`;
        case APPLICATION_DECISION:
            return 'Application closed';
        case APPLICANT_DETAILS_UPDATED:
            return `Details updated by ${staffUsers[logEntry.createdBy].name}`;
        case STAFF_ASSIGNED:
            return `Assigned to ${newStaffAssignedUuid && staffUsers[newStaffAssignedUuid].name}`;
        case NOTE:
            return `Note added by ${staffUsers[logEntry.createdBy].name}`;
        case EMAIL:
            return `Email sent by ${staffUsers[logEntry.createdBy].name}`;
        case REMINDER:
            return `Reminder sent by ${staffUsers[logEntry.createdBy].name}`;
        default:
            return '';
    }
};

export const getLogIcon = (logEntry: ActivityLogEntryType): IconOptions => {
    const {
        logType,
        details: { checkType },
    } = logEntry;

    if (checkType) {
        switch (checkType) {
            case ID_CHECK:
                return 'idCheck';
            case WATCHLIST:
                return 'watchlist';
            default:
                return 'creation';
        }
    }

    switch (logType) {
        case APPLICATION_CREATED:
            return 'creation';
        case APPLICATION_STARTED:
            return 'tick';
        case STAFF_ASSIGNED:
            return 'addCircle';
        default:
            return 'creation';
    }
};

export const noteOptions = [
    { value: NOTE, label: 'Note' },
    { value: EMAIL, label: 'Email sent' },
    { value: REMINDER, label: 'Reminder sent' },
];

export const getLogIconSize = (logType: LogType): string => {
    switch (logType) {
        case STAFF_ASSIGNED:
            return foxtrot;
        case APPLICATION_STARTED:
            return foxtrot;
        default:
            return hotel;
    }
};

interface CreateActivityLogEntryErrors {
    noteError: string;
    noteTypeError: string;
}

export const initialCreateActivityLogEntryErrors = {
    noteError: '',
    noteTypeError: '',
};

export const checkActivityLogNoteToCreate = (
    note: string,
    noteType: string | undefined,
): CreateActivityLogEntryErrors => {
    return {
        noteError: isEmpty(note) ? 'Please enter a note' : '',
        noteTypeError: isNil(noteType) ? 'Please select a note type' : '',
    };
};
