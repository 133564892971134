import React, { useState, ReactElement, useContext } from 'react';
import DashboardButtonSearch from './DashboardButtonSearch';
import { getCouncilAreasCellRenderers, GridWrapper } from '../../utils/grid';
import Grid from '../bits/Grid/Grid';
import { GridApi } from '@ag-grid-community/core';
import { councilAreasColumns } from '../bits/Grid/gridOptions';
import { values } from 'ramda';
import { AdminStateContext } from '../WithAdminState/adminState';
import Button from '../bits/Buttons/Button';
import { useHistory } from 'react-router';

const CouncilAreas = (): ReactElement => {
    const history = useHistory();
    const { councilAreas } = useContext(AdminStateContext);
    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    // TODO remove this button once routing has been fixed
    const body = (
        <Button
            buttonStyle="primary"
            text="Update Council Areas"
            onClick={() => history.push('/admin/councilAreas/update')}
        />
    );

    return (
        <GridWrapper>
            <DashboardButtonSearch title="Council Areas" body={body} />
            {councilAreas && (
                <Grid
                    gridApi={gridApi}
                    rowData={values(councilAreas)}
                    setGridApi={setGridApi}
                    columns={councilAreasColumns}
                    frameworkComponent={{ ...getCouncilAreasCellRenderers() }}
                />
            )}
        </GridWrapper>
    );
};

export default CouncilAreas;
