import { CognitoUser } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import { createContext } from 'react';

export interface CognitoUserAttributes {
    email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    email_verified?: boolean;
    'custom:organisationId': string;
    sub: string;
}

// Types are currently wrong for Cognito user (missing attribute field and challengeName field)
// github.com/aws-amplify/amplify-js/issues/4927
export interface AppUser extends CognitoUser {
    attributes?: CognitoUserAttributes;
    challengeName?: string;
}

export interface CognitoError {
    code: string;
    name: string;
    message: string;
}

export type AuthContextValue = {
    signOut: () => void;
    signIn: (email: string, password: string) => Promise<AppUser>;
    completeNewPassword: (
        user: AppUser,
        newPassword: string,
        setError: (error: { message: string }) => void,
    ) => Promise<void>;
    forgotPassword: (email: string) => Promise<void>;
    changePassword: (email: string, code: string, newPassword: string) => Promise<void>;
    currentCredentials: ICredentials;
    currentAuthenticatedUser?: AppUser;
    userType?: string;
    organisation?: string;
};

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

export default AuthContext;
