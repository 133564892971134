import React, { ReactElement, useContext } from 'react';
import { ApplicantContext } from '../WithApplicant/applicantContext';

const ApplicationSurvey = (): ReactElement => {
    const { applicant } = useContext(ApplicantContext);
    console.log({ applicant });
    return <div>Participant Survey</div>;
};

export default ApplicationSurvey;
