import {
    PENDING,
    STARTED,
    ACTION_REQUIRED,
    APPLICANT_DETAILS_UPDATED,
    STAFF_ASSIGNED,
} from 'proxyaddress-common/constants';
import {
    ActivityLogInput,
    ApplicantWithStaffDetails,
    ApplicationStatus,
    UpdateAddressInput,
} from 'proxyaddress-common/types';
import { equals, isEmpty, map, omit, values } from 'ramda';
import { UpdateApplicantParams } from '../graphql/applicant';
import { defaultActivityLogDetails } from 'proxyaddress-common/types/utils';
import { AddressErrors } from './addresses';
import { validateDate } from './application';
import { ERROR_DOB_TYPE, ERROR_NO_POSTCODE, ERROR_NO_TOWN } from './constants';
import { checkEmail } from './forgotPassword';
import { checkDateInput } from './proxyAddress';
import { getApplicantAddressUpdateFields, getApplicationUpdateFields } from './users';

export interface EditDetailsErrors {
    dateOfBirthError: string;
    correspondenceEmailError: string;
    postalAddressError: AddressErrors;
    currentLocationError: AddressErrors;
}

export const editDetailsInitialErrors = {
    dateOfBirthError: '',
    correspondenceEmailError: '',
    postalAddressError: {
        townError: '',
        postcodeError: '',
    },
    currentLocationError: {
        townError: '',
        postcodeError: '',
    },
};

export interface EditApplicantDetailsForm {
    title?: string;
    firstName?: string;
    middleNames?: string;
    lastName?: string;
    dateOfBirth: { dobDay: string; dobMonth: string; dobYear: string };
    correspondenceEmail?: string;
    phoneNumber?: string;
    postalAddress?: UpdateAddressInput;
    currentLocation?: UpdateAddressInput;
}

interface DateOfBirthDate {
    dobDay: string;
    dobMonth: string;
    dobYear: string;
}

export const getDob = (date: string | undefined): DateOfBirthDate => {
    if (date) {
        const [dobYear, dobMonth, dobDay] = date.split('-');
        return { dobDay, dobMonth, dobYear };
    } else return { dobDay: '', dobMonth: '', dobYear: '' };
};

export const getEditApplicantDetails = (applicant: ApplicantWithStaffDetails): EditApplicantDetailsForm => {
    const {
        title,
        firstName,
        middleNames,
        lastName,
        dateOfBirth,
        correspondenceEmail,
        phoneNumber,
        postalAddress,
        currentLocation,
    } = applicant;
    return {
        title,
        firstName,
        middleNames,
        lastName,
        dateOfBirth: getDob(dateOfBirth),
        correspondenceEmail,
        phoneNumber,
        postalAddress: postalAddress && omit(['__typename'], postalAddress),
        currentLocation: currentLocation && omit(['__typename'], currentLocation),
    };
};

export const formatApplicantDetailsToUpdate = (
    applicant: ApplicantWithStaffDetails,
    details: EditApplicantDetailsForm,
) => {
    const formattedDateOfBirth =
        details.dateOfBirth &&
        validateDate(details.dateOfBirth.dobDay, details.dateOfBirth.dobMonth, details.dateOfBirth.dobYear);

    return {
        ...applicant,
        ...details,
        dateOfBirth: formattedDateOfBirth || applicant.dateOfBirth,
        postalAddress: details.postalAddress,
        currentLocation: details.currentLocation,
    };
};

export const getApplicantDetailsToUpdateFields = (
    applicant: ApplicantWithStaffDetails,
    newStaffAssignedUuid?: string,
): UpdateApplicantParams => {
    const {
        applicantUuid,
        orgUuid,
        application,
        addressHistory,
        postalAddress,
        currentLocation,
        staffMember: { staffUuid },
    } = applicant;

    const updateApplicantType = {
        ...omit(
            [
                'email',
                'createdAt',
                'createdBy',
                'modifiedAt',
                'closedApplications',
                'activityLog',
                'pin',
                'proxyAddress',
                '__typename',
                'name',
                'staffMember',
                'application',
                'addressHistory',
                'postalAddress',
                'currentLocation',
            ],
            applicant,
        ),
        staffMember: newStaffAssignedUuid || staffUuid,
        application: application && getApplicationUpdateFields(application),
        addressHistory: addressHistory?.map((address) => {
            return getApplicantAddressUpdateFields(address);
        }),
        postalAddress: postalAddress && omit(['__typename'], postalAddress),
        currentLocation: currentLocation && omit(['__typename'], currentLocation),
    };

    const activityLogEntry: ActivityLogInput = {
        applicantUuid,
        orgUuid,
        createdBy: staffUuid,
        logType: newStaffAssignedUuid ? STAFF_ASSIGNED : APPLICANT_DETAILS_UPDATED,
        ...(newStaffAssignedUuid
            ? { details: { ...defaultActivityLogDetails, newStaffAssignedUuid } }
            : { details: { ...defaultActivityLogDetails } }),
    };

    return { applicant: updateApplicantType, activityLogEntry };
};

export const validateEditApplicantDetailsInput = (details: EditApplicantDetailsForm): EditDetailsErrors => {
    const { dateOfBirth, correspondenceEmail, postalAddress, currentLocation } = details;

    return {
        dateOfBirthError: !checkDateInput(dateOfBirth.dobDay, dateOfBirth.dobMonth, dateOfBirth.dobYear)
            ? ERROR_DOB_TYPE
            : '',
        correspondenceEmailError: correspondenceEmail ? checkEmail(correspondenceEmail) : '',
        // An address with all empty fields is valid, but not one with at least one field present.
        // If all fields of an address are empty, the display address is an empty string.
        postalAddressError: {
            townError:
                postalAddress && !isEmpty(postalAddress.displayAddress) && isEmpty(postalAddress.town)
                    ? ERROR_NO_TOWN
                    : '',
            postcodeError:
                postalAddress && !isEmpty(postalAddress.displayAddress) && isEmpty(postalAddress.postcode)
                    ? ERROR_NO_POSTCODE
                    : '',
        },
        currentLocationError: {
            townError:
                currentLocation && !isEmpty(currentLocation.displayAddress) && isEmpty(currentLocation.town)
                    ? ERROR_NO_TOWN
                    : '',
            postcodeError:
                currentLocation && !isEmpty(currentLocation.displayAddress) && isEmpty(currentLocation.postcode)
                    ? ERROR_NO_POSTCODE
                    : '',
        },
    };
};

export const checkEditApplicantValidationResult = (validationResult: EditDetailsErrors): boolean => {
    const convertErrorObject = (error: any) => {
        if (typeof error === 'string') return error;
        else return values(error);
    };

    const errors = map(convertErrorObject, values(validationResult)).flat();
    return errors.every(isEmpty);
};

export const isApplicationUndecided = (applicationStatus: ApplicationStatus | undefined): boolean => {
    return (
        equals(applicationStatus, PENDING) ||
        equals(applicationStatus, STARTED) ||
        equals(applicationStatus, ACTION_REQUIRED)
    );
};
