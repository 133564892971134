import React, { ReactElement, useState } from 'react';
import Button from './Button';
import styled from 'styled-components';
import { shade200 } from '../../../theme/colours';
import { alpha, bravo } from '../../../theme/sizing';

interface DropdownButtonProps {
    text: string;
    options: ReactElement;
}

const StyledDropdownButton = styled.div`
    position: relative;
`;

const StyledOptions = styled.div`
    box-shadow: -${alpha} ${alpha} ${bravo} ${shade200};
    width: 100%;
    position: absolute;
    z-index: 1;
`;

const DropdownButton = ({ text, options }: DropdownButtonProps): ReactElement => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <StyledDropdownButton>
            <Button buttonStyle="primary" text={text} rightIcon="down" onClick={() => setDropdownOpen(!dropdownOpen)} />
            {dropdownOpen && <StyledOptions>{options}</StyledOptions>}
        </StyledDropdownButton>
    );
};

export default DropdownButton;
