import { detailsStage } from 'proxyaddress-common/types';
import React, { ReactElement, useContext } from 'react';
import { IdStage } from '../../../utils/application';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import Intro from '../../bits/Intro/Intro';
import List from '../../bits/List/List';
import { Title2, Title3 } from '../../bits/Titles/titles';
import { ApplicantContext } from '../../WithApplicant/applicantContext';
import { IdStagesProps } from '../ApplicationIdVerification';

const IdVerificationIntro = ({ setIdStage }: IdStagesProps): ReactElement => {
    const { updateApplicationStage } = useContext(ApplicantContext);

    const introText = [
        'Photo ID verification helps us combat identity theft by checking your document is valid, and that it is you providing your details and not someone pretending to be you. Your information and images are securely encrypted and uploaded for analysis by Onfido.',
        //TODO Needs link adding
        'Read more about photo ID verification',
    ];

    const body = (
        <>
            <Title2>How to complete this step</Title2>
            <Title3>You will need the following to complete this step</Title3>
            <List>
                <li>A valid passport or national identity card</li>
                <li>A device that allows you to take a photograph of your identity documents and yourself</li>
                <li>A pen and paper to write down a unique code that you will show in your photo</li>
            </List>
            <BodyCopy>
                <strong>
                    If you do not have these right now, you can close this window and save your progress, then log in
                    again to complete your application
                </strong>
            </BodyCopy>
        </>
    );

    return (
        <Intro
            text={introText}
            title="Photo ID verification"
            onCancel={() => updateApplicationStage(detailsStage)}
            cancelTitle="Back to details"
            onSubmit={() => {
                setIdStage(IdStage.ID_PHOTO);
            }}
            submitTitle="Start"
            body={body}
        />
    );
};

export default IdVerificationIntro;
