import React, { ReactElement, useContext } from 'react';
import { detailsStage } from 'proxyaddress-common/types';
import Intro from '../bits/Intro/Intro';
import List from '../bits/List/List';
import { Title3 } from '../bits/Titles/titles';
import { ApplicantContext } from '../WithApplicant/applicantContext';
import { STARTED } from 'proxyaddress-common/constants';

const ApplicationIntro = (): ReactElement => {
    const { applicant, updateApplicationStage } = useContext(ApplicantContext);

    const introText = [
        'This process should take around 10-15 minutes',
        'The form will save as you go along. You can come back to this application at any time using the link in your email or by signing into your online account',
    ];

    const title = `ProxyAddress Application${applicant.firstName && ` - ${applicant.firstName} ${applicant.lastName}`}`;

    const body = (
        <>
            <Title3>To complete the first stage of your application, you will be asked for:</Title3>
            <List>
                <li>First name</li>
                <li>Last known address</li>
                <li>Date of birth</li>
                <li>Email Address</li>
                <li>Phone number</li>
            </List>
        </>
    );

    return (
        <Intro
            text={introText}
            title={title}
            onSubmit={() => {
                updateApplicationStage(detailsStage, STARTED);
            }}
            submitTitle="Start"
            body={body}
        />
    );
};

export default ApplicationIntro;
