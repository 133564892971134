import React, { ReactChild } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { backgroundSecondary, backgroundTertiary, brandA } from '../../../theme/colours';
import { title3 } from '../../../theme/cssSnippets';
import { alpha, bravo, delta, golf } from '../../../theme/sizing';

export interface TabLink {
    label: ReactChild;
    link: string;
}

interface TabsProps {
    className?: string;
    links: TabLink[];
}

const UnstyledTabs = ({ links, ...props }: TabsProps) => {
    return (
        <div {...props}>
            <div>
                {links.map((item: TabLink) => (
                    <div key={item.link} className="list-item">
                        <NavLink to={item.link} activeClassName="is-active" className="link">
                            {item.label}
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>
    );
};

UnstyledTabs.displayName = 'UnstyledTabs';

const Tabs = styled(UnstyledTabs)`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    .component-slider {
        padding-left: ${golf};
        padding-top: ${golf};
        position: relative;
        width: 100%;
        overflow-x: hidden;
        white-space: nowrap;
    }
    .component-slider .slider-container {
        overflow-x: hidden;
    }
    .component-slider .slider-content {
        display: inline-block;
        transition: margin-left 0.15s ease-in;
    }
    /* */
    .list-item {
        display: inline-block;
    }
    .link {
        align-items: center;
        background-color: transparent;
        display: flex;
        ${title3}
        --padding-bottom-var: ${delta};
        padding-bottom: var(--padding-bottom-var);
        padding-left: ${golf};
        padding-right: ${golf};
        padding-top: ${alpha};
        transition-duration: 0.1s;
        transition-property: background-color;
        transition-timing-function: ease-in-out;
        text-decoration: none;
        color: ${brandA};
        &.is-active,
        &:focus,
        &:hover {
            padding-bottom: calc(var(--padding-bottom-var) - ${bravo});
            border-bottom: ${bravo} solid ${brandA};
            text-decoration: none;
            background-color: ${backgroundTertiary};
        }
    }
    .link:not(.is-active) {
        .count {
            background-color: ${backgroundSecondary};
            color: ${brandA};
        }
    }
    .count {
        margin-left: ${golf};
    }
    .alert {
        margin-left: ${alpha};
    }
`;

export default Tabs;
