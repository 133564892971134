import React, { FormEvent, ReactElement, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { golf } from '../../theme/sizing';
import { checkEmailFromUrl, formatUsername } from '../../utils/users';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import IconButton from '../bits/Buttons/IconButton';
import TextInput from '../bits/FormFields/TextInput';
import AuthContext, { CognitoError } from '../WithAuth/AuthContext';
import AmplifyBase from './AmplifyBase';

interface SignInProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange: (authState: string, data?: any) => void;
    isApplicant: boolean;
}

const SignIn = ({ onStateChange, isApplicant }: SignInProps): ReactElement => {
    const { signIn } = useContext(AuthContext);
    const { search } = useLocation();
    const email = checkEmailFromUrl(search);
    const [error, setError] = useState<{ message: string } | undefined>();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const onSignIn = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (username.length === 0 && !email) return setError({ message: 'Username is required' });
        if (password.length === 0) return setError({ message: 'Password is required' });

        const validUsername = formatUsername(email ? email : username);
        if (!validUsername) return setError({ message: 'Invalid email address format' });

        try {
            const user = await signIn(validUsername, password);
            if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                return onStateChange('requireNewPassword', user);
            } else {
                return onStateChange('signedIn', user);
            }
        } catch (errorResponse) {
            const err = errorResponse as CognitoError;
            if (err.code === 'NotAuthorizedException') {
                setError({ message: 'Incorrect email or password' });
            } else {
                setError({ message: err.message });
            }
        }
    };

    const showHideButton = (
        <IconButton
            icon={showPassword ? 'hide' : 'show'}
            size={golf}
            offset
            onClick={() => setShowPassword(!showPassword)}
        />
    );

    const body = (
        <>
            {isApplicant ? (
                <>
                    <BodyCopy>
                        Setting up an account will let you log in to continue your application or track its progress.
                    </BodyCopy>
                    <BodyCopy>
                        You will then be able to access your account by clicking 'Sign in' on www.proxyaddress.com
                    </BodyCopy>
                </>
            ) : (
                <BodyCopy>Log in to your account here</BodyCopy>
            )}
            {isApplicant && email ? (
                <BodyCopy>
                    Email: <strong>{email}</strong>
                </BodyCopy>
            ) : (
                <>
                    <TextInput
                        label="Email"
                        id="email"
                        placeholder="Email"
                        onChange={({ target }) => setUsername(target.value)}
                        value={username}
                    />
                </>
            )}
            <TextInput
                label="Password"
                id="password"
                placeholder="Password"
                onChange={({ target }) => setPassword(target.value)}
                value={password}
                type={showPassword ? 'text' : 'password'}
                button={showHideButton}
                helperText={isApplicant ? 'Please enter the temporary password from your invite email' : ''}
            />
        </>
    );

    return (
        <AmplifyBase
            heading={isApplicant ? 'Account set up' : 'Sign in to ProxyAddress'}
            body={body}
            submitLabel={isApplicant ? 'Continue' : 'Sign In'}
            submitButtonRightIcon={isApplicant ? 'rightArrow' : undefined}
            onSubmit={onSignIn}
            cancelLabel={isApplicant ? 'Resend password' : 'Forgotten your password?'}
            onCancel={() => onStateChange('forgotPassword')}
            error={error}
            splitScreen={!isApplicant}
        />
    );
};

export default SignIn;
