import { GridApi } from '@ag-grid-community/core';
import { values } from 'ramda';
import React, { useContext, useState } from 'react';
import { getAdminAllStaffCellRenderers, GridWrapper } from '../../utils/grid';
import SearchBar from '../bits/FormFields/SearchBar';
import Grid from '../bits/Grid/Grid';
import { allStaffUsersAdminColumns } from '../bits/Grid/gridOptions';
import { AdminStateContext } from '../WithAdminState/adminState';
import DashboardButtonSearch from './DashboardButtonSearch';

const StaffAdmin = () => {
    const { allStaffUsers, organisations, redirectToOrg } = useContext(AdminStateContext);
    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    const body = (
        <SearchBar
            placeholder="Name or email"
            onChange={({ target }) => gridApi?.setQuickFilter(target.value)}
            disabled={!gridApi}
        />
    );

    return (
        <>
            <GridWrapper>
                <DashboardButtonSearch title="Staff" body={body} />
                <Grid
                    columns={allStaffUsersAdminColumns}
                    rowData={values(allStaffUsers)}
                    gridApi={gridApi}
                    setGridApi={setGridApi}
                    frameworkComponent={getAdminAllStaffCellRenderers({
                        staffData: allStaffUsers,
                        orgsData: organisations,
                        redirectToOrg,
                    })}
                />
                ;
            </GridWrapper>
        </>
    );
};

export default StaffAdmin;
