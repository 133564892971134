import gql from 'graphql-tag';
import { CreateProxyAddressArgs, UpdateProxyAddressArgs, ProxyAddress } from 'proxyaddress-common/types';
import wrapMutation from './wrapMutation';
import wrapQuery from './wrapQuery';

export interface CreateProxyAddressParams {
    proxyAddress: CreateProxyAddressArgs;
}

interface CreateProxyAddressData {
    proxyAddress: ProxyAddress;
}

export const Address = `
    town
    postcode
    houseName
    houseNumber
    streetName
    locality
    county
    administrativeArea
    uprn
    displayAddress
    classification
    classificationCode
`;

export const ProxyAddressType = `{
    proxyAddressUuid
   ${Address}
    councilAreaUuid
    source
    isActive
    activationDate
    expiryDate
    orgUuids   
    modifiedAt
    createdAt
    logicalStatusCode
    postalAddressCode
    blpuStateCode
    lastUpdateDate
    localCustodianCode
}`;

const mutation = gql`
    mutation createProxyAddress($proxyAddress: ProxyAddressInput!) {
        createProxyAddress(proxyAddress: $proxyAddress) ${ProxyAddressType}
    }
`;

export const createProxyAddress = wrapMutation<CreateProxyAddressParams, CreateProxyAddressData>(mutation);

export interface ListProxyAddressesData {
    listProxyAddresses: ProxyAddress[];
}

export const listProxyAddressesQuery = gql`
    query listProxyAddresses {
        listProxyAddresses ${ProxyAddressType}
    }`;

export const listProxyAddresses = wrapQuery<void, ListProxyAddressesData>(listProxyAddressesQuery);

export interface UpdateProxyAddressParams {
    proxyAddressUpdates: UpdateProxyAddressArgs;
}

interface UpdateProxyAddressData {
    editProxyAddress: ProxyAddress;
}

export const updateProxyAddressMutation = gql`
    mutation editProxyAddress($proxyAddressUpdates: UpdateProxyAddressInput!) {
        editProxyAddress(proxyAddressUpdates: $proxyAddressUpdates) ${ProxyAddressType}
}`;

export const updateProxyAddress = wrapMutation<UpdateProxyAddressParams, UpdateProxyAddressData>(
    updateProxyAddressMutation,
);
