import gql from 'graphql-tag';
import {
    CreateApplicantArgs,
    Applicant,
    UpdateApplicantInput,
    ActivityLogInput,
} from 'proxyaddress-common/types/applicant';
import wrapMutation from './wrapMutation';
import wrapQuery from './wrapQuery';
import { Address } from './proxyAddress';

export interface GetApplicantParams {
    orgUuid: string;
    applicantUuid: string;
}

export interface GetApplicantData {
    getApplicant: Applicant;
}

const ProgressType = `{
    intro
    details
    idVerification
    survey
    serviceChoice
    complete
}`;

const Check = `{
    checkStatus
    checkResult
    applicantId
    checkId
    reportIds
    startedAt
    completedAt
    staffStartedByUuid
}`;

export const Application = `{
    orgUuid
    applicantUuid
    applicationUuid
    applicationStatus
    progress ${ProgressType}
    identityReport
    watchlistCheck ${Check}
    idCheck ${Check}
    hasParticipantSurvey
    createdAt
    createdBy
    modifiedAt
    staffClosedByUuid
    applicationClosedDate
    closingDecisionReason
}`;

const AddressType = `{
    ${Address}
}`;

const ApplicantAddress = `{
    ${Address}
    applicantAddressUuid
    applicantUuid
    orgUuid
    addressType
    dateMovedIn
    dateMovedOut
    modifiedAt
    createdAt
}`;

const ActivityLogEntryDetails = `{
    applicationUuid
    checkId
    checkType
    newStaffAssignedUuid
    note
}`;

export const ActivityLogEntry = `{
    applicantUuid
    orgUuid
    activityLogUuid
    createdAt
    createdBy
    logType
    details ${ActivityLogEntryDetails}
}`;

export const ApplicantType = `{
    applicantUuid
    orgUuid
    email
    createdAt
    createdBy
    staffMember
    firstName
    lastName
    middleNames
    modifiedAt
    applicationStatus
    application ${Application}
    closedApplications ${Application}
    isActive
    activityLog ${ActivityLogEntry}
    dateOfBirth
    addressHistory ${ApplicantAddress}
    pin
    title
    phoneNumber
    serviceChoices
    correspondenceEmail
    currentLocation ${AddressType}
    postalAddress ${AddressType} 
}`;

export const getApplicantQuery = gql`
    query getApplicant($applicantUuid: String!, $orgUuid: String!) {
        getApplicant(applicantUuid: $applicantUuid, orgUuid: $orgUuid) ${ApplicantType}
    }
`;

export const getApplicant = wrapQuery<GetApplicantParams, GetApplicantData>(getApplicantQuery);

export interface ListApplicantsByOrgParams {
    orgUuid: string;
}

export interface ListApplicantsByOrgData {
    listApplicantsByOrg: Applicant[];
}

export const listApplicantsByOrgQuery = gql`
    query listApplicantsByOrg($orgUuid: String!) {
        listApplicantsByOrg(orgUuid: $orgUuid) ${ApplicantType}
    }
`;

export const listApplicantsByOrg = wrapQuery<ListApplicantsByOrgParams, ListApplicantsByOrgData>(
    listApplicantsByOrgQuery,
);

export interface CreateApplicantParams {
    applicantUser: CreateApplicantArgs;
}
interface CreateApplicantData {
    applicantUser: Applicant;
}

const mutation = gql`
    mutation createApplicant($applicantUser: NewApplicantUserInput!) {
        createApplicant(applicantUser: $applicantUser) ${ApplicantType}
    }
`;

export const createApplicant = wrapMutation<CreateApplicantParams, CreateApplicantData>(mutation);

export interface UpdateApplicantParams {
    applicant: UpdateApplicantInput;
    activityLogEntry?: ActivityLogInput;
}
export interface UpdateApplicantData {
    editApplicant: Applicant;
}

const editApplicantMutation = gql`
    mutation editApplicant($applicant: UpdateApplicantInput!, $activityLogEntry: ActivityLogInput) {
        editApplicant(applicant: $applicant, activityLogEntry: $activityLogEntry) ${ApplicantType}
    }
`;

export const updateApplicant = wrapMutation<UpdateApplicantParams, UpdateApplicantData>(editApplicantMutation);

export interface DeleteAddressParams {
    applicantUuid: string;
    orgUuid: string;
    applicantAddressUuid: string;
}

const deleteApplicantAddressMutation = gql`
    mutation deleteApplicantAddress($applicantUuid: String!, $orgUuid: String!, $applicantAddressUuid: String!) {
        deleteApplicantAddress(
            applicantUuid: $applicantUuid
            orgUuid: $orgUuid
            applicantAddressUuid: $applicantAddressUuid
        )
    }
`;

export const deleteApplicantAddress = wrapMutation<DeleteAddressParams, string>(deleteApplicantAddressMutation);
