import { isEmpty, values } from 'ramda';
import React, { FormEvent, ReactElement, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
    ResetPasswordErrors,
    ResetPasswordInitialErrors,
    validateResetPasswordInput,
} from '../../utils/forgotPassword';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import TextInput from '../bits/FormFields/TextInput';
import Confirmation from '../patterns/Confirmation';
import NegativeHeader from '../patterns/NegativeHeader';
import AuthContext, { CognitoError } from '../WithAuth/AuthContext';
import AmplifyBase from './AmplifyBase';
import IconButton from '../bits/Buttons/IconButton';
import { golf } from '../../theme/sizing';

const Wrapper = styled.div`
    > div {
        display: flex;
        width: 100%;
        margin: auto;
    }
    form {
        margin: auto;
    }
`;

const ResetPassword = (): ReactElement => {
    const history = useHistory();
    const { changePassword } = useContext(AuthContext);
    const [code, setCode] = useState('');
    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypeNewPassword, setRetypeNewPassword] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [error, setError] = useState<{ message: string } | undefined>();
    const [errors, setErrors] = useState<ResetPasswordErrors>(ResetPasswordInitialErrors);
    const [showPassword, setShowPassword] = useState(false);
    const [showRetypeNewPassword, setShowRetypeNewPassword] = useState(false);

    const validateAndResetPassword = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        const variables = { input: { username, code, newPassword, retypeNewPassword } };
        const validationResult = validateResetPasswordInput(variables);

        if (values(validationResult).every(isEmpty)) {
            try {
                await changePassword(username, code, newPassword);
                setShowConfirmation(true);
            } catch (errorResponse) {
                const err = errorResponse as CognitoError;
                setError({ message: err.message });
            }
        } else {
            setErrors(validationResult);
        }
    };

    const showHidePasswordButton = (
        <IconButton
            icon={showPassword ? 'hide' : 'show'}
            size={golf}
            offset
            onClick={() => setShowPassword(!showPassword)}
        />
    );

    const showHideRetypePasswordButton = (
        <IconButton
            icon={showRetypeNewPassword ? 'hide' : 'show'}
            size={golf}
            offset
            onClick={() => setShowRetypeNewPassword(!showRetypeNewPassword)}
        />
    );

    const body = (
        <>
            <BodyCopy>Enter your email address, the code you received via email, and your new password.</BodyCopy>
            <BodyCopy>
                If you entered the correct email address but have not received an email with a code, check your spam.
            </BodyCopy>
            <BodyCopy>Passwords must be a minimum of 8 characters and contain at least one number.</BodyCopy>
            <BodyCopy>
                <b>* All fields are required.</b>
            </BodyCopy>
            <TextInput
                id="username"
                label="Email *"
                error={errors?.usernameError}
                onChange={({ target }) => setUsername(target.value)}
            />
            <TextInput
                id="code"
                label="Code *"
                error={errors?.codeError}
                onChange={({ target }) => setCode(target.value)}
            />
            <TextInput
                id="newPassword"
                label="New password *"
                type={showPassword ? 'text' : 'password'}
                button={showHidePasswordButton}
                helperText="Use 8 or more characters with a mix of upper and lowercase letters"
                error={errors?.newPasswordError}
                onChange={({ target }) => setNewPassword(target.value)}
            />
            <TextInput
                id="retypeNewPassword"
                label="Retype new password *"
                type={showRetypeNewPassword ? 'text' : 'password'}
                button={showHideRetypePasswordButton}
                error={errors?.retypeNewPasswordError}
                onChange={({ target }) => setRetypeNewPassword(target.value)}
            />
        </>
    );

    const confirmationText = ['Your password has been changed.', 'You may now log in with your new password.'];

    const navButton = 'Back to login';

    return (
        <Wrapper>
            <NegativeHeader />
            {!showConfirmation ? (
                <AmplifyBase
                    heading="Reset your password"
                    body={body}
                    submitLabel="Reset password"
                    onSubmit={validateAndResetPassword}
                    navButton={navButton}
                    error={error}
                />
            ) : (
                <Confirmation
                    title="Password changed"
                    text={confirmationText}
                    buttonTitle="Back to login"
                    buttonAction={() => {
                        history.push('/');
                    }}
                />
            )}
        </Wrapper>
    );
};

export default ResetPassword;
