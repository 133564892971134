import styled from 'styled-components';
import { bravo, hotel } from '../../../theme/sizing';
import { brandB } from '../../../theme/colours';

interface InputWrapperProps {
    type?: string;
    width?: string;
}

export const InputWrapper = styled.div<InputWrapperProps>`
    align-items: center;
    display: flex;
    transition: border-color 0.1s ease-in-out;
    &:focus-within {
        border: ${bravo} solid ${brandB};
    }
    width: ${(props) => props.width || '20.5rem'};
    margin-top: ${bravo};
    input {
        padding-right: ${(props) => (props.type === 'password' ? hotel : bravo)};
        padding-left: ${bravo};
    }
`;
