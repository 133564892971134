import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { golf } from '../../theme/sizing';
import Button from '../bits/Buttons/Button';
import { Icon } from '../bits/Icons';
import { Title2 } from '../bits/Titles/titles';
import NegativeHeader from '../patterns/NegativeHeader';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
        margin-top: ${golf};
    }
    h2 {
        margin-bottom: ${golf};
    }
`;

const LandingPage = (): ReactElement => {
    const history = useHistory();
    return (
        <div>
            <NegativeHeader />
            <Wrapper>
                <Title2>Welcome to ProxyAddress</Title2>
                <Icon icon="logoPrimary" size="30rem" />
                <Button
                    buttonStyle="primary"
                    text="Sign in"
                    rightIcon="rightArrow"
                    onClick={() => history.push('/signIn')}
                />
            </Wrapper>
        </div>
    );
};

export default LandingPage;
