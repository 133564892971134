import { isEmpty, values } from 'ramda';
import React, { FormEvent, ReactElement, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createStaffUser } from '../../graphql/staffUser';
import { getCurrentUserId, StaffUserErrors, StaffUserInitialErrors, validateStaffUserInput } from '../../utils/users';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import Button from '../bits/Buttons/Button';
import Form from '../bits/Form/Form';
import TextInput from '../bits/FormFields/TextInput';
import { AdminStateContext } from '../WithAdminState/adminState';
import AuthContext from '../WithAuth/AuthContext';

const CreateStaffUser = (): ReactElement => {
    const history = useHistory();
    const { orgUuid }: { orgUuid: string } = useParams();
    const { currentAuthenticatedUser: user } = useContext(AuthContext);
    const { organisations, setCurrentOrganisation } = useContext(AdminStateContext);
    const [errors, setErrors] = useState<StaffUserErrors>(StaffUserInitialErrors);
    const [createStaffUserMutation, { loading, error: CreateStaffUserError }] = createStaffUser.hook();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const formBody = (
        <>
            <BodyCopy>Use this form to add a new staff member to {organisations?.[orgUuid]?.name}</BodyCopy>
            <TextInput
                label="Name"
                id="name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                error={errors?.nameError}
            />
            <TextInput
                label="Email address"
                id="staffEmail"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim())}
            />
            <Button buttonStyle="primary" text="Continue" rightIcon="rightArrow" type="submit" />
        </>
    );

    const validateAndCreateStaffUser = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const variables = {
            staffUser: {
                name,
                createdBy: getCurrentUserId(user),
                orgUuid,
                isAdmin: false,
                isActive: true,
                email,
            },
        };
        const validationResult = validateStaffUserInput(variables);

        if (values(validationResult).every(isEmpty)) {
            createStaffUserMutation({
                variables,
            });
            setCurrentOrganisation(organisations[orgUuid]);
            history.push(`/admin/organisations/${orgUuid}`);
        } else {
            setErrors(validationResult);
        }
    };

    return (
        <Form
            title="Add a Staff User"
            description="Use this form to add a new staff user"
            body={formBody}
            onCancel={() => history.push(`/admin/organisations/${orgUuid}`)}
            onSubmit={validateAndCreateStaffUser}
            cancelTitle="Cancel and discard changes"
            loading={loading}
            error={!!CreateStaffUserError}
        />
    );
};

export default CreateStaffUser;
