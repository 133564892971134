import React, { ReactElement } from 'react';
import ApplicantDetails from '../patterns/ApplicantDetails/ApplicantDetails';
import CreateApplicant from '../patterns/CreateApplicant';
import PrimaryHeader from '../patterns/PrimaryHeader';
import Dashboard from './Dashboard';
import { Route, Switch } from 'react-router-dom';
import Tabs, { TabLink } from '../bits/Tabs/Tabs';

const Staff = (): ReactElement => {
    const staffLinks: TabLink[] = [
        {
            link: '/dashboard',
            label: 'People',
        },
    ];

    const menu = <Tabs links={staffLinks} />;

    return (
        <>
            <PrimaryHeader menu={menu} />
            <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route path="/dashboard/application/new" component={CreateApplicant} />
                <Route path="/dashboard/applicant/:applicantUuid" component={ApplicantDetails} />
            </Switch>
        </>
    );
};

export default Staff;
