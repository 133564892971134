import { Label } from 'aws-amplify-react';
import { isEmpty } from 'ramda';
import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { deleteApplicantAddress } from '../../graphql/applicant';
import { shade200 } from '../../theme/colours';
import { sortAddressByDate } from '../../utils/addresses';
import { getDayMonthYear } from '../../utils/application';
import Loading from '../bits/Loading';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import Button from '../bits/Buttons/Button';
import DateInputField, { DateInputsWrapper } from '../bits/FormFields/Dates';
import FormField from '../bits/FormFields/FormField';
import { Title2, Title3 } from '../bits/Titles/titles';
import { ApplicantContext } from '../WithApplicant/applicantContext';

const StyledAddressList = styled.div`
    text-align: left;
    width: 50%;
    margin: auto;
    @media (max-width: 650px) {
        width: 100%;
    }
`;

const StyledAddressListItem = styled.div`
    padding-bottom: 1rem;
    border-bottom: 0.01rem solid ${shade200};
`;

const AddressList = (): ReactElement => {
    const { applicant } = useContext(ApplicantContext);
    const [deleteApplicantAddressMutation, { loading, error }] = deleteApplicantAddress.hook();

    const deleteSelectedAddress = async (applicantAddressUuid?: string) => {
        if (!applicantAddressUuid) return;
        const { applicantUuid, orgUuid } = applicant;
        await deleteApplicantAddressMutation({
            variables: {
                applicantUuid,
                orgUuid,
                applicantAddressUuid,
            },
            update(cache) {
                const normalizedId = cache.identify({ applicantAddressUuid, __typename: 'ApplicantAddress' });
                cache.evict({ id: normalizedId });
                cache.gc();
            },
        });
    };

    return (
        <StyledAddressList>
            {!isEmpty(applicant.addressHistory) && <Title2>Address history</Title2>}
            {loading && <Loading />}
            {error && <p>There was an error getting your address history please refresh the page...</p>}
            {!loading &&
                !error &&
                applicant.addressHistory &&
                sortAddressByDate(applicant.addressHistory).map((address, i) => {
                    const { applicantAddressUuid, displayAddress, dateMovedIn, dateMovedOut } = address;
                    const { month: movedInMonth, year: movedInYear } = getDayMonthYear(dateMovedIn);
                    const { month: movedOutMonth, year: movedOutYear } = getDayMonthYear(dateMovedOut);

                    return (
                        <StyledAddressListItem key={applicantAddressUuid}>
                            <Title3>{`Previous Address ${i + 1}`}</Title3>
                            <BodyCopy>{displayAddress}</BodyCopy>
                            <FormField>
                                <Label>Date moved in</Label>
                                <DateInputsWrapper>
                                    <DateInputField
                                        id="movedInMonth"
                                        type="text"
                                        value={movedInMonth}
                                        preText="Month"
                                        size="small"
                                        disabled={true}
                                    />
                                    <DateInputField
                                        id="movedInYear"
                                        type="text"
                                        value={movedInYear}
                                        preText="Year"
                                        disabled={true}
                                    />
                                </DateInputsWrapper>
                            </FormField>
                            <FormField>
                                <Label>Date moved out</Label>
                                <DateInputsWrapper>
                                    <DateInputField
                                        id="movedOutMonth"
                                        type="text"
                                        value={movedOutMonth}
                                        preText="Month"
                                        size="small"
                                        disabled={true}
                                    />
                                    <DateInputField
                                        id="movedOutYear"
                                        type="text"
                                        value={movedOutYear}
                                        preText="Year"
                                        disabled={true}
                                    />
                                </DateInputsWrapper>
                            </FormField>
                            <Button
                                buttonStyle="primary"
                                onClick={() => deleteSelectedAddress(applicantAddressUuid)}
                                text="Delete address"
                            />
                        </StyledAddressListItem>
                    );
                })}
        </StyledAddressList>
    );
};

export default AddressList;
