import gql from 'graphql-tag';
import wrapQuery from './wrapQuery';
import wrapMutation from './wrapMutation';
import { CouncilArea, CouncilAreaInput } from 'proxyaddress-common/types/councilAreas';

interface ListCouncilAreasData {
    listCouncilAreas: CouncilArea[];
}

export const CouncilAreaType = `{
    name
    region
    councilStatus
    councilAreaUuid
}`;

export const listCouncilAreasQuery = gql`
    query listCouncilAreas {
       listCouncilAreas ${CouncilAreaType}
    }
`;

export const listCouncilAreas = wrapQuery<void, ListCouncilAreasData>(listCouncilAreasQuery);

export interface CreateCouncilAreasParams {
    councilAreaInput: CouncilAreaInput[];
}

export const createCouncilAreasMutation = gql`
    mutation createCouncilAreas($councilAreaInput: [CouncilAreaInput!]!) {
        createCouncilAreas(councilAreas: $councilAreaInput)
    }
`;

export const createCouncilAreas = wrapMutation<CreateCouncilAreasParams, void>(createCouncilAreasMutation);
