import { isValid } from 'date-fns';
import format from 'date-fns/format';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import parse from 'date-fns/parse';
import * as c from 'proxyaddress-common/constants';
import { ApplicantAddress, ApplicationStages, Progress } from 'proxyaddress-common/types';
import { always, cond, findIndex, includes, isEmpty, propEq, propOr, sortWith, T, values } from 'ramda';
import { UpdateApplicantParams } from '../graphql/applicant';
import { OsAddressAllData } from './addresses';
import {
    ERROR_DATE,
    ERROR_NO_ADDRESS_TYPE,
    ERROR_NO_DOB,
    ERROR_NO_FIRST_NAME,
    ERROR_NO_LAST_NAME,
    ERROR_NO_POSTCODE,
    ERROR_NO_TOWN,
    ERROR_US_NO_STATE,
} from './constants';
import { usStates } from './usStates';

export const getCurrentStage = cond<Progress, ApplicationStages>([
    [propEq(c.INTRO, false), always(c.INTRO)],
    [propEq(c.DETAILS, false), always(c.DETAILS)],
    [propEq(c.ID_VERIFICATION, false), always(c.ID_VERIFICATION)],
    [propEq(c.SURVEY, false), always(c.SURVEY)],
    [propEq(c.COMPLETE, false), always(c.COMPLETE)],
    [T, always(c.INTRO)],
]);

export const titles = [
    { label: '', value: '' },
    { label: 'Mr', value: 'Mr' },
    { label: 'Ms', value: 'Ms' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Dr', value: 'Dr' },
];

export const getDayMonthYear = (dateString: string | undefined): { day: string; month: string; year: string } => {
    if (!dateString) {
        return {
            month: '',
            day: '',
            year: '',
        };
    }

    const date = dateString.length === 7 ? new Date(`${dateString}-01`) : new Date(dateString);
    return {
        month: date ? `${getMonth(new Date(date)) + 1}` : '',
        day: date ? `${getDate(new Date(date))}` : '',
        year: date ? `${getYear(new Date(date))}` : '',
    };
};

export const validateDate = (day: string, month: string, year: string): string => {
    if (day && month && year) {
        const dateString = `${year}-${month}-${day}`;
        const parsed = parse(dateString, 'yyyy-MM-dd', new Date());
        if (year.length < 4 || !isValid(parsed)) {
            return '';
        }
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd');
    }
    return '';
};

export const validateMonthYear = (month: string, year: string): string => {
    if (month && year) {
        const dateString = `${year}-${month}-01`;
        const parsed = parse(dateString, 'yyyy-MM-dd', new Date());
        if (year.length < 4 || !isValid(parsed)) {
            return '';
        }
        const date = new Date(dateString);
        return format(date, 'yyyy-MM');
    }
    return '';
};

export const formatGridDate = (date: string): string => {
    if (date) {
        const newDate = new Date(date);
        return format(newDate, 'dd/MM/yyyy');
    }
    return '';
};

export interface DetailsErrors {
    firstNameError: string;
    lastNameError: string;
    dateOfBirthError: string;
    dateTypeError?: string;
}

export const DetailsInitialErrors = {
    firstNameError: '',
    lastNameError: '',
    dateOfBirthError: '',
    dateTypeError: '',
};

export const validateDetailsInput = ({ applicant }: UpdateApplicantParams): DetailsErrors => {
    const { firstName, lastName, dateOfBirth } = applicant;
    return {
        firstNameError: isEmpty(firstName) ? ERROR_NO_FIRST_NAME : '',
        lastNameError: isEmpty(lastName) ? ERROR_NO_LAST_NAME : '',
        dateOfBirthError: isEmpty(dateOfBirth) ? ERROR_NO_DOB : '',
    };
};
export interface ApplicantAddressErrors {
    postcodeError: string;
    dateMovedInError: string;
    dateMovedOutError: string;
    townError: string;
    addressTypeError: string;
    stateError: string;
}

export const applicantAddressInitialErrors = {
    postcodeError: '',
    dateMovedInError: '',
    dateMovedOutError: '',
    townError: '',
    noResultsError: '',
    addressTypeError: '',
    stateError: '',
};

export const isStateError = (country?: string, state?: string): boolean => {
    if (country !== 'USA' || includes(state, usStates)) {
        return false;
    }
    return true;
};

export const validateApplicantAddressInput = (address: ApplicantAddress): ApplicantAddressErrors => {
    const { dateMovedIn, dateMovedOut, town, postcode, addressType, county, country } = address;
    return {
        postcodeError: isEmpty(postcode) ? ERROR_NO_POSTCODE : '',
        dateMovedInError: isEmpty(dateMovedIn) ? ERROR_DATE : '',
        dateMovedOutError: isEmpty(dateMovedOut) ? ERROR_DATE : '',
        townError: isEmpty(town) ? ERROR_NO_TOWN : '',
        addressTypeError: isEmpty(addressType) ? ERROR_NO_ADDRESS_TYPE : '',
        stateError: isStateError(country, county) ? ERROR_US_NO_STATE : '',
    };
};

export const addressTypes = [
    { label: 'Supported housing', value: 'supported_housing' },
    { label: 'Home owner', value: 'home_owner' },
    { label: 'Rented', value: 'rented' },
    { label: 'Hostel/Temporary Shelter', value: 'temporary_shelter' },
    { label: 'Lodger', value: 'lodger' },
    { label: 'Living with friends/family', value: 'friends_family' },
];

export const getAddressOptions = (
    osAddresses: Record<string, OsAddressAllData>,
): { value: string; label: string }[] => {
    const sorted = sortWith([propOr('ADDRESS', '0'), propOr('BUILDING_NUMBER', '0')], values(osAddresses));
    return sorted.map((address) => ({
        label: address.ADDRESS,
        value: address.UPRN,
    }));
};

// Id verification stages

export enum IdStage {
    ID_INTRO = 'ID_INTRO',
    ID_TYPE = 'ID_TYPE',
    ID_PHOTO = 'ID_PHOTO',
    ID_CODE = 'ID_CODE',
    ID_RESULT = 'ID_RESULT',
}

export enum IdType {
    passport = 'passport',
    drivingLicence = 'drivingLicence',
    idCard = 'idCard',
    residencePermit = 'residencePermit',
}

export const idTypes: { label: string; value: IdType; subLabel: string }[] = [
    { label: 'Passport', value: IdType.passport, subLabel: 'Personal details page' },
    { label: 'Driving Licence', value: IdType.drivingLicence, subLabel: 'Front and back' },
    { label: 'Asylum Registration Card', value: IdType.idCard, subLabel: 'Front and back' },
    { label: 'Residence Permit', value: IdType.residencePermit, subLabel: 'details' },
];

export const getStageStatus = (currentStage: string, stageIndex: number): 'complete' | 'notStarted' | 'started' => {
    const currentStageIndex = findIndex(propEq('stage', currentStage), c.applicationStageDetails);
    if (currentStageIndex === stageIndex) {
        return 'started';
    }
    if (stageIndex < currentStageIndex) {
        return 'complete';
    }
    return 'notStarted';
};

export const getApplicationStatusText = (applicationStatus: string) => {
    switch (applicationStatus) {
        case c.PENDING:
            return 'NOT STARTED';
        case c.STARTED:
            return 'IN PROGRESS';
        case c.ACTION_REQUIRED:
            return 'ACTION REQUIRED';
        case c.APPROVED:
            return c.APPROVED;
        case c.REJECTED:
            return c.REJECTED;
        case c.CLOSED_WITHOUT_DECISION:
            return 'CLOSED - NO DECISION';
        default:
            return '';
    }
};
