import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { shade100 } from '../../theme/colours';
import Button from '../bits/Buttons/Button';
import FullLogo from './FullLogo';
import AuthContext from '../WithAuth/AuthContext';

export interface HeaderProps {
    menu?: ReactElement;
    userName?: string;
}

const Header = styled.h1`
    background-color: ${shade100};
    width: 100%;
    display: flex;
    margin-bottom: 1.25rem;
`;

const Menu = styled.div`
    display: flex;
    margin-top: 0.85rem;
`;

// TODO temporary styling until have created the user dropdown
const UserInfo = styled.div`
    flex-grow: 1;
    > button {
        float: right;
        margin-right: 30%;
        margin-top: 0.9rem;
    }
`;

const PrimaryHeader = ({ menu, userName }: HeaderProps): ReactElement => {
    const { signOut } = useContext(AuthContext);
    return (
        <Header>
            <FullLogo theme="primary" />
            <Menu>
                {menu}
                {userName}
            </Menu>
            <UserInfo>
                <Button buttonStyle="link" text="Sign Out" onClick={() => signOut()} />
            </UserInfo>
        </Header>
    );
};

export default PrimaryHeader;
