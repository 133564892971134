import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { submitOnfidoCheck } from '../../../graphql/apis';
import Loading from '../../bits/Loading';
import ErrorPage from '../../bits/ErrorMessage/ErrorPage';
import { ApplicantContext } from '../../WithApplicant/applicantContext';

interface OnfidoViewProps {
    token: string;
    setCheckComplete: (complete: boolean) => void;
}

const OnfidoView = ({ token, setCheckComplete }: OnfidoViewProps): ReactElement => {
    const {
        applicant: { application, orgUuid, applicantUuid, staffMember },
    } = useContext(ApplicantContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ loadingError: '', submitError: '' });
    const [onfidoInstance, setOnfidoInstance] = useState<any | null>(null);
    const [doSubmitOnfidoCheck, { data, loading: submitLoading, error: submitError }] = submitOnfidoCheck.lazyHook({
        applicationUuid: application?.applicationUuid || '',
        orgUuid,
        applicantUuid,
        staffStartedByUuid: staffMember,
    });

    useEffect(() => {
        initOnfido();
        return () => {
            onfidoInstance && onfidoInstance.tearDown();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (submitError) {
            setError({ ...error, submitError: submitError.message });
            return;
        }
        if (data) {
            setCheckComplete(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, submitLoading, submitError]);

    const initOnfido = async () => {
        // Onfido issue on load in React
        // https://github.com/onfido/onfido-sdk-ui/issues/615
        const { init } = await import('onfido-sdk-ui');
        try {
            setLoading(true);

            const instance = init({
                useModal: false,
                token: token,
                onComplete: (completeData: any) => {
                    doSubmitOnfidoCheck();
                },
                steps: [
                    'document',
                    'face',
                    {
                        type: 'complete',
                        options: {
                            message: 'Documents successfully uploaded',
                            submessage: `You will be redirected to complete the application.`,
                        },
                    },
                ],
            });

            setOnfidoInstance(instance);
            setLoading(false);
        } catch (err: any) {
            setError({ ...error, loadingError: err.message });
        }
    };

    return (
        <>
            <div id="onfido-mount">
                <>
                    {loading && <Loading />}
                    {(error.loadingError || error.submitError) && (
                        <ErrorPage message={error.loadingError || error.submitError} />
                    )}
                </>
            </div>
        </>
    );
};

export default OnfidoView;
