import { GetQuickFilterTextParams, ValueGetterParams } from '@ag-grid-community/all-modules';

export const defaultOptions = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    autoHeight: true,
    resizable: true,
    minWidth: 120,
    getQuickFilterText: () => '',
};

const quickFilter = {
    getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.value;
    },
};

const statusValueGetter = ({ data }: ValueGetterParams) => {
    return data.applicationStatus;
};

const staffValueGetter = ({ data }: ValueGetterParams) => {
    return data.staffMember.name;
};

export const organisationColumns = [
    {
        headerName: 'Organisation',
        field: 'name',
        cellRenderer: 'orgLinkRenderer',
        sort: 'asc',
    },
    {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'statusRenderer',
    },
    {
        headerName: 'Main contact',
        field: 'subscriptionEmail',
        cellRenderer: 'mainContactRenderer',
    },
];

export const staffDashboardColumns = [
    {
        headerName: 'Name',
        field: 'name',
        cellRenderer: 'applicantLinkRenderer',
        hide: true,
        ...quickFilter,
    },
    {
        headerName: 'Name',
        field: 'name',
        colId: 'obscureNames',
        hide: false,
        cellRenderer: 'obscureNamesRenderer',
        ...quickFilter,
    },
    {
        headerName: 'PIN',
        field: 'pin',
        cellRenderer: 'pinRenderer',
        ...quickFilter,
    },
    {
        headerName: 'Last updated',
        field: 'modifiedAt',
        cellRenderer: 'lastUpdatedRenderer',
    },
    {
        headerName: 'Identity report',
        field: 'identityReport',
    },
    {
        headerName: 'Photo ID verif.',
        field: 'idCheck',
        cellRenderer: 'idCheckRenderer',
    },
    {
        headerName: 'Watchlist',
        field: 'watchlist',
    },
    {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'statusRenderer',
        valueGetter: statusValueGetter,
    },
    {
        headerName: 'Staff',
        field: 'staffMember',
        cellRenderer: 'staffRenderer',
        valueGetter: staffValueGetter,
    },
];

export const allStaffUsersAdminColumns = [
    {
        headerName: 'Name',
        field: 'name',
        cellRenderer: 'staffLinkRenderer',
        sort: 'asc',
        ...quickFilter,
    },
    {
        headerName: 'Organisation',
        field: 'organisation',
        cellRenderer: 'orgLinkRenderer',
    },
    {
        headerName: 'Status',
        field: 'isActive',
        cellRenderer: 'isActiveRenderer',
    },
    {
        headerName: 'Email',
        field: 'email',
        ...quickFilter,
    },
    {
        headerName: 'Admin',
        field: 'isAdmin',
        cellRenderer: 'isAdminRenderer',
    },
    {
        headerName: 'Created at',
        field: 'createdAt',
        cellRenderer: 'createdAtRenderer',
    },
    { headerName: 'Last modified', field: 'modifiedAt', cellRenderer: 'modifiedAtRenderer' },
];

export const viewOrgDetailsColumns = [
    {
        headerName: 'Name',
        field: 'name',
        sort: 'asc',
    },
    { headerName: 'Status', field: 'status', cellRenderer: 'statusRenderer' },
    { headerName: 'Email', field: 'email' },
];

export const councilAreasColumns = [
    {
        headerName: 'Name',
        field: 'name',
    },
    {
        headerName: 'Region',
        field: 'region',
    },
    {
        headerName: 'Status',
        field: 'councilStatus',
        cellRenderer: 'councilStatusRenderer',
    },
];

export const checksColumns = [
    {
        headerName: 'Check',
        field: 'check',
    },
    { headerName: 'Date begun', field: 'startedAt', cellRenderer: 'dateBegunRenderer' },
    {
        headerName: 'Date issued',
        field: 'completedAt',
        cellRenderer: 'dateIssuedRenderer',
    },
    {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'statusRenderer',
    },
];

export const proxyAddressesColumns = [
    /* TODO add score and number of people assigned to once PXA-79 assign proxy address is merged */
    {
        headerName: 'Address',
        cellRenderer: 'displayAddressRenderer',
    },
    {
        headerName: 'Status',
        field: 'isActive',
        cellRenderer: 'statusRenderer',
    },
    {
        headerName: 'Council area',
        cellRenderer: 'councilAreaRenderer',
    },
    {
        headerName: 'Source',
        field: 'source',
        cellRenderer: 'sourceRenderer',
    },
    {
        headerName: 'Expires',
        field: 'expiryDate',
        cellRenderer: 'expiryDateRenderer',
    },
    {
        headerName: 'Activated',
        field: 'activationDate',
        cellRenderer: 'activationDateRenderer',
    },
    { headerName: 'Building status', field: 'blpuStateCode', cellRenderer: 'buildingStatusRenderer' },
];
