import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Address, ApplicantAddress } from 'proxyaddress-common/types';
import { StyledDetailWrapper } from './Detail';
import Caption from '../Caption/Caption';
import { shade600 } from '../../../theme/colours';
import BodyCopy from '../BodyCopy/BodyCopy';
import { isNil, isEmpty } from 'ramda';

interface AddressProps {
    address: Address | ApplicantAddress[] | undefined;
    caption: string;
}

const AddressDetailWrapper = styled(StyledDetailWrapper)`
    p {
        margin: 0.5rem auto;
    }
`;

const AddressDetail = ({ address, caption }: AddressProps): ReactElement => {
    return (
        <AddressDetailWrapper>
            <Caption color={shade600}>{caption}</Caption>
            {address && Array.isArray(address) && isEmpty(address) && <p>-</p>}
            {address &&
                Array.isArray(address) &&
                address.map((applicantAddress) => (
                    <BodyCopy key={applicantAddress.applicantAddressUuid}>{applicantAddress.displayAddress}</BodyCopy>
                ))}

            {/* If the display address is an empty string, it means all fields of an address are empty */}
            {!Array.isArray(address) && (
                <BodyCopy>{isEmpty(address?.displayAddress) ? '-' : address?.displayAddress}</BodyCopy>
            )}
            {isNil(address) && <p>-</p>}
        </AddressDetailWrapper>
    );
};

export default AddressDetail;
